import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import { GoArrowRight } from 'react-icons/go';
import { PiChalkboardTeacher, PiPersonArmsSpreadLight, PiStudent, PiUser, PiUsersThree } from 'react-icons/pi';
import DashBoardFinance from './profile/DashBoardFinance';
import DashboardSalary from './profile/DashboardSalary';
import DashBoardActivity from './profile/DashBoardActivity';
import AsideMain from '../../Addons/AsideMain';
import { getTenant, postTenant } from '../../Resquest';
import useLocalStorage from '../../lib/UseLocalStorage';
import BaseGraph from './profile/AdminGraphs';


const AdminDashboard = ({ server }) => {
        const navigate = useNavigate()
        const [currentSchool, setCurrentSchool] = useLocalStorage('currentSchool', null)
        const [applicants, setApplicants] = useState(0)
        const [students, setStudents] = useState(0)
        const [teachers, setTeachers] = useState(0)
        const [parents, setParents] = useState(0)
        const [classes, setClasses] = useState(0)


        useEffect(() => {
          dashboard_request()
          fetch_dashboard_numbers()
        }, [])


        const dashboard_request = async() => {
                // const response = await getTenant(server, '/dashboard/overview/')
                // console.log(response);
        }

        const fetch_dashboard_numbers = async() => {
                const response = await getTenant(server, '/analytics/numbers/')
                response.status === 200 && update_dashboard(response?.data)
                
        }

        const update_dashboard = (data) => {
                setApplicants(data?.applications || 0)
                setClasses(data?.classes || 0)
                setStudents(data?.students || 0)
                setTeachers(data?.staffs || 0)
                setParents(data?.guardians || 0)
        }
        

  return (
    <div className='admin_dashboard'>
        <div className='dashboard_container'>
                <AsideMain />
                <div className='container'>
                        <div className='dashboard_component_grid'>
                                <div onClick={()=> navigate('/applicants')} className='admin_action '>
                                        <div className='dashboard_elt_flex'>
                                                <div className='dash_elt_icon'>
                                                        <PiUser />
                                                </div>
                                                <div className='dash_elt_text'>
                                                        <h1 className='count'>{ applicants }</h1>
                                                        <h2 className=''>Applicants</h2>
                                                </div>
                                        </div>
                                        <div className='dash_elt_more'>
                                                <span>more info</span>
                                                <span><GoArrowRight /></span>
                                        </div>
                                </div>
                                <div onClick={()=> navigate('/students')} className='admin_action admin_student_action'>
                                        <div className='dashboard_elt_flex'>
                                                <div className='dash_elt_icon'>
                                                        <PiStudent />
                                                </div>
                                                <div className='dash_elt_text'>
                                                        <h1 className='count'>{ students }</h1>
                                                        <h2 className=''>Students</h2>
                                                </div>
                                        </div>
                                        <div className='dash_elt_more'>
                                                <span>more info</span>
                                                <span><GoArrowRight /></span>
                                        </div>
                                </div>
                                <div onClick={()=> navigate('/staffs')} className='admin_action admin_teacher_action'>
                                        <div className='dashboard_elt_flex'>
                                                <div className='dash_elt_icon'>
                                                        <PiChalkboardTeacher />
                                                </div>
                                                <div className='dash_elt_text'>
                                                        <h1 className='count'>{ teachers }</h1>
                                                        <h2 className=''>Staffs</h2>
                                                </div>
                                        </div>
                                        <div className='dash_elt_more'>
                                                <span>more info</span>
                                                <span><GoArrowRight /></span>
                                        </div>
                                </div>
                                <div onClick={()=> navigate('/parents')} className='admin_action admin_tparent_action'>
                                        <div className='dashboard_elt_flex'>
                                                <div className='dash_elt_icon'>
                                                        <PiPersonArmsSpreadLight />
                                                </div>
                                                <div className='dash_elt_text'>
                                                        <h1 className='count'>{ parents }</h1>
                                                        <h2 className=''>Parents</h2>
                                                </div>
                                        </div>
                                        <div className='dash_elt_more'>
                                                <span>more info</span>
                                                <span><GoArrowRight /></span>
                                        </div>
                                </div>
                                <div onClick={()=> navigate('/classes')} className='admin_action admin_class_action'>
                                        <div className='dashboard_elt_flex'>
                                                <div className='dash_elt_icon'>
                                                        <PiUsersThree />
                                                </div>
                                                <div className='dash_elt_text'>
                                                        <h1 className='count'>{ classes }</h1>
                                                        <h2 className=''>Classes</h2>
                                                </div>
                                        </div>
                                        <div className='dash_elt_more'>
                                                <span>more info</span>
                                                <span><GoArrowRight /></span>
                                        </div>
                                </div>
                                
                        </div>

                        <GraphAndTime />
                       
                        <DashBoardFinance server={server} />
                        <DashboardSalary server={server} />
                        {/* <DashBoardActivity /> */}
                </div>
        </div>
    </div>
  )
}

export default AdminDashboard

const GraphAndTime = () => {
        const [date, setDate] = useState(new Date());
        const navigate = useNavigate()



        const options = {
                chart: {
                  id: "basic-bar"
                },
                xaxis: {
                  categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
        };

        const series = [
                {
                  name: "series-1",
                  data: [30, 40, 45, 50, 49, 60, 70, 91]
                }
        ];

        const scale = (date) => {
                setDate(date)
                navigate('calendar')
        }

        return (
                <div className='admin_graph_date'>
                <section>
                        <div className='calendar_analytics_wrapper'>
                                <div><Calendar onChange={e => scale(e)} value={date} className = 'dashboard_calendar' /></div>
                                <div className='dashboard_analyticss'>
                                        {/* <Chart
                                                options={options}
                                                series={series}
                                                type="line"
                                                width="500"
                                        /> */}
                                </div>
                        </div>
                </section>
                <BaseGraph />
                </div>
        )
}