import React from 'react'
import WhyUs from '../../assets/img/landing/why_us.png'

const Secure = () => {
  return (
    <section className='advertise_n'>
        <br />
        <br />
        <h2 className='section_title '>Enhancing education through intelligent solutions</h2>
        <div className='why_us_section'>
          <div className='why_us_title'>Why choose us?</div>
          <p className='description'>
            We provide complete free service with 24hours support to guide you and your establishment to achieve the best possible result
          </p>
          <div className='why_us_img_wrapper'>
            <img src={WhyUs} alt='why us' />
          </div>
          <br />
          <br />
          <div className='wrapper_section_advertise'>
            <div className='why_us_title'>Locations and support</div>
            <p className='description'>
              The diversity of our team makes us support several countries around the world.
            </p>
            <div className='why_us_img_wrapper'>
              <img src={WhyUs} alt='why us' />
            </div>
          </div>
        </div>
    </section>
  )
}

export default Secure