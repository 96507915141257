import React, { useState } from 'react'
import './ViewTypes.css'
import Johndoe from '../johndoe.jpg'
import TreeItem from './TreeItem'
import { useNavigate } from 'react-router-dom'

const PersonTree = ({ students }) => {
        const [selectAll, setSelectAll] = useState(false)
        const [selectedList, setSelectedList ] = useState([])

        const selectItem = (id) => {
                console.log(id);
        }
  return (
    <div className='person tree_view'>
        <div className='scroll_tree_wrapper'>
                <div className='scroll_container'>
                        <div className='person_profile_pic'>
                                <img src={Johndoe} alt='placeholder' />
                        </div>
                <table className='tree'>
                     <thead className='tree_table_head'>
                                <tr>
                                        <th><input type='checkbox' value={selectAll} onChange={() => setSelectAll(!selectAll)} /></th>
                                        <th>Student Name</th>
                                        <th>Matricule</th>
                                        <th>Department</th>
                                        <th>Class</th>
                                        <th>Email</th>
                                        <th>Gender</th>
                                        <th>Status</th>
                                        <th>Admission Date</th>
                                </tr>
                        </thead>
                        <tbody className='tree_table_body'>
                                {
                                        students.map(student => (
                                                <StudentTree student={student} handleSelect={selectItem} selectAll={selectAll} key={student?.id} />
                                        ))
                                }
                                
                        </tbody>
                </table> 
                </div>
        </div>
    </div>
  )
}

export default PersonTree


const StudentTree = ({ student, selectAll, handleSelect }) => {
        const navigate = useNavigate()
        console.log(student);
        return (
        <tr className='tree_item' onClick={() => navigate(`${ student?.id }`)}>
                <td><input type='checkbox' value={selectAll} onChange={() => handleSelect(student)} /></td>
                <td>{ student?.user?.first_name } { student?.user?.last_name }</td>
                <td>{student?.matricule}</td>
                <td>{ student?.student_class?.level?.departments?.name }-{ student?.student_class?.level?.departments?.language_supports && student?.student_class?.level?.departments?.language_supports[0] }</td>
                <td style={{ textTransform: 'capitalize' }}>{ student?.student_class?.level?.name } { student?.student_class?.class_name }</td>
                <td>{ student?.user?.email }</td>
                <td>{ student?.user?.gender }</td>
                <td>{ student?.status }</td>
                <td>{ student?.admission_date && new Date(student?.admission_date).toDateString() }</td>
        </tr>
  )
}
