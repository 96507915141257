
import React, { createContext, useState } from 'react'

export const StudentAdmissionContext = createContext()



export const StudentAdmissionContextProvider= ({ children }) => {
    const [studentInfo, setStudentInfo] = useState('user', {});
    
    return (
        <StudentAdmissionContext.Provider value={[studentInfo, setStudentInfo]}>
            {children}
        </StudentAdmissionContext.Provider>
    )
}