
import React, { createContext, useState } from 'react'

export const SubjectFormToggleContext = createContext()



export const SubjectFormToggleContextProvider= ({ children }) => {
    const [addSubjForm, setAddSubjForm] = useState(false);
    
    return (
        <SubjectFormToggleContext.Provider value={[ addSubjForm, setAddSubjForm ]}>
            {children}
        </SubjectFormToggleContext.Provider>
    )
}