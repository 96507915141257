import React, { useState } from 'react'
import './Security.css'
import { VscChromeClose } from 'react-icons/vsc'
import NotificationAlert from '../../Addons/NotificationAlert'

const SecurityPage = () => {
  return (
    <div className='security_page'>
        <div className='container'>
            <div className='sec_wrapper'>
                <WebaSocial />
                <Location />
                <WebaSocial />
            </div>
        </div>
    </div>
  )
}

export default SecurityPage

const WebaSocial = () => {
    const [upadatedSec, setUpadatedSec] = useState('valid')
    const [showPopup, setShowPopup] = useState(false)
    const [alertStatus, setAlertStatus] = useState('')


    const [website, setWebsite] = useState('https://www.tiidel.com')
    const [twitterHandle, setTwitterHandle] = useState('https://www.twitter.com/tiidel')
    const [facebookHandle, setFacebookHandle] = useState('https://www.facebook.com/tiidel')
    const [linkedInHandle, setLinkedInHandle] = useState('https://www.linkedin.com/company/tiidel')
    const removeModal = () => {

    }
    const saveInfo = (e) => {
        console.log(e.target.name);
        setShowPopup(true)
        setAlertStatus('loading')
        setTimeout(() => {
            setShowPopup(false)
        }, 1500);

    }
    return (
        <section className='sec_section'>
            <h2 className='mini_title'>Website and social</h2>
            <div className='sec_flex'>
                <div className='sec_flex_left'>Website</div>
                <div className='sec_flex_right'>
                    <input type='text' onBlur={e =>saveInfo(e)} name='website' className='sec_input' value={website} onChange={e => setWebsite(e.target.value)} />
                </div>
            </div>
            <div className='sec_flex'>
                <div className='sec_flex_left'>twitter</div>
                <div className='sec_flex_right'>
                    <input type='text' className='sec_input' onBlur={e => saveInfo(e)} value={twitterHandle} onChange={e => setTwitterHandle(e.target.value)}  />
                </div>
            </div>
            <div className='sec_flex'>
                <div className='sec_flex_left'>facebook</div>
                <div className='sec_flex_right'>
                    <input type='text' className='sec_input' onBlur={e => saveInfo(e)} value={facebookHandle}  onChange={e => setFacebookHandle(e.target.value)}  />
                </div>
            </div>
            <div className='sec_flex'>
                <div className='sec_flex_left'>linkedIn</div>
                <div className='sec_flex_right'>
                    <input type='text' className='sec_input' onBlur={e => saveInfo(e)} value={ linkedInHandle } onChange={e => setLinkedInHandle(e.target.value)} />
                </div>
            </div>
            <NotificationAlert showPopup={showPopup} handleRemoveModal={removeModal} text="saved successfully" status={alertStatus} />
        </section>
    )
}
const Location = () => {
    return (
        <section className='sec_section sec_loc'>
            <h2 className='mini_title'>Location</h2>
            <div className='sec_flex sec_flex_loc'>
                <div className='sec_flex_left'>site and map</div>
                <div className='sec_flex_right'>
                    <div className='sec_map_loc'></div>
                </div>
            </div>
        </section>
    )
}