import React from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { PiDownloadThin } from 'react-icons/pi'
import { VscChromeClose } from 'react-icons/vsc'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { ReactComponent as Loader } from '../assets/loader.svg'

const variants = {
    hidden: {
        y: "-100vh",
        opacity: 0,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    visible: {
        y: "-92vh",
        opacity: 1,
        transition: {
            duration: 0.2,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    exit: {
        y: "-100vh",
        opacity: 0,

    }
}


const NotificationAlert = ({ showPopup=true, handleRemoveModal, text, status }) => {
   

    return (
        <AnimatePresence
            initial={false}
            mode='wait'
        >
            {
                showPopup && (
                    <div className='book_modalWrapper'>
                        <motion.div
                            className='book_notify_modal notification_alert'
                            variants={variants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                                <div className='add_to_cart_modal_inner'>
                                    <div className='alert_icon'>
                                        {
                                            status === 'loading'
                                            ?
                                            <span className='nofification_status_loader'><Loader /></span>
                                            :
                                            status === 'success'
                                            ?
                                            <span className='notification_status success'>
                                                G
                                            </span>
                                            :
                                            <span className='notification_status success'>
                                                F
                                            </span>
                                            

                                        }
                                    </div>
                                    <p className='modal_notify_text' >{ text }</p>
                                    <div>
                                        <span className='' onClick={handleRemoveModal}><VscChromeClose /></span>
                                    </div>
                                </div>
                        </motion.div>
                    </div>
                )
            }
        </AnimatePresence>
    )
}


export default NotificationAlert