
import React from 'react'
import TBI from './resources/TBI-School-logo.png'
import CPNDDB from './resources/CPNDDB.png'
import ASD from './resources/asd.png'
import CCAST from './resources/CCAST.png'
import ALA from './resources/ALA.png'
import aois from './resources/aois.png'
import standrewsturi from './resources/standrewsturi.png'

const PopularSchools = () => {
    
    const schools = [
        {
            name: "The bridge international school",
            logo: TBI,
        },
        {
            name: "COLLEGE PRIVE LAIC NOTRE DAME DES BEATITUDES",
            logo: CPNDDB,
        },
        {
            name: "The american school of Douala",
            logo: ASD,
        },
        {
            name: "Cameroon college of art science and technology",
            logo: CCAST,
        },
        {
            name: "African leadership academy",
            logo: ALA,
        },
        {
            name: "Association of international schools in Africa",
            logo: aois,
        },
        // {
        //     name: "standrewsturi",
        //     logo: standrewsturi,
        // }

    ]

  return (
    <>
    {
        schools.map((school, index) => (
            <div key={index} className='popular_school'>
                <img src={school.logo} alt={school.name} />
            </div>
        ))
    
    }
    </>
  )

}

export default PopularSchools