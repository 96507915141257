import axios from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { PiDownloadThin } from 'react-icons/pi'
import { VscChromeClose } from 'react-icons/vsc'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { getTenant, postTenant } from '../../Resquest'



const variants = {
    hidden: {
        y: "-100vh",
        opacity: 0,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    visible: {
        y: "-70vh",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    exit: {
        y: "-100vh",
        opacity: 0,

    }
}


const TimeTableModal = ({ dayIndex, startTime, endTime, showPopup, cls_id, handleRemoveModal,term_id, server }) => {

    const [subjects, setSubjects] = useState([])
    const [selectedSubject, setSelectedSubject] = useState(null)
    const [isloading, setIsloading] = useState(false)
    let day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

    useEffect(() => {
       showPopup && fetch_class_subjects()
    }, [showPopup])

    const fetch_class_subjects = async() => {
        const response = await getTenant(server, `/subjects/${cls_id}/`)
        if(response?.status === 200) {
            setSubjects(response.data)
        }
    }
    
    const request = {
        day: day[dayIndex],
        class: cls_id,
        term: term_id,
        subject: selectedSubject,
        startTime: startTime,
        endTime: endTime
    }
    const save_event = async() => {
        if(!selectedSubject || selectedSubject === '') return
        setIsloading(true)
        const response = await postTenant(server, `/timetables/`, [request])
        console.log(response);
        if(response?.status === 201) {
            setIsloading(false)
            request.subject = subjects.find(subject => subject.id === selectedSubject)
            return handleRemoveModal(request)
        }
        else {
            handleRemoveModal(false)
        }
        setIsloading(false)
    };

    return (
        <AnimatePresence
            initial={false}
            mode='wait'
        >
            {
                showPopup && (
                    <div className='book_modalWrapper'>
                        <motion.div
                            className='book_notify_modal download_modal'
                            variants={variants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                                <div className='add_to_cart_modal_inner'>
                                        <p className='modal_notify_text' >Select term to download result <br />your download will begin automatically</p>
                                        <select onChange={(e) =>setSelectedSubject(e.target.value)} className='filter_tree_select' id="filter_activity">
                                            <option value=''>Select Subject</option>
                                            {
                                                subjects?.map((subject) => (
                                                    <option key={subject?.id} value={subject?.id}>{subject?.name}</option>
                                                ))
                                            }
                                        </select>
                                        <button onClick={save_event} className='modal_btn'>
                                            <span><PiDownloadThin/></span>
                                            <span>{ isloading ? 'Saving subject...' : 'Save Event' }</span>
                                        </button>
                                </div>
                                <span className='download_modal_btn' onClick={handleRemoveModal}><VscChromeClose /></span>
                        </motion.div>
                    </div>
                )
            }
        </AnimatePresence>
    )
}

export default TimeTableModal