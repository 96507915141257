import React, { useState } from 'react'
import Switch from "react-switch";
import './Job.css'
import AsideMain from '../../Addons/AsideMain'
import { useNavigate } from 'react-router-dom'
import { PiLinkLight, PiStarThin } from 'react-icons/pi'
import { VscChromeClose } from 'react-icons/vsc';

const JobView = () => {
    const [jobModal, setJobModal] = useState(false)
    const [jobModalWrapper, setJobModalWrapper] = useState(false)
    const [jobTitle, setJobTitle] = useState('')
    const [workers, setWorkers] = useState(0)
    const [desc, setdesc] = useState('')

    const createModal = () => {
        setJobModal(true)
        setJobModalWrapper(true)
    }
    const removeModal = () => {
        setJobModal(false)
        setTimeout(() => {
            setJobModalWrapper(false)
        }, 150);
    }

  return (
    <div className='job_listing_page'>
        <div className='dashboard_container'>
            <AsideMain />
            <div className='container'>
                <main className='main_user_section'>
                    <div className='controls'>
                        <button className='person_create_btn' onClick={createModal}>Create job</button>
                    </div>
                </main>
                <div className='job_list'>
                    <JobItem />
                    <JobItem />
                    <JobItem />
                    <JobItem />
                    <JobItem />
                    <JobItem />
                    <JobItem />
                    <JobItem />
                    <JobItem />
                </div>
            </div>
        </div>



        <div className={`system_modal_base ${jobModal ? 'show_system_modal_base': ''}`}>
            <div className={`system_modal_wrapper jobModal ${jobModalWrapper ? 'show_system_modal_wrapper': ''}`}>

                <div className='system_modal_content'>
                    <header className='modal_title'>Create a Job position</header>
                    <div onClick={removeModal} className='system_modal_content_btn'><VscChromeClose /></div>
                    
                    <div className='modal_content'>
                        <div className='nameWrapper inputflex'>
                            <div className='sides'>
                                <label htmlFor='job_title' className='input_label'>Job title</label>
                                <input value={jobTitle} onChange={e => setJobTitle(e.target.value)} type='text' placeholder='e.g teacher' name='job_title' className='auth_input' />
                            </div>
                            <div className='sides'>
                                <label htmlFor='workers' className='input_label'>Workers</label>
                                <input value={workers} onChange={e => setWorkers(e.target.value)}  type='text' name='workers' className='auth_input' />
                            </div>
                        </div>
                        <div className='nameWrapper'>
                                <label htmlFor='description' className='input_label'>Description</label>
                                <textarea  value={desc} onChange={e => setdesc(e.target.value)} type='text' name='description' placeholder='Description of the job' rows={6} className='auth_input' />
                        </div>
                    </div>

                    <div className='modal_actions_section'>
                        <button className='modal_btn'>create</button>
                    </div>
                </div>

            </div>
        </div>




    </div>
  )
}

export default JobView


const JobItem = () => {
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false)
    return (
        <div className='job_item'>
            <div className='job_upper'>
                <h2 className='title'>
                    <span className='icon'><PiStarThin /></span>
                    <span className='text'>Counsellor</span>
                </h2>
                <div className='class_center_analytics'>
                    <button onClick={() => navigate('/classes/form7-general')} className='class_btn'>4 applicants</button>
                    <div className='class_center'>
                        <p>4 New Applications</p>
                        <p>2 Recruits</p>
                    </div>
                </div>
            </div>
            <div className='job_under'>
                <div className='publish_btn'>
                    <div>published</div>
                    <Switch width={30} height={12} activeBoxShadow={'0 0 2px 3px #0000'} onChange={() => setChecked(!checked)} checked={checked} />
                </div>
                <div><span className='job_url_handle'><PiLinkLight /></span></div>
            </div>
        </div>
    )
}