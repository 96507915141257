import React, { useEffect, useState } from 'react'
import { PiFilesThin, PiStarLight, PiUsersThreeThin } from 'react-icons/pi'
import { ReactComponent as LoaderOval } from '../../assets/LoaderOval.svg'

import PersonTree from '../DashBoard/profile/views/PersonTree'
import PopupAddToAlert from '../../Addons/PopupAddToAlert'
import ClassStudents from './ClassStudents'
import { useLocation, useParams } from 'react-router-dom'
import { getTenant } from '../../Resquest'
import ClassSubjects from './ClassSubjects'
import { SubjectFormToggleContextProvider } from '../../Addons/SubjectFormContext'
import ClassTuitionSettings from './settings/ClassTuitionSettings'
import ClassCreate from './settings/ClassCreate'

const ClassSettings = ({ server }) => {
    const [classDispAttr, setclassDispAttr] = useState('tuition')
    const [showDownloadModal, setshowDownloadModal] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [cls, setCls] = useState([])
    
    const location = useLocation();
    const { id } = useParams()
    
    const removeModal = () => {
        setshowDownloadModal(false)
    }
    useEffect(() => {
        get_class_by_id()
    }, [])
    
    const get_class_by_id = async() => {
        setIsloading(true)
        const response = await getTenant(server, `/classes/`)
        response?.status === 200 && setCls(response?.data);
        setIsloading(false)
    }
    
  return (
    <div className='class_details_page'>
        <div className='container'>
            <div className='content'>
                <header className='details_header'>
                    <div className='head'>
                        <span className='icon'><PiFilesThin /></span>
                        <div className='context'>
                            <p>21 Download</p>
                            <p>Class info</p>
                        </div>
                    </div>
                    <div onClick={() => setshowDownloadModal(true)} className='head'>
                        <span className='icon'><PiUsersThreeThin /></span>
                        <div className='context'>
                            <p>{ cls?.students && cls?.students.length } class</p>
                            <p>Tuitions/Fees</p>
                        </div>
                    </div>
                    <div className='head'>
                        <span className='icon'><PiStarLight /></span>
                        <div className='context'>
                            <p>78 grade</p>
                            <p>7 remaining</p>
                        </div>
                    </div>
                </header>
                <main className='class_attr_list'>
                    <div className='headers_attr'>
                        <div onClick={()=> setclassDispAttr('class_create')} className={`class_attr_category ${classDispAttr === 'class_create' ? 'active': ''}`} style={{textTransform: 'capitalize'}}>Add class</div>
                        <div onClick={()=> setclassDispAttr('tuition')} className={`class_attr_category ${classDispAttr === 'tuition' ? 'active': ''}`}>Configure Tuitions</div>
                    </div>
                    {
                        isloading
                        ?
                        <span className='class_fetch_data_loader'><LoaderOval /></span>
                        :
                        <SubjectFormToggleContextProvider>
                            <section className='attr_content'>
                                {
                                    classDispAttr === 'tuition'
                                    ?
                                    <>{ cls && <ClassTuitionSettings cls={cls} server={server} /> }</>
                                    :
                                    classDispAttr === 'class_create'
                                    ?
                                    <>{ cls && <ClassCreate cls={cls}  /> }</>
                                    :
                                    <div>404 not found</div>
                                }
                            </section>
                        </SubjectFormToggleContextProvider>
                    }
                </main>
            </div>
        </div>
        <PopupAddToAlert handleRemoveModal={removeModal} showPopup={showDownloadModal} />
    </div>
  )
}

export default ClassSettings