import React, { useEffect, useState } from 'react'

import { IoListSharp } from 'react-icons/io5'

import useLocalStorage from '../../lib/UseLocalStorage'
import Search from '../../Addons/Search'


import AsideGeneral from './AsideGeneral'
import { getTenant } from '../../Resquest'
import { ReactComponent as Loader } from '../../assets/LoaderOval.svg'

const Guardians = ({ server }) => {
    const [viewMethod, setViewMethod] = useLocalStorage('viewType', 'tree')
    const [guardians, setGuardians] = useState([]) 
    const [isloading, setIsloading] = useState(false) 

    useEffect(() => {
        get_guardians()
    }, [])


    const get_guardians = async() => {
        setIsloading(true)
        try {
            const response = await getTenant(server, '/guardians/')
            response?.data && setGuardians(response.data)
        } catch (error) {
            console.log(error);
        }
        setIsloading(false)
    }
    
        
  return (
    <div className='staff_management'>
        <div className='user_grid'>
                <AsideGeneral />
                <main className='main_user_section'>
                        <div className='controls'>
                                <div />
                                <div className='view_types'>
                                    <Search />
                                    <button onClick={()=> setViewMethod('tree')} className={`view_type_btn ${viewMethod === 'tree' ? 'selected_view_type': ''}`}><IoListSharp /></button>
                                </div>
                        </div>
                        {
                            isloading
                            ?
                            <div className='loading_black'>
                                <Loader />
                            </div>
                            :
                            guardians.length  > 0 ? <ParentTree list={guardians} /> : <h2 className='bold' style={{ textAlign: 'center', marginTop: '3rem' }}>No Guardians Found</h2>
                        }
                        
                </main>
        </div>
    </div>
  )
}

export default Guardians


const ParentTree = ({list}) => {
    const [selectAll, setSelectAll] = useState(false)
   
    return (
    <div className='person tree_view'>
        <div className='scroll_tree_wrapper'>
                <div className='scroll_container'>
                    <br />
                    <table className='tree'>
                        <thead className='tree_table_head'>
                            <tr>
                                <th><input type='checkbox' value={selectAll} onChange={() => setSelectAll(!selectAll)} /></th>
                                <th>Full Name</th>
                                <th>Type</th>
                                <th>Occupation</th>
                                <th>Phone</th>
                                <th>email</th>
                                <th>Join Date</th>
                                <th>Gender</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody className='tree_table_body'>
                            {
                            list.map(listItem => (
                                <tr className='tree_item'>
                                    <td><input type='checkbox' value={selectAll} /></td>
                                    <td>{ listItem?.user?.first_name } { listItem?.user?.last_name }</td>
                                    <td>{ listItem?.type }</td>
                                    <td>{ listItem?.job }</td>
                                    <td>{ listItem?.user?.phone }</td>
                                    <td>{ listItem?.user?.email }</td>
                                    <td>{ new Date(listItem?.created).toDateString() }</td>
                                    <td>{ listItem?.user?.gender }</td>
                                    <td>{ listItem?.user?.address }</td>
                                </tr>
                            ))
                            }
                            
                        </tbody>
                    </table> 
                </div>
        </div>
</div>
)}