
import React, { useState } from 'react'
import { Actions, AnimateOnboard } from '../../SlideInfomation'


export const SlideInfomationTier = ({ handle_form_submit, index, handle_go_back }) => {
    const [logo, setLogo] = useState(null)
    const [school_photo, setSchool_photo] = useState(null)
    const [isloading, setIsloading] = useState(false)
    
  
    const submit_form = () => {
      setIsloading(true)
      handle_form_submit({})
    }
    const go_back = () => {
      handle_go_back(index);
    }
  
  
  return (
    <>
        <AnimateOnboard>
            <section className='school_onboard-1'>
                <div className='onboard_form_control'>
                  <label className='bold-text' htmlFor="name">SETUP COMPLETE</label>
                  <p>Your school's online server setup has been successfully completed. <br />Click "Finish" to access your resources.</p>                    
                </div>
            </section>
        </AnimateOnboard>
        <Actions save_data={submit_form} handle_go_back={go_back} index={index} finish={true} isloading={isloading} />
    </>
)

}

export default SlideInfomationTier;