import React, { useEffect, useState } from 'react'
import './SubComp.css'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" 
import Calendar from 'react-calendar'

import { ReactComponent as Loader } from '../../../../assets/loader.svg'
import { VscChromeClose } from 'react-icons/vsc'

const AdminDashboardActivity = () => {
        const [date, setDate] = useState(new Date())
        const [alertDate, setAlertDate] = useState(false)

        const addEvent = () => {

        }
        const scale = (e) => {
                setDate(e)
                setAlertDate(true)
        }
        const handleDateClick = (e) => {
                setDate(e.date)
                setAlertDate(true)
        }
        const handleFormSubmit = (data) => {
        }
        const removeModal =() => {
                setAlertDate(false)
        }
  return (
    <div className='dashboard_calendarK'>
        <div className='alert_calendar'>
                This page cannot be opened on this device
                <br />
                Continue on a larger screen
        </div>
        <div className='calendar_contentK'>
                <div className='calendar_main'>
                        <FullCalendar
                                plugins={[ dayGridPlugin, interactionPlugin ]}
                                initialView="dayGridMonth"
                                dateClick={ e => handleDateClick(e) }
                                events={[
                                        { title: 'event 1', date: '2023-09-01' },
                                        { title: 'event 1', date: '2023-09-01' },
                                        { title: 'event 2', date: '2023-09-02' },
                                        { title: 'event 2', date: '2023-09-02' }
                                ]}
                        />
                </div>
                <aside className='calendar_asideK'>
                        <Calendar onChange={e => scale(e)} value={date} className = 'caledar_k' />
                        <renderEventContent  />
                </aside>
        </div>
        {
                alertDate && <DateAlert handleFormSubmit={handleFormSubmit} date={date}  handleRemoveModal={removeModal}  />
        }
        
    </div>
  )
}

export default AdminDashboardActivity


const DateAlert = ({ handleFormSubmit, date, handleRemoveModal }) => {
        const [isloading, setIsloading] = useState(false)
        const [dateString, setDateString] = useState('')
        const [endDate, setEndDate] = useState('')
        const [alertMessage, setAlertMessage] = useState('')

        useEffect(() => {
                let day = date.getDate()
                let month = date.getMonth()
                let year = date.getFullYear()
                if(month < 10){
                        month = '0' + month
                }
                if(day < 10){
                        day = '0' + day
                }
                setDateString(`${year}-${month}-${day}`)
        }, [])
        
        const submitform = (e) => {
                e.preventDefault()
                setIsloading(true)
                handleFormSubmit(e)
                clearForm()
        }
        const validateDate = () => {
                let regex = /^\d{4}-\d{2}-\d{2}$/
                const res = regex.test(endDate);
                if(res === false){
                        alert('invalid date')
                }
                if(res === true){
                       const [ year, month, date ] = endDate.split('-')
                       if( parseInt(date) > 31 ){
                                alert('invalid date')
                       }
                       if( parseInt(month) > 12 ){
                                alert('invalid date')
                       }
                       if( parseInt(year) < new Date().getFullYear() || parseInt(year) > 2050 ){
                                alert('invalid date')
                       }
                }
        }
        const alert = (param) => {
                setAlertMessage(param)
                setTimeout(() => {
                        setAlertMessage('')
                }, 3000);
        }
        const remove = () => {
                clearForm()
                handleRemoveModal()
        }
        const clearForm = () => {
                setEndDate('')
        }
        return (
                <div className='alert_event'>
                        <div className='alert_event_content'>
                                <h2 className='title'>Academic year: 2022-2023</h2>
                                <div onClick={ remove } className='close_event_modal'><VscChromeClose /></div>
                                <h2 className='program_title'>Program description</h2>
                                {alertMessage.length > 1  && <div className='alert_input'>{ alertMessage }</div> }
                                <form onSubmit={ submitform }>
                                        <div className='program_grid'>
                                                <div className='grid_one'>
                                                        <div className='program_item'>
                                                                <label htmlFor='name'>Event Name</label>
                                                                <input type='text' className='event_input' />
                                                        </div>
                                                        <div className='program_item'>
                                                                <label htmlFor='name'>Attendies</label>
                                                                <input type='text' className='event_input' />
                                                        </div>
                                                </div>
                                                <div className='grid_one'>
                                                        <div className='program_item'>
                                                                <label htmlFor='name'>Start Date</label>
                                                                <input type='text' placeholder='yyyy-mm-dd' className='event_input' value={dateString} />
                                                        </div>
                                                        <div className='program_item'>
                                                                <label htmlFor='name'>End Date</label>
                                                                <input type='text' placeholder='yyyy-mm-dd' onChange={(e) => setEndDate(e.target.value)} onBlur={validateDate} value={endDate} className='event_input' />
                                                        </div>
                                                </div>
                                        </div>
                                        <button type='submit' className='auth_input auth_submit_btn'>
                                                <span className='btn_text'>Save</span>
                                                { isloading && <span className='btn_svg'><Loader /></span> }
                                                
                                        </button>
                                </form>
                        </div>
                </div>
        )
}