import React, { useEffect, useState } from 'react'
import './classes.css'
import AsideMain from '../../Addons/AsideMain'
import Search from '../../Addons/Search'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from '../../lib/UseLocalStorage'
import { IoListSharp } from 'react-icons/io5'
import { BsUiChecksGrid } from 'react-icons/bs'
import { getTenant } from '../../Resquest'

import { ReactComponent as Loader } from '../../assets/LoaderOval.svg'

const Classes = ({ server }) => {
    const [viewMethod, setViewMethod] = useLocalStorage('viewType', 'tree')
    const [classes, setClasses] = useState([])
    const [isloading, setIsloading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
      get_classes()
    }, [])
    
    const get_classes = async() => {
        setIsloading(true)
        const response = await getTenant(server, '/classes')
        response?.status === 200 && save_data(response?.data);
    }
    const save_data = (data) => {
        setClasses(data)
        setIsloading(false)
    }

  return (
    <div className='classes_page'>
        <div className='dashboard_container'>
            <AsideMain />
            <div className='container'>
                <main className='main_user_section'>
                    <div className='controls'>
                        <button className='person_create_btn' onClick={()=> navigate('settings')}>Class Settings</button>
                        <div className='view_types'>
                            <Search />
                            <button onClick={()=> setViewMethod('tree')} className={`view_type_btn ${viewMethod === 'tree' ? 'selected_view_type': ''}`}><IoListSharp /></button>
                            <button onClick={()=> setViewMethod('kanban')} className={`view_type_btn ${viewMethod === 'kanban' ? 'selected_view_type': ''}`}><BsUiChecksGrid /></button>
                        </div>
                    </div>
                </main>
                <div className='classes_content'>
                    {
                        isloading
                        ?
                        <div className='loading_black'>
                            <Loader />
                        </div>
                        :
                        <div className='classes_grid'>
                            {
                                classes.length > 0 && classes.map(cls => (
                                    <ClassItem key={cls?.id} cls={cls} />
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Classes


const ClassItem = ({ cls }) => {
    const navigate = useNavigate()

    const filterTeachers = () => {
        navigate(`${ cls?.id }/teachers`)
    }

    return (
        <div className='class'>
            <h2>{ cls?.level?.name.toUpperCase() } -{ cls?.class_name }</h2>
            <ul className='list_tags'  style={{ textTransform: 'capitalize' }}>
                <li>{ cls?.level?.departments?.name }</li>
                <li>{ cls?.level?.departments?.language_supports && cls?.level?.departments?.language_supports[0] }</li>
                <li>{ cls?.instructor || "HOD" }</li>
            </ul>
            <div className='class_center_analytics'>
                <button onClick={() => navigate('/classes/' + cls?.id,  { state: { cls } })} className='class_btn'>View sylabus</button>
                <div className='class_center'>
                    <p>Class AV</p>
                    <p>Pass</p>
                    <p>Complete</p>
                </div>
                <div className='class_center right'>
                    <p>19.99</p>
                    <p>100%</p>
                    <p>100%</p>
                </div>
            </div>
            <div className='class_bottom_analytics'>
                <div className='account'>
                    <h2 onClick={filterTeachers}>0</h2>
                    <p>Teacher</p>
                </div>
                <div className='account_stick' />
                <div className='account'>
                    <h2>{ cls?.students.length }</h2>
                    <p>Students</p>
                </div>
                <div className='account_stick' />
                <div className='account'>
                    <h2>{ cls?.subjects.length }</h2>
                    <p>Subjects</p>
                </div>
            </div>
        </div>
    )
}