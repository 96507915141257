import React, { useEffect, useState } from 'react'
import './StaffManagement.css'
import { FaUsers } from 'react-icons/fa'
import { FiFilter } from 'react-icons/fi'
import { IoListSharp } from 'react-icons/io5'
import { BsUiChecksGrid } from 'react-icons/bs'


import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Johndoe from './profile/johndoe.jpg'
import PersonKanban from './profile/views/PersonKanban'
import useLocalStorage from '../../lib/UseLocalStorage'
import Search from '../../Addons/Search'
import { getTenant } from '../../Resquest'
import StaffInvitation from '../../Addons/StaffInvitationPopUp'

const StaffManagement = ({ user, school }) => {
        const [viewMethod, setViewMethod] = useLocalStorage('viewType', 'tree')
        const [invitationModal, setInvitationModal] = useState(false)
        const [staffs, setStaffs] = useState([])
        const [isloading, setIsloading] = useState(false)
        
        const navigate = useNavigate()
        const staffCount = new Array(0).fill(0)
        const location = useLocation()
        
        const filterList = () => {
                const filterBy = location.hash
                console.log('filter by: ', filterBy);
        }

        const removeModal = () => {
                setInvitationModal(false)
        }

        useEffect(() => {
          get_staffs()
        }, [])

        const get_staffs = async() => {
                try {
                        setIsloading(true)
                        const response = await getTenant(user?.server, '/staffs');
                        response?.status === 200 && save_staffs(response.data)
                } catch (error) {
                        console.warn(error);
                }
        }

        const save_staffs = (staffs) => {
                setStaffs(staffs)
                setIsloading(false)
        }
        

  return (
    <div className='staff_management'>
        <div className='user_grid'>
                <aside className='search_panel'>
                        <h2 className="panel_title">
                                <span className='icon'><FaUsers /></span>
                                <span className='bold-text'>Departments</span>
                        </h2>
                        <div className='panel_category_list'>
                                <ul onClick={filterList}>
                                        <NavLink to='' className={`panel_category_item ${location.hash === ''? 'filterSet': ''}`}>
                                                <span className='text'>All</span>
                                                <span className='count'>26</span>
                                        </NavLink>
                                        <NavLink to='#admins' className={`panel_category_item ${location.hash === '#admins'? 'filterSet': ''}`}>
                                                <span className='text'>Administration</span>
                                                <span className='count'>1</span>
                                        </NavLink>
                                        <NavLink to='#secretary' className={`panel_category_item ${location.hash === '#secretary'? 'filterSet': ''}`}>
                                                <span className='text'>Secretary</span>
                                                <span className='count'>3</span>
                                        </NavLink>
                                        <NavLink to='#accountant' className={`panel_category_item ${location.hash === '#accountant'? 'filterSet': ''}`}>
                                                <span className='text'>Accountant</span>
                                                <span className='count'>12</span>
                                        </NavLink>
                                        <NavLink to='#teachers' className={`panel_category_item ${location.hash === '#teachers'? 'filterSet': ''}`}>
                                                <span className='text'>Teachers</span>
                                                <span className='count'>6</span>
                                        </NavLink>
                                        <NavLink to='#security' className={`panel_category_item ${location.hash === '#security'? 'filterSet': ''}`}>
                                                <span className='text'>Security</span>
                                                <span className='count'>3</span>
                                        </NavLink>
                                        <NavLink to='#ps' className={`panel_category_item ${location.hash === '#ps'? 'filterSet': ''}`}>
                                                <span className='text'>Professional Services</span>
                                                <span className='count'>2</span>
                                        </NavLink>
                                </ul>
                        </div>
                </aside>
                <main className='main_user_section'>
                        <div className='controls'>
                                <div>
                                        <button className='person_create_btn' onClick={()=> navigate('create')}>Create staff member</button>
                                        {
                                        invitationModal
                                        ?
                                        <button className='person_create_btn' style={{ marginLeft: '0px'}} onClick={()=> setInvitationModal(false)}>Go back</button>
                                        :
                                        <button className='person_create_btn' style={{ marginLeft: '0px'}} onClick={()=> setInvitationModal(true)}>0 Invitations</button>
                                        }
                                </div>

                                <div className='view_types'>
                                        <Search />
                                        <button onClick={()=> setViewMethod('tree')} className={`view_type_btn ${viewMethod === 'tree' ? 'selected_view_type': ''}`}><IoListSharp /></button>
                                        <button onClick={()=> setViewMethod('kanban')} className={`view_type_btn ${viewMethod === 'kanban' ? 'selected_view_type': ''}`}><BsUiChecksGrid /></button>
                                </div>
                        </div>
                        {
                                invitationModal
                                ? 
                                <StaffInvitation user={user} school={school} handleRemoveModal={removeModal} showPopup={invitationModal} />
                                : staffs.length === 0 
                                ?
                                <div className='no_staff_member'>
                                        <h2>No staff member yet. Send invitation</h2>  
                                        <button className='person_create_btn' onClick={()=> setInvitationModal(true)}>Invite members</button>
                                </div>
                                :
                                <StaffMembers viewMethod={viewMethod} staffs={staffs} />
                        }
                               
                </main>
        </div>
    </div>
  )
}

export default StaffManagement


const StaffMembers = ({ viewMethod, staffs }) => {
        const navigate = useNavigate()

        return (
                <>
                {
                        viewMethod === 'kanban'
                        ?
                        (
                        <div className='user_main_grid'>
                                {
                                        staffs.length > 0 && staffs.map(item => (
                                                <PersonKanban list={item} />
                                        ))
                                }
                        </div>

                        ):
                        
                        staffs.length > 0 && <StaffTree staffs={staffs} />
                        
                        
                 } 
                </>
        )
}


const StaffTree = ({ staffs }) => {
        const [selectAll, setSelectAll] = useState(false)
        const [selectedList, setSelectedList ] = useState([])

        const selectItem = (id) => {
                console.log(id);
        }
  return (
    <div className='person tree_view'>
        <div className='scroll_tree_wrapper'>
                <div className='scroll_container'>
                        <div className='person_profile_pic'>
                                <img src={Johndoe} alt='placeholder' />
                        </div>
                <table className='tree'>
                     <thead className='tree_table_head'>
                                <tr>
                                        <th><input type='checkbox' value={selectAll} onChange={() => setSelectAll(!selectAll)} /></th>
                                        <th>Staff Name</th>
                                        <th>Role</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Gender</th>
                                        <th>Status</th>
                                        <th>Admission Date</th>
                                </tr>
                        </thead>
                        <tbody className='tree_table_body'>
                                {
                                        staffs.map(staff => (
                                                <StaffMember staff={staff} handleSelect={selectItem} selectAll={selectAll} key={staff?.id} />
                                        ))
                                }
                                
                        </tbody>
                </table> 
                </div>
        </div>
    </div>
  )
}


const StaffMember = ({ staff, selectAll, handleSelect }) => {
        const navigate = useNavigate()
        return (
        <tr className='tree_item' onClick={() => navigate(`${ staff?.id }`)}>
                <td><input type='checkbox' value={selectAll} onChange={() => handleSelect(staff)} /></td>
                <td>{ staff?.user?.first_name } { staff?.user?.last_name }</td>
                <td className='bold-text' style={{textTransform: 'capitalize'}}>{ staff?.role }</td>
                <td style={{ textTransform: 'capitalize' }}>{ staff?.user?.phone }</td>
                <td>{ staff?.user?.email }</td>
                <td>{ staff?.user?.gender }</td>
                <td>{ staff?.status ? 'Active': 'Not Active' }</td>
                <td>{ staff?.created_at && new Date(staff?.created_at   ).toDateString() }</td>
        </tr>
  )
}
