
import axios from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { PiDownloadThin } from 'react-icons/pi'
import { VscChromeClose } from 'react-icons/vsc'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

const variants = {
    hidden: {
        y: "-100vh",
        opacity: 0,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    visible: {
        y: "-70vh",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    exit: {
        y: "-100vh",
        opacity: 0,

    }
}


const PopupAddToAlert = ({ showPopup, cls_id, handleRemoveModal,terms, server }) => {
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [isloading, setIsloading] = useState(false)

    useEffect(() => {
      if(terms?.active_term?.id){
        setSelectedTerm(terms?.active_term?.id)
      }
      else if(terms?.terms[0]){
        setSelectedTerm(terms?.terms[0]?.id)
      }
      else {
        setSelectedTerm(null)
      }
    }, [])
    
    const download = async() => {
        if(isloading) return 
        if(!selectedTerm){
            handleRemoveModal()
            alert('Select a valid term to proceed')
            return;
        }
        setIsloading(true)
        try {
            const response = await axios.get(`https://${ server }.rankafrica.site/api/v1/class_result/${cls_id}/${selectedTerm}/download`, {
                responseType: 'blob',
            });
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'class_result.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
            setIsloading(false)
            return;
        } catch (error) {
            console.error('Download failed', error);
        }
        setIsloading(false)
        return;
    };

    return (
        <AnimatePresence
            initial={false}
            mode='wait'
        >
            {
                showPopup && (
                    <div className='book_modalWrapper'>
                        <motion.div
                            className='book_notify_modal download_modal'
                            variants={variants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                                <div className='add_to_cart_modal_inner'>
                                        <p className='modal_notify_text' >Select term to download result <br />your download will begin automatically</p>
                                        <select onChange={(e) =>setSelectedTerm(e.target.value)} className='filter_tree_select' id="filter_activity">
                                            { terms?.active_term?.id && <option defaultValue={terms?.active_term?.id} value={ terms?.active_term?.id }>Current Term</option>}
                                            {
                                                terms?.terms.map(term => (
                                                    <option key={term?.id} value={term?.id}>Term {term?.term_name}</option>
                                                ))
                                            }
                                        </select>
                                        <button onClick={download} className='modal_btn'>
                                            <span><PiDownloadThin/></span>
                                            <span>{ isloading ? 'Downloading...' : 'Download' }</span>
                                        </button>
                                </div>
                                <span className='download_modal_btn' onClick={handleRemoveModal}><VscChromeClose /></span>
                        </motion.div>
                    </div>
                )
            }
        </AnimatePresence>
    )
}

export default PopupAddToAlert
