import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { PiDownloadThin } from 'react-icons/pi'
import { VscChromeClose } from 'react-icons/vsc'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { getTenant, postTenant } from '../Resquest'
import SlideInNotifications from './SlideNotification'


const StaffInvitation = ({ user, school }) => {
    const [staffList, setStaffList] = useState([])
    const [fullName, setFullName] = useState('')
    const [role, setRole] = useState('general')
    const [email, setEmail] = useState('')
    const [notification, setNotification] = useState()
    const [showNotify, setShowNotify] = useState(false)
    const [invitations, setInvitations] = useState([])
    
    useEffect(() => {
      fetch_invitations()
    }, [])

    const fetch_invitations = async() => {
        const response = await getTenant(user?.server, '/invitation/')
        response.status === 200 && setInvitations(response?.data)
        console.log(response?.data);
    }
    
    const validateForm = (formData) => {
        let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!formData.recipient_name){
        alert("You must provide the name of the recipient")
        return false
        }
        if( !email.test(formData.recipient_email)){
        alert("Invalid email provided please try again")
        }
        else {
        return true
            }
    }

    const filter_item = (email) => {
        const filtered = staffList.filter(staff => staff.recipient_email !== email)
        setStaffList(filtered)
    }
    
    const add_staff_to_list = () => {
        console.log(school);
        const data = {
        "recipient_email": email,
        "recipient_name": fullName,
        "role": role.toLowerCase(),
        "school_name": school?.name,
        "school": school?.id,
        "tenant_name": user?.server
        }
        const validated = validateForm(data)
        if(validated){
        save(data)
        clearForm()
        }
    }
    const save = (data) => {
        setStaffList([...staffList, data])
    }
    
    const clearForm = () => {
        setFullName('')
        setRole('general')
        setEmail('')
    }

    const send_invites = async() => {
        const response = await postTenant(user?.server, '/invitation/', staffList)
        console.log(response);
        if(response?.status === 201){
            fetch_invitations()
            setNotification({ text: "Message sent successfully", id: Math.random(), status: 'success' })
            setShowNotify(true)
        }
        else {
            setNotification({ text: "Could not send Invitations Try again later", id: Math.random(), status: 'fail' })
        }
        setStaffList([])
    }
    
    const remove_invite = () => {
        setNotification({ text: "Invite has been revoked", id: Math.random(), status: 'info' })
        setShowNotify(true)
    }

    return (
            <div className='staff_invitation_form'>
                    <div className='invitation_form_flex'>
                    <div className='book_notify_modal staff_invitations_modal'>
                            <div className='add_to_cart_modal_inner'>
                                    <p className='create_staff_title modal_notify_text' >Enter a list of staff members you wish to invite <br /> into your organisation </p>
                                    <section className='create_staff_invitation_form'>
                                        <section className='staff_invitation_flex'>
                                            <section className='create_staff_invitation_input'>
                                                <input value={fullName} onChange={e => setFullName(e.target.value)} type='text' placeholder='Full Name' />
                                            </section>
                                            <section className='create_staff_invitation_input'>
                                                <select value={role} onChange={e => setRole(e.target.value)}>
                                                    <option value="general" def>---Role---</option>
                                                    <option value="teacher">Teacher</option>
                                                    <option value="general">General</option>
                                                    <option value="secretary">Secretary</option>
                                                    <option value="accountant">Accountant</option>
                                                    <option value="guardian">Student Guardian</option>
                                                </select>
                                            </section>
                                        </section>
                                        <section className='create_staff_invitation_input'>
                                            <input type='email' value={email} onChange={e=> setEmail(e.target.value)} placeholder='Email' />
                                        </section>
                                        <div className='add_staff_modal_wrapper'>
                                            <button onClick={ add_staff_to_list } className='modal_btn add_staff_modal'>
                                                <span>Add User</span>
                                            </button>
                                        </div>
                                    </section>
                            </div>
                            <SlideInNotifications notification={notification} triggered={ showNotify } />
                    </div>
                    <div className='staff_invitee_list'>
                            <div className='invite_list_temp'>
                                    <div className='invite_list_email_flex'>
                                    {
                                            staffList.map(( staff, index) => (
                                            <div key={index} onClick={() => filter_item(staff?.recipient_email)} className='invite_list_temp_email'>{ staff?.recipient_email }</div>
                                            ))
                                    }
                                    </div>
                            </div>
                            {
                                    staffList.length > 0 && <button onClick={send_invites} className='btn send_staff_invitation_btn'>Send Invitations</button>
                            }

                            <table className='tree'>
                                    <thead className='tree_table_head'>
                                            <tr>
                                                    <th>Name</th>
                                                    <th>Role</th>
                                                    <th>email</th>
                                                    <th>status</th>
                                                    <th style={{ textAlign: 'right'}}>Remove</th>
                                            </tr>
                                    </thead>
                                    <tbody className='tree_table_body'>
                                        {
                                        invitations.length > 0 &&
                                        invitations.map(invitation => (
                                            <tr key={invitation?.id}>
                                                    <td>{ invitation?.recipient_name }</td>
                                                    <td>{ invitation?.role }</td>
                                                    <td>{ invitation?.recipient_email }</td>
                                                    <td><span className={`staff_invitation_status ${invitation?.invite_status}`}>{ invitation?.invite_status }</span></td>
                                                    <td><span onClick={remove_invite} className='staff_invitation_close'><VscChromeClose /></span></td>
                                            </tr>
                                        ))
                                        }
                                            
                                    </tbody>
                            </table> 
                    </div>

                    </div>
            </div>
    )
}
export default StaffInvitation
