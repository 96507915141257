
import React, { useContext, useEffect, useState } from 'react'
import { VscClose } from 'react-icons/vsc'

import { useNavigate } from 'react-router-dom';
import { getTenant, postTenant } from '../../Resquest';
import useLocalStorage from '../../lib/UseLocalStorage';
import SlideInNotifications from '../../Addons/SlideNotification';
import { SubjectFormToggleContext, SubjectFormToggleContextProvider } from '../../Addons/SubjectFormContext';
import GradeSubjects from './GradeSubject';


const ClassSubjects = ({ subjects, cls }) => {
    const [addSubjForm, setAddSubjForm] = useContext(SubjectFormToggleContext)
    const [tenantUser, setTenantUser] = useLocalStorage('tenantUser', )
    const [showSubject, setShowSubject] = useState(false)
    const [showSubjectWrapper, setShowSubjectWrapper] = useState(false)
    const [selectedSubject, setSelectedSubject] = useState()

    const openSubject = (subject) => {
        setShowSubject(true)
        setShowSubjectWrapper(true)
        setSelectedSubject(subject)
        console.log(subject);
    }

    const removeModal = () => {
        setShowSubject(false)
        setTimeout(() => {
            setShowSubjectWrapper(false)
        }, 150);
    }
    
  return (
    <div className='class_subjects'>
        {
            addSubjForm
            ?
            <SubjectCreateForm cls={cls} server={tenantUser?.server} />
            :
            <SubjectListItems handleOpenSubject={openSubject} server={tenantUser?.server} cls={cls}  />
            
        }
        <GradeSubjects server={tenantUser?.server} subject={selectedSubject} cls_id={cls.id} showSubjectWrapper={showSubjectWrapper} showSubject={showSubject} handle_remove_modal={removeModal}  />
    </div>
  )
}

export default ClassSubjects

const SubjectListItems = ({ server, cls, handleOpenSubject }) => {
    const [subjects, setSubjects] = useState([])
    const [addSubjForm, setAddSubjForm] = useContext(SubjectFormToggleContext)

    useEffect(() => {
        fetch_subjects()
    }, [])

    const fetch_subjects = async() => {
        const response = await getTenant(server, `/subjects/${ cls?.id }/`)
        response?.status === 200 && setSubjects(response.data)
    }

    
    
    return (
        <div className='subject_list'>
            <div  className='class_subject title'>
                <p>Subject Name</p>
                <p>Instructor</p>
                <p>Classes</p>
                <p>Attendance</p>
                <p>Coefficient or Course Credit</p>
                <p className='close_btn_for_class_subject toggle_to_form' onClick={() => setAddSubjForm(!addSubjForm)} style={{ backgroundColor: '#fff' }}></p>
            </div>
            {
                subjects.length > 0 && 
                subjects.map(subject => (
                    <div onClick={() => handleOpenSubject(subject)} className='class_subject'>
                        <p>{ subject?.name }</p>
                        <p>
                            { subject?.instructor?.user?.gender == 'male' ? 'Mr' : subject?.instructor?.user?.gender == 'female' ? 'Mrs' : '' }
                            { subject?.instructor?.user?.first_name } { subject?.instructor?.user?.last_name }
                        </p>
                        <p>44</p>
                        <p>100%</p>
                        <p>{ subject?.sub_coef }</p>
                        <p className='close_btn_for_class_subject'><VscClose /></p>
                    </div>
                ))
            }
        </div>
    )
}


const SubjectCreateForm =({cls, server }) => {
    const [forms, setForms] = useState([0])
    const [subjects, setSubjects] = useState([])
    const [teachers, setTeachers] = useState([])
    const [showNotify, setShowNotify] = useState(false)
    const [notification, setNotification] = useState({})
    const [addSubjForm, setAddSubjForm] = useContext(SubjectFormToggleContext)

    useEffect(() => {
        fetch_teachers()
    }, [])

    const fetch_teachers = async () => {
        const response = await getTenant(server, '/teachers/')
        response?.status === 200 && setTeachers(response.data)
    }

    const add_form = (idx, current, data) => {
        if(idx > current + 1) {
            return 
        }
        setForms(form => ([...form, idx]))
        setSubjects(subjects => ([...subjects, data]))
    }

    const remove_subject = (idx, sj) => {
        if(forms.length === 1){
            return
        }
        if(idx + 1 === forms.length){
            return
        }
        let filter_form = forms.filter(form => form !== idx)
        let filter_subjects = subjects.filter(subject => subject?.name !== sj?.name)
        setForms(filter_form)
        setSubjects(filter_subjects)
    }

    const save_subjects = async() => {
        const response = await postTenant(server, `/subjects/${ cls?.id }/`, subjects)
        console.log(response)
        response?.status === 201 && reset_field()
    }
    const reset_field = () => {
        setForms([0])
        setSubjects([])
        setNotification({id: Math.random(), text: 'Subject added successfully'})
        setShowNotify(true)

        setAddSubjForm(false)
    }
    return (
        <section className='class_add_subject'>
            <div className='subject_form_header'>
                <h2>Add new Subject to class</h2>
                <div className='subject_form_actions'>
                    <button onClick={ save_subjects } className='modal_btn primary'>Save subject</button>
                </div>
            </div>
            {
                forms.map((index, form) => (
                    <SubjectForm teachers={teachers} cls={cls} key={index} index={forms.length} current={index} handle_remove_subject={ remove_subject } handle_add_form={add_form} />
                ))
            }
            <SlideInNotifications triggered={showNotify} notification={notification} />
        </section>
    )
}

const SubjectForm = ({ index, teachers, current, handle_add_form, handle_remove_subject, cls }) => {
    const [subject, setSubject] = useState('')
    const [instructor, setInstructor] = useState('')
    const [instructorId, setInstructorId] = useState('')
    const [coef, setCoef] = useState('')
    const [teacherDD, setTeacherDD] = useState(false)
    const data = {
            "name": subject,
            "cls": cls?.id,
            "level": cls?.level?.id,
            "instructor": instructorId,
            "sub_coef": coef,
            "course_duration": 600
    }


    useEffect(() => {
        if(subject && instructor && coef ) {
            handle_add_form(index, current, data)
        }
    }, [subject, instructor, coef])

    const handleInputChange = (e) => {
        const value = e.target.value;
    
        if (/^\d*$/.test(value)) {
            setCoef(value);
        }
    };

    const select_teacher = (teacher) => {
        setInstructor(teacher?.user?.first_name + ' ' + teacher?.user?.last_name)
        setInstructorId(teacher?.id)
    }

    const navigate = useNavigate();
    
    return (
        <>
            <div className='subject_form_inline'>
                <div className='subject_input_form'>
                    <input type='text' value={subject} onChange={e => setSubject(e.target.value)} className='subject_input_inline' placeholder='Subject Name'/>
                </div>
                <div className='subject_input_form instructor_select'>
                    <input onFocus={() => setTeacherDD(true)} onBlur={() => setTimeout(() => { setTeacherDD(false) }, 300) } type='text' value={instructor} onChange={e => setInstructor(e.target.value)}  className='subject_input_inline' placeholder='Subject Instructor'/>
                    <div className={`select_cls_instructor_dropdown ${ teacherDD ? 'show': '' }`}>
                        <ul>
                            {
                                teachers.length > 0 &&
                                teachers.map(teacher => (
                                    <li onClick={() => select_teacher(teacher)}>{ teacher?.user?.first_name } { teacher?.user?.last_name }</li>
                                ))
                            }
                            <li onClick={() => navigate('/staffs')}>Add intructor</li>
                        </ul>
                    </div>
                </div>
                <div className='subject_input_form'>
                    <input type='text' value={coef} onChange={handleInputChange} className='subject_input_inline' placeholder='Coefficient'/>
                </div>
                {/* <div className='subject_input_form'>
                    <input type='text' value={coef} onChange={handleInputChange} className='subject_input_inline' placeholder='Exam Score e.g /20'/>
                </div> */}
                <div>
                    <p onClick={() => handle_remove_subject(current, data) } className='close_btn_for_class_subject'><VscClose /></p>
                </div>
            </div>
        </>
    )
}