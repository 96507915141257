import React, { useState } from 'react'

import { Actions, AnimateOnboard } from "../../SlideInfomation"
import { FiSearch } from 'react-icons/fi'
import { SlLocationPin } from 'react-icons/sl'
import { getExternalRequest, postTenant } from '../../../../../Resquest'


import { ReactComponent as Loader } from '../../../../../assets/loader.svg'
import useLocalStorage from '../../../../../lib/UseLocalStorage'

const SlideInfomationLocation = ({ handle_save_data, user, request, index, handle_go_back }) => {
    const [Country, setCountry] = useState('')
    const [address, setaddress] = useState('')
    const [city, setCity] = useState('')
    const [isloading, setIsloading] = useState(false)
    const [currentSchool, setCurrentSchool ] = useLocalStorage('currentSchool', {})

    // TOGGLE DROPDOWNS
    const [show_country_dropdown, setShow_country_dropdown] = useState(false)
    const [show_city_dropdown, setShow_city_dropdown] = useState(false)

    const [full_country_list, setFull_country_list] = useState([])
    const [country_search_response, setCountry_search_response] = useState([])


    const data = {
      "country": Country,
      "city": city,
      "address": address, 
      "billing_method": "MTN"
    }
    const save_data = () => {
      setIsloading(true)
      create_school()
    }

    const create_school = async( ) => {
      let req = {...request, ...data}
      const response = await postTenant(user?.school_code, '/schools/', req)
      console.log(response)
      response.data?.school_code && setCurrentSchool(response.data)
      response.status === 201 && handle_save_data(index, {}, true)
       
    }

    const go_back =() => {
      handle_go_back(index)
    }

    const getCountryList = async() => {
      setShow_country_dropdown(true)
      if( full_country_list.length < 1){
        const res = await getExternalRequest("https://restcountries.com/v3.1/all?fields=name,flags");
        setCountry_search_response(res.data);
        setFull_country_list(res.data);
      }
    }

    const getCityList = async() => {
      setShow_city_dropdown(true)
      const res = getExternalRequest("");
    }

    const searchCountry = () => {
      let search_list = full_country_list.filter((country) => country.name.common.toLowerCase().startsWith(Country))
      setCountry_search_response(search_list)
    }

    const searchCity = () => {
      let search_list = full_country_list.filter((country) => country.name.common.toLowerCase().startsWith(Country))
      setCountry_search_response(search_list)
    }

  return (
    <>
      <AnimateOnboard>
        <section className='school_onboard-1'>
          <div className='onboard_form_control'>
              <label htmlFor="name">Country</label>
              <div className='onboard_input_wrapper'>
                <input onFocus={ getCountryList } type="text" value={Country} onBlur={() => setShow_country_dropdown(false)} onKeyUp={searchCountry} onChange={e=> setCountry(e.target.value)} />
                <span className='form_control_icon'><FiSearch /></span>
              </div>
              {
                show_country_dropdown && (
                  <div className='onboard_search_response'>
                    {
                      country_search_response.length < 1
                      ? <div className="loading_data_loader"><Loader /></div> 
                      :
                      country_search_response.map(country => (
                        <div className='country_list_item' onMouseDown={() => setCountry(country.name.common)}>
                          <span className="icon"><img src={country.flags.svg} alt={country.name.common} /></span>
                          <span className="countryname">{ country.name.common }</span>
                        </div>
                      ))
                    }
                  </div>
                )
              }
          </div>
          <div className='onboard_form_control'>
              <label htmlFor="name">City</label>
              <div className='onboard_input_wrapper'>
                <input 
                  // onFocus={ getCityList } 
                  // onBlur={() => setShow_city_dropdown(false)} 
                  // onKeyUp={searchCity} 
                  type="text" value={city} 
                  onChange={e=> setCity(e.target.value)} 
                />
                <span className='form_control_icon'><SlLocationPin /></span>
              </div>
              {/* {
                show_city_dropdown && (
                  <div className='onboard_search_response'>
                    {
                      country_search_response.length < 1
                      ? <div className="loading_data_loader"><Loader /></div> 
                      :
                      country_search_response.map(country => (
                        <div className='country_list_item' onMouseDown={() => setCity(country.name.common)}>
                          <span className="icon"><img src={country.flags.svg} alt={country.name.common} /></span>
                          <span className="countryname">{ country.name.common }</span>
                        </div>
                      ))
                    }
                  </div>
                )
              } */}
          </div>

          <div className='onboard_form_control'>
              <label htmlFor="name">Address of School</label>
              <div className='onboard_input_wrapper'>
                <input type="text" value={address} onChange={e=> setaddress(e.target.value)} />
                <span className='form_control_icon'><SlLocationPin /></span>
              </div>
          </div>
        </section>
      </AnimateOnboard>
      <Actions save_data={save_data} handle_go_back={go_back} isloading={isloading} />
    </>
  )
}

export default SlideInfomationLocation;