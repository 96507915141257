import React, { useEffect, useState } from "react";
import { PiDownloadThin, PiFilesThin, PiStarLight, PiUsersThreeThin } from "react-icons/pi";
import { ReactComponent as LoaderOval } from "../../assets/LoaderOval.svg";
import { IoCloudOfflineOutline } from "react-icons/io5";

import PersonTree from "../DashBoard/profile/views/PersonTree";
import PopupAddToAlert from "../../Addons/PopupAddToAlert";
import ClassStudents from "./ClassStudents";
import { useLocation, useParams } from "react-router-dom";
import { getTenant } from "../../Resquest";
import ClassSubjects from "./ClassSubjects";
import { SubjectFormToggleContextProvider } from "../../Addons/SubjectFormContext";

const ClassDetails = ({ server }) => {
  const [classDispAttr, setclassDispAttr] = useState("students");
  const [showDownloadModal, setshowDownloadModal] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [serverStatus, setServerStatus] = useState(0);
  const [cls, setCls] = useState([]);
  const [terms, setTerms] = useState(null);

  const location = useLocation();
  const { id } = useParams();

  const removeModal = () => {
    setshowDownloadModal(false);
  };
  useEffect(() => {
    get_class_by_id();
    get_terms();
  }, []);

  // useEffect(() => {
  //     console.log('status form server', serverStatus)
  //     if(serverStatus > 0 ){
  //         setclassDispAttr('status')
  //     }
  // }, [serverStatus])

  const get_terms = async () => {
    const response = await getTenant(server, `/terms/`);
    setTerms(response.data);
  };
  const get_class_by_id = async () => {
    setIsloading(true);
    const response = await getTenant(server, `/classes/${id}/`);
    if (response?.code === "ERR_NETWORK" && cls.length === 0) {
      setServerStatus(100);
    }
    response?.status === 200 && setCls(response?.data);
    response?.status === 200 && setServerStatus(0);
    setIsloading(false);
  };

  return (
    <div className="class_details_page">
      <div className="container">
        <div className="content">
          <header className="details_header">
            <div className="modal_location">
              {cls?.level?.name} {cls?.class_name}
            </div>

            <div onClick={() => setshowDownloadModal(true)} className="head">
              <span className="icon">
                <PiDownloadThin />
              </span>
              <div className="context">
                <p>Publish &</p>
                <p>Download Results</p>
              </div>
            </div>

            <div className="head">
              <span className="icon">
                <PiFilesThin />
              </span>
              <div className="context">
                <p>{cls?.subjects && cls?.subjects.length} subjects</p>
                <p>0 completed</p>
              </div>
            </div>
            <a
              href={`https://${server}.rankafrica.site/api/v1/class_list/${id}/download/`}
              target="_blank"
              className="head"
            >
              <span className="icon">
                <PiUsersThreeThin />
              </span>
              <div className="context">
                <p>{cls?.students && cls?.students.length} students</p>
                <p>Download</p>
              </div>
            </a>
            <div className="head">
              <span className="icon">
                <PiStarLight />
              </span>
              <div className="context">
                <p>78 grade</p>
                <p>7 remaining</p>
              </div>
            </div>
          </header>
          <main className="class_attr_list">
            <div className="headers_attr">
              <div
                onClick={() => setclassDispAttr("students")}
                className={`class_attr_category ${
                  classDispAttr === "students" ? "active" : ""
                }`}
              >
                Students
              </div>
              <div
                onClick={() => setclassDispAttr("subjects")}
                className={`class_attr_category ${
                  classDispAttr === "subjects" ? "active" : ""
                }`}
              >
                Subjects
              </div>
              {/* { serverStatus > 0 && <div onClick={()=> setclassDispAttr('status')} className={`class_attr_category ${classDispAttr === 'status' ? 'active': ''}`}>Status</div> } */}
              {/* <div onClick={()=> setclassDispAttr('general')} className={`class_attr_category ${classDispAttr === 'general' ? 'active': ''}`}>General</div> */}
              {/* <div onClick={()=> setclassDispAttr('derif')} className={`class_attr_category ${classDispAttr === 'derif' ? 'active': ''}`}>Derivatives</div> */}
            </div>
            {isloading ? (
              <span className="class_fetch_data_loader">
                <LoaderOval />
              </span>
            ) : (
              <SubjectFormToggleContextProvider>
                <section className="attr_content">
                  {classDispAttr === "general" ? (
                    <div>Anything goes here</div>
                  ) : classDispAttr === "students" ? (
                    <>
                      {cls?.students && (
                        <ClassStudents
                          list={cls?.students}
                          cls_id={cls?.id}
                          server={server}
                        />
                      )}
                    </>
                  ) : classDispAttr === "subjects" ? (
                    <>
                      {cls?.subjects && (
                        <ClassSubjects
                          cls={cls}
                          subjects={new Array(6).fill(0)}
                        />
                      )}
                    </>
                  ) : classDispAttr === "status" ? (
                    <>{cls?.subjects && <ServerStatus statusCode={100} />}</>
                  ) : (
                    <div>404 not found</div>
                  )}
                </section>
              </SubjectFormToggleContextProvider>
            )}
          </main>
        </div>
      </div>
      {
        terms
        &&
        <PopupAddToAlert
            handleRemoveModal={removeModal}
            showPopup={showDownloadModal}
            server={server}
            terms={terms}
            cls_id={id}
        />
      }
    </div>
  );
};

export default ClassDetails;

const ServerStatus = ({ statusCode }) => {
  return (
    <div className="center_status">
      <div className="center_status_content">
        {statusCode === 100 && (
          <div>
            <h2>
              Unable to connect to the server, Please check you internet
              connection and try again later
            </h2>
            <span
              className="icon"
              title="reload"
              onClick={() => window.location.reload()}
            >
              <IoCloudOfflineOutline />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
