import React from 'react'
import CCAST from '../../assets/img/schools/ccast.png'
import SHEGA from '../../assets/img/schools/shega.png'
import SHEART from '../../assets/img/schools/sheart.png'
import ALA from '../../assets/img/schools/ALA.png'
import Standrewsturi from '../../assets/img/schools/standrewsturi.png'
import TBI from '../../assets/img/schools/TBI-School-logo.png'
import CPNDDB from '../../assets/img/schools/CPNDDB.png'
import CountUpOnScrollWelcome from './CountUpWelcome'

const PopularClients = () => {


  return (
    <section className='advertise_n blue'>
        <div className='popular_clients_overlay'>
            <h2 className='section_title '>Popular clients</h2>
            <h3 className='hero_sub_title'>Some of our popular clients you may recorgnize</h3>
            <div className='popular_clients_wrapper' >
              <div className='clients_list'>
                <div className='popular_clients_flex'>
                  <div className='popular_client'>
                    <img src={CCAST} alt='ccast bambili logo' />
                  </div>
                  <div className='popular_client'>
                    <img src={ALA} alt='african leadership academy' />
                  </div>
                  <div className='popular_client'>
                    <img src={SHEGA} alt='secret heart college bamenda cameroon' />
                  </div>
                  <div className='popular_client'>
                    <img src={SHEART} alt='ccast bambili logo' />
                  </div>
                  <div className='popular_client'>
                    <img src={Standrewsturi} alt='ccast bambili logo' />
                  </div>
                  <div className='popular_client'>
                    <img src={CPNDDB} alt='ccast bambili logo' />
                  </div>
                  <div className='popular_client'>
                    <img src={TBI} alt='TBI' />
                  </div>
                </div>
              </div>
            </div>
            <h2 className='section_title '>Discover our amazing services</h2>
            <h3 className='hero_sub_title'>We serve thousands of users all over Africa</h3>
            <CountUpOnScrollWelcome />
        </div>
        <div className='popular_clients_underlay' />
    </section>
  )
}

export default PopularClients