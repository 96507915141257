
import React, { useEffect, useState } from 'react'

import { Actions, AnimateOnboard } from "../../SlideInfomation"
import { SlLocationPin } from 'react-icons/sl'
import { postTenant } from '../../../../../Resquest'
import useLocalStorage from '../../../../../lib/UseLocalStorage'
import { VscTerminalPowershell } from 'react-icons/vsc'


const SlideInfomationAddress = ({ user, handle_save_data, index, handle_go_back }) => {
    
    const [school_types, setSchool_types] = useState([])
    const [terms, setTerms] = useState(2)
    const [sequences, setSequences] = useState(2)

    const [isloading, setIsloading] = useState(false)
    const [department, setDepartment ] = useLocalStorage('department', {})

    const data = {
      "school_types": school_types
    }
    const save_data = () => {
      setIsloading(true)
      create_level()
      setIsloading(false)
    }

   
    const create_level = async() => {
      if(school_types.length < 1 ) {
        setIsloading(false)
        return
      } 
      let request = []
      school_types.map( school_type => {
        const data = {
          "name": school_type.toLowerCase(),
          "departments": department?.id,
          "school": department?.school,
          "created_by": `${user?.first_name} ${user?.last_name}`,
          "activate_date": new Date()
        }
        request.push(data)
      })
      create_all_levels(request)
    }

    const create_all_levels = async(data) => {
      const response = await postTenant(user?.school_code, '/levels/', data)
      console.log(response);
      if(response.status === 201) {
        create_terms()
        handle_save_data(index, {school_types: response.data, terms: terms, sequences: sequences})
      }
      
    }


    const create_terms = () => {
      const data = {
        "school": department?.school,
        "term_name": "first",
        "start_date": "2027-01-01",
        "end_date": "2027-03-03",
        "exams_per_term": 2
      }
    }

    const go_back =() => {
      handle_go_back(index)
    }

    const type_list = [
      {name: "Preschool or Nursery", value: "Nursery"},
      {name: "Elementary or Primary", value: "Primary"},
      {name: "Secondary", value: "Form"},
      {name: "Middle school", value: "middleschool"},
      {name: "Junior High school", value: "juniorhigh"},
      {name: "High school or college", value: "highschool"},
    ]
    
    const handleCheck = (event) => {
      let updatedList = [...school_types];
      if (event.target.checked) {
        updatedList = [...school_types, event.target.value];
      } else {
        updatedList.splice(school_types.indexOf(event.target.value), 1);
      }
      setSchool_types(updatedList);
    };
  return (
    <>
      <AnimateOnboard>
        <section className='school_onboard-1'>

          <div className='onboard_form_control'>
              <label htmlFor="name">School types</label>
              {
                type_list.map((type, index) => (
                <div key={index} className="onboard_checkbox_wrapper">
                  <input type="checkbox" value={type.value} onChange={handleCheck}  id={ type.value } />
                  <label style={{ cursor: 'pointer' }} htmlFor={ type.value }>{ type.name }</label>
                </div>
                ))
              }
          </div>

          <div className='onboard_form_control'>
              <label htmlFor="name">Number of Terms / semesters</label>
              <div className='onboard_input_wrapper'>
                <input type="number" value={terms} onChange={e=> setTerms(e.target.value)}  min={1} max={4} />
                <span className='form_control_icon'><VscTerminalPowershell /></span>
              </div>
          </div>

          <div className='onboard_form_control'>
              <label htmlFor="name">Evaluations per term / Sequences</label>
              <div className='onboard_input_wrapper'>
                <input type="number" value={sequences} min={1} max={3} onChange={e=> setSequences(e.target.value)} />
                <span className='form_control_icon'><SlLocationPin /></span>
              </div>
          </div>

        </section>
      </AnimateOnboard>
      <Actions save_data={save_data} handle_go_back={go_back} isloading={isloading} />
    </>
  )
}


export default SlideInfomationAddress;