import React from 'react'
import RegistrationRibon from '../../../Addons/RegistrationRibon'
import './About.css'
import { ReactComponent as Blob } from './blob_one.svg'
import { ReactComponent as BlobOverlay } from './blob_overlay.svg'
import { ReactComponent as BlobB } from './blob_9-6-9307.svg'
import { Link } from 'react-router-dom'
import Footer from '../../../Footer/Footer'

const AboutPage = () => {
  return (
    <>
    <div className='aboutPage'>
        <header className='about_page_header'>
            <div className='about_page_header_text_wrapper'>
                <h3 className='about_page_title'>about us</h3>
                <h2>Our mission is to make education easy for everyone with exceptional experiences</h2>
                <p>Rank is a free management system for schools and colleges. You can get started by creating an account and adding your school details.</p>
                <button>sign up for free</button>
            </div>
            <div className='about_page_header_img_wrapper'>
                <div className='about_page_header_img_overlay'>
                    <BlobOverlay />
                </div>
                <Blob />
            </div>
        </header>

        <section className='about_page_analytics_section'>
            <div className='about_page_analytics_text_wrapper'>
                <h2>Built to manage all aspects of your institution</h2>
                <div className='about_page_rank_features'>
                    <p>We believe in making learning easier by distributing work to different communities to better access and manage resources</p>
                    <p>We believe in making learning easier by distributing work to different communities to better access and manage resources</p>
                    <p>We believe in making learning easier by distributing work to different communities to better access and manage resources</p>
                </div>
            </div>

        </section>

        <section className='about_page_contact_card'>
            <div className='about_page_contact_card_wrapper'>
                <div className='about_page_contact_card_text'>
                    <h2>Get in touch with us</h2>
                    <p>Curious about how Rank services, pricing and intergration work?</p>
                </div>
                <div>
                    <Link to='/contact-us'>Send us a message</Link>
                </div>
            </div>
        </section>
        {/* <RegistrationRibon />
        <Blob /> */}
    </div>
    <Footer />
    </>
  )
}

export default AboutPage