import React, { useEffect, useState } from 'react'
import { PiFilesThin, PiStarLight, PiTrashThin, PiUserListThin } from 'react-icons/pi'
import { useParams } from 'react-router-dom'


import Persona from '../../../../assets/img/persona.jpeg'
import { getTenant } from '../../../../Resquest'

import { ReactComponent as LoaderOval } from '../../../../assets/LoaderOval.svg'
import SlideInNotifications from '../../../../Addons/SlideNotification'

const StaffDetails = ({ server }) => {
    const [classDispAttr, setclassDispAttr] = useState('subjects')
    const [staff, setStaff] = useState({})
    const [error, setError] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const { id } = useParams()
    let student = {}

    useEffect(() => {
        setIsloading(true)
        fetch_student_data()
        setIsloading(false)
    }, [])


    const fetch_student_data = async() => {
        const response = await getTenant(server, `/staffs/${id}/`);
        response?.status === 200 ? setStaff(response?.data) : setError(true)
        console.log(response);
    }

    const download_student_profile = () => {

    }
    
  return (
    <div className='class_details_page'>
        <div className='container'>
            <div className='content'>
                <header className='details_header'>
                    <div className='head'>
                        <span className='icon'><PiFilesThin /></span>
                        <div className='context'>
                            <p>Pay staff</p>
                            <p>N/A</p>
                        </div>
                    </div>
                    <div className='head'>
                        <span className='icon'><PiTrashThin /></span>
                        <div className='context'>
                            <p>Remove</p>
                            <p>{ staff?.role }</p>
                        </div>
                    </div>
                    <a href={`https://${server}.rankafrica.site/api/v1/staff_profile/${staff?.id}/download/`} target='_blank' className='head'>
                        <span className='icon'><PiUserListThin /></span>
                        <div className='context'>
                            <p>Staff Profile</p>
                            <p>Download</p>
                        </div>
                    </a>
                    <div className='head'>
                        <span className='icon'><PiStarLight /></span>
                        <div className='context'>
                            <p>Promote Staff</p>
                            <p>Change role</p>
                        </div>
                    </div>
                </header>
                {
                    error
                    ?
                    <h2>Error fetching data</h2>
                    :
                    isloading
                    ?
                    <LoaderOval />
                    :
                    <>
                    <div className='user_information_section'>
                        <div className='user_persona_info'>
                            <div className='name_section'>
                                <div>
                                    <h1>{ staff?.user?.first_name } { staff?.user?.last_name }</h1>
                                    <p>{ staff?.user?.email }</p>
                                </div>
                                <p>Admitted On: { staff?.activate_date && new Date(staff?.activate_date).toDateString() }</p>
                            </div>
                            <div className='profile'>
                                <img src={Persona} alt='kimbi dalrington pic' />
                            </div>
                        </div>

                        <div className='user_attr_content_details'>
                            <div className='col_one'>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Experience</h4>
                                    <h4 className='col_value'>{ staff?.experience && staff?.experience + 1 } year(s)</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Avg. salary</h4>
                                    <h4 className='col_value'>{ staff?.salary } XAF</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Active</h4>
                                    <h4 className='col_value'><span className="staff_invitation_status accept">True</span></h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Gender</h4>
                                    <h4 className='col_value'>{ staff?.user?.gender }</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Date of Birth</h4>
                                    <h4 className='col_value'>{ staff?.user?.date_of_birth && new Date(staff?.user?.date_of_birth).toDateString() || new Date().toDateString()}</h4>
                                </div>
                            </div>

                            <div className='col_one'>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Phone </h4>
                                    <h4 className='col_value'>{ staff?.user?.phone }</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Alt. Phone</h4>
                                    <h4 className='col_value'>{ staff?.user?.phone_alt }</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Address line 1</h4>
                                    <h4 className='col_value'>{ staff?.user?.address }</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Address line 2</h4>
                                    <h4 className='col_value'>{ staff?.user?.address_alt }</h4>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </>
                }


                <main className='class_attr_list'>
                    <div className='headers_attr'>
                        <div onClick={()=> setclassDispAttr('timetable')} className={`class_attr_category ${classDispAttr === 'timetable' ? 'active': ''}`}>Availability</div>
                        { staff?.role === 'teacher' &&
                        <>
                            <div onClick={()=> setclassDispAttr('subjects')} className={`class_attr_category ${classDispAttr === 'subjects' ? 'active': ''}`}>Subjects</div>
                            <div onClick={()=> setclassDispAttr('attendance')} className={`class_attr_category ${classDispAttr === 'attendance' ? 'active': ''}`}>Attendance</div>
                        </>
                        }
                    </div>
                    <section className='attr_content'>
                        {
                            classDispAttr === 'timetable'
                            ?
                            <StaffAvailability staff={staff} />
                            :
                            classDispAttr === 'subjects'
                            ?
                            <StaffSubjects student={student} />
                            :
                            <div>404 not found</div>
                        }
                    </section>
                </main>
            </div>
        </div>
    </div>
  )
}

export default StaffDetails


const StaffAvailability = ({ staff }) => {
    const [showNotify, setShowNotify] = useState(false)
    const [notification, setNotification] = useState({})

    const saveChanges = () => {
        setShowNotify(true)
        setNotification({ text: "Student Information updated!!", id: Math.random() })
    }
    return (
        <section className='tile_section'>
            <h2>Staffs presence (On campus)</h2>
            <table>
                <thead>
                    <tr>
                        <th>Monday</th>
                        <th>Teusday</th>
                        <th>Wednesday</th>
                        <th>Thursday</th>
                        <th>Friday</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>08:00 am - 09:00 am</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>09:00 am - 10:00 am</td>
                        <td></td>
                        <td><span className="staff_invitation_status accept">Present</span></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10:00 am - 11:00 am</td>
                        <td><span className="staff_invitation_status accept">Present</span></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>11:00 am - 12:00 pm</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><span className="staff_invitation_status accept">Present</span></td>
                    </tr>
                    <tr>
                        <td>12:00 pm - 01:00 pm</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>01:00 pm - 02:00 pm</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>02:00 pm - 03:00 pm</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <SlideInNotifications triggered={showNotify} notification={notification} />
        </section>
    )
}


const StaffSubjects = () => {
    return (
        <section className='tile_section'>
            
        </section>
    )
}