import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAFi5auCQfkiFJlXjop6ERM2Z2ulbGqhkI",
  authDomain: "rank-7ec5b.firebaseapp.com",
  projectId: "rank-7ec5b",
  storageBucket: "rank-7ec5b.appspot.com",
  messagingSenderId: "102120392342",
  appId: "1:102120392342:web:ed4a55336e1f7228c864b7",
  measurementId: "G-BDPTVFX8VX"
};


const app = initializeApp(firebaseConfig);



function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      
      const messaging = getMessaging(app);
      
      getToken(messaging, { 
        vapidKey: "BBQHm6zlSmu-VWmqER-A7ppmMKLWY50bo3C9CP9h7Qi50B6x51c1T3sofK4zr7OW_ff2CzsVOczgrtRuisSKUS8" 
      })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      })

    } else {
      console.log('Unable to get permission to notify.');
    }
  });
}

requestPermission();





// export const getFCMToken = async (setTokenFound) => {
//   let currentToken = "";

//   try {
//     currentToken = await getToken(messaging, { vapidKey: "BBQHm6zlSmu-VWmqER-A7ppmMKLWY50bo3C9CP9h7Qi50B6x51c1T3sofK4zr7OW_ff2CzsVOczgrtRuisSKUS8" });
//     if (currentToken) {
//       setTokenFound(true);
//     } else {
//       setTokenFound(false);
//     }
//   } catch (error) {
//     console.log("An error occurred while retrieving token. ", error);
//   }

//   return currentToken;
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });


