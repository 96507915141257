import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AiFillCaretDown } from 'react-icons/ai'

const FaqCard = () => {
    const faqs = [
        {
            'faq': 'How do I register my school on Rank?',
            'ans': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        },
        {
            'faq': 'How do I register my ?',
            'ans': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        },
        {
            'faq': 'e printing and typesetting industry. Lo How do I register my school on Rank? ',
            'ans': "Lorem Ipsum is simply dummy text of threm Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        },
        {
            'faq': 'How do I register simply dummy  my school on Rank?',
            'ans': "Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        },
    ]
  return (
    <div className='home_card'>
        <div className='flex_head'>
        <h1 className='title'>FAQs</h1>
        <Link to='/faqs'>see all</Link>
        </div>
        <div className='faq_list'>
            {
                faqs.map(faq => (
                    <FaqItem key={faq.faq} faq={faq} />
                ))
            }
        </div>

    </div>
  )
}

export default FaqCard

const FaqItem = ({ faq }) =>  {
    const [showfaq, setShowfaq] = useState(false)

    return (
        <div className='faq_dropdown'>
            <div onClick={()=> setShowfaq(!showfaq)} className='faq_pin'>{ faq.faq } <AiFillCaretDown /></div>
            <div className={`faq_dd_content ${showfaq ? 'showfaq': ''}`}>
                { faq.ans }
            </div>
        </div>
    )
}