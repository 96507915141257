import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { LiaCertificateSolid, LiaCloudDownloadAltSolid } from 'react-icons/lia'
import { IoContract, IoDocumentOutline, IoExpand, IoReceiptOutline } from 'react-icons/io5'

const NavSecondary = () => {
        const [expandMobileNav, setExpandMobileNav] = useState(false)
  return (
    <div className='organization_nav_secondary'>
        <nav className={`mobile_nav ${expandMobileNav ? "expand_mobile_nav": ""} `}>
                <Link className='secondary_nav_link_n' to='#' onClick={() => setExpandMobileNav(!expandMobileNav)}>
                        <span className='nav_icon'>
                                { expandMobileNav ? <IoContract /> : <IoExpand /> }
                        </span>
                        <span className='nav_text'>Close</span>
                </Link>
                <NavLink className='secondary_nav_link_n' to='/downloads'>
                        <span className='nav_icon'><LiaCloudDownloadAltSolid /></span>
                        <span className='nav_text'>Downloads</span>
                </NavLink>
                <NavLink className='secondary_nav_link_n' to='/certificates'>
                        <span className='nav_icon'><LiaCertificateSolid /></span>
                        <span className='nav_text'>Certificates</span>
                </NavLink>
                <NavLink className='secondary_nav_link_n' to='/report-card-designs'>
                        <span className='nav_icon'><IoReceiptOutline /></span>
                        <span className='nav_text'>Report Card</span>
                </NavLink>
                <NavLink className='secondary_nav_link_n' to='/students/admission'>
                        <span className='nav_icon'><IoDocumentOutline /></span>
                        <span className='nav_text'>Admission</span>
                </NavLink>
                
        </nav>
        <nav className='desktop_nav'>
                <ul>
                        <li className='secondary_nav_list'>
                                <div className='secondary_nav_left'>
                                        <NavLink className='secondary_nav_link_n' to='/downloads'>Downloads</NavLink>
                                        <NavLink className='secondary_nav_link_n' to='/certificates'>Certificates</NavLink>
                                        <NavLink className='secondary_nav_link_n' to='/report-card-designs'>Design report card</NavLink>
                                        <NavLink className='secondary_nav_link_n' to='/students/admission'>Admissions</NavLink>
                                        
                                </div>
                                <div className='secondary_nav_right'>
                                        <NavLink className='secondary_nav_link_n btn_links' to='/security'>Security</NavLink>
                                        <NavLink className='secondary_nav_link_n btn_links' to='/settings'>Settings</NavLink>

                                </div>
                        </li>
                </ul>
        </nav>
    </div>
  )
}

export default NavSecondary