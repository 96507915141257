
import React, { useLayoutEffect, useState } from 'react'
import { FaFacebookF, FaGlobe, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { Actions, AnimateOnboard } from '../../SlideInfomation'
import { FiTwitter } from 'react-icons/fi';
import { postTenant } from '../../../../../Resquest';

const SlideInfomationSocial = ({ server, handle_save_data, handle_go_back, index, prev_data }) => {
    const [website, setWebsite] = useState('')
    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [linkedIn, setLinkedIn] = useState('')
    const [instagram, setInstagram] = useState('')
    const [isloading, setIsloading] = useState(false)
    const data = {
      "facebook": facebook,
      "twitter": twitter,
      "linkedIn": linkedIn,
      "instagram": instagram
    }
    const save_data = () => {
      setIsloading(true)
      create_socials()
      
    }

    const create_socials = async() => {
      const response = await postTenant(server, '/socials/', data)
      handle_save_data(index, {"social":data})
      setIsloading(false)
    }

    useLayoutEffect(() => {
      if(prev_data.classes){
        prev_data?.social > 0 && get_previous_social(prev_data?.social)
      }
    }, [])
    
    const get_previous_social = (socials) => {
      setTwitter(socials?.twitter)
      setFacebook(socials?.facebook)
      setInstagram(socials?.instagram)
      setLinkedIn(socials?.linkedIn)
      setWebsite(socials?.website)
    }
  
    const go_back = () => {
      handle_go_back(index);
    }

  return (
    <>
      <AnimateOnboard>
          <section className='school_onboard-1'>
            <div className='onboard_form_control'>
                <label htmlFor="name">Website</label>
                <div className='onboard_input_wrapper'>
                  <input type="url" value={website} placeholder='https//example.com' onChange={e=> setWebsite(e.target.value)} />
                  <span className='form_control_icon'><FaGlobe /></span>
                </div>
            </div>
            <br />
            <br />
            <div className='onboard_form_control'>
                <label htmlFor="name">Facebook</label>
                <div className='onboard_input_wrapper'>
                  <input type="url" value={facebook} placeholder='https//facebook.com/rank' onChange={e=> setFacebook(e.target.value)} />
                  <span className='form_control_icon'><FaFacebookF /></span>
                </div>
            </div>
            <div className='onboard_form_control'>
                <label htmlFor="name">Twitter( X )</label>
                <div className='onboard_input_wrapper'>
                  <input type="url" required value={twitter} placeholder='https//x.com/rank' onChange={e=> setTwitter(e.target.value)} />
                  <span className='form_control_icon'><FiTwitter /></span>
                </div>
            </div>
            <div className='onboard_form_control'>
                <label htmlFor="name">LinkedIn</label>
                <div className='onboard_input_wrapper'>
                  <input type="url" required value={linkedIn} placeholder='https//linkedin.com/rank' onChange={e=> setLinkedIn(e.target.value)} />
                  <span className='form_control_icon'><FaLinkedinIn /></span>
                </div>
            </div>
            <div className='onboard_form_control'>
                <label htmlFor="name">Instagram</label>
                <div className='onboard_input_wrapper'>
                  <input type="url" required value={instagram} placeholder='https//instagram.com/rank' onChange={e=> setInstagram(e.target.value)} />
                  <span className='form_control_icon'><FaInstagram /></span>
                </div>
            </div>
          </section>
      </AnimateOnboard>
      <Actions save_data={save_data} isloading={isloading} handle_go_back={go_back}  />
    </>
  )
  }
  export default SlideInfomationSocial;