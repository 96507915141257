import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import '../onBoardGeneral.css'
import { ReactComponent as LoaderOval } from '../../assets/LoaderOval.svg'
import { SlideInfomationSchool } from './Slides/SlideInfomation'

import { useLocation, useNavigate } from 'react-router-dom'
import { OnboardSlideTextStart, OnboardSlideTextAddress, OnboardSlideTextLocation, OnboardSlideTextPictures, OnboardSlideTextVerify, } from './Slides/ManageSlides/SlidesLeft'
import useLocalStorage from '../../lib/UseLocalStorage'
import SlideInfomationPictures from './Slides/ManageSlides/RightSide/SlideInformationLogo'
import SlideInfomationClass from './Slides/ManageSlides/RightSide/SlideInfomationClass'
import { postRequest, postTenant } from '../../Resquest'
import SlideInfomationDepartment from './Slides/ManageSlides/RightSide/SlideInfomationDepartment'
import SlideInfomationLocation from './Slides/ManageSlides/RightSide/SlideInfomationLocation'
import SlideInfomationProprietor from './Slides/ManageSlides/RightSide/SlideInfomationProprietor'
import SlideInfomationAddress from './Slides/ManageSlides/RightSide/SlideInfomationAddress'
import SlideInfomationTier from './Slides/ManageSlides/RightSide/SlideInfomationTier'
import SlideInfomationReportCards from './Slides/ManageSlides/RightSide/SlideInfomationReportCards'
import SlideInfomationVerify from './Slides/ManageSlides/RightSide/SlideInfomationVerify'
import SlideInfomationSocial from './Slides/ManageSlides/RightSide/SlideInfomationReportSocial'


const CreateSchool = () => {
  // https://restcountries.com/v3.1/all?fields=name,flags
  const [tenantUser, setTenantUser] = useLocalStorage('tenantUser')
  const location = useLocation()
  const searches = location.search
  const params = new URLSearchParams(searches);
  const step = params.get("step");
  const [percent, setPercent] = useState(0)
  const navigate = useNavigate()
  const [issubmitting, setIssubmitting] = useState(false)
  const [submit_notification, setSubmit_notification] = useState('spinning the dots')
  const [school_onboard_request, setSchool_onboard_request] = useLocalStorage("school_onboard_request", {})
  const [hasSchool, setHasSchool] = useLocalStorage('hasSchool', )
  
  const steps_to_go = [ "start", "location", "school-proprietors", "departments", "address", "classes", "terms", "pictures", "social", "tier" ]
  
  useLayoutEffect(() => { 
    let step_count = steps_to_go.indexOf(step)
    setPercent(step_count * 100 / (steps_to_go.length - 1))
  }, [step])

  const save_data = (current_step, data, reset=false) => {
    console.log(reset);
    if(reset){
      setSchool_onboard_request(({}))
    }
    setSchool_onboard_request(school_onboard_request => ({...school_onboard_request, ...data}))
    navigate('?step='+steps_to_go[current_step + 1]);
  }
  
  const go_to_previous = ( current_step ) => {
    navigate('?step='+steps_to_go[current_step - 1]);
  }
  


  const submit_school = ({ tier }) => {
    setIssubmitting(true)
    load_all_requests()
    setHasSchool(true)
    setSubmit_notification("analysing your information")
    setTimeout(() => {
      setSubmit_notification('Verifying information')
    }, 1000);
    setTimeout(() => {
      setSubmit_notification('Generating Dashboard')
    }, 3000);
    setTimeout(() => {
      setSubmit_notification('Sending Invites to Staff')
    }, 5000);
    setTimeout(() => {
      window.location.href = '/dashboard'
    }, 6000);

  }

  useEffect(() => {
    console.log("tenant admin", tenantUser);
  }, [])

  const load_all_requests = () => {
    
    create_school()
  }
  const create_school = async() => {
    const {name, country, principal_name, principal_email, director_name, director_email, director_phone } = school_onboard_request
    const { phone, email, city, address } = school_onboard_request
    const data = {
      "name": name,
      "country": country,
      "city": city,
      "address": address,
      "email": email,
      "phone": phone,
      "principal": principal_name,
      "payment_method": "paypal",
      "billing_method": "paypal"
    }
    const res = await postRequest('/school/', data)
    console.log(res);
  }
  return (
    <div className='onboard_wrapper'>
        <div className='onboard_quote'>
  
          <div className=''>
            {
              step === 'start'
              ?
              <OnboardSlideTextStart />
              : step === 'location' ?
              <OnboardSlideTextLocation />
              : step === 'address' ?
              <OnboardSlideTextAddress />
              : step === 'pictures' ?
              <OnboardSlideTextPictures />
              :  step === "terms" ?
              <OnboardSlideTextVerify />
              :
              <OnboardSlideTextStart />

            }
          </div>
        </div>
        <div className='onboard_content'>
          <>
          {
            step === 'start' ?
            <SlideInfomationSchool user={tenantUser.user} handle_save_data={save_data} handle_go_back={go_to_previous} index={0} />
            : step === "location" ?
            <SlideInfomationLocation handle_save_data={save_data} request={school_onboard_request} user={tenantUser?.user} handle_go_back={go_to_previous} index={1} />
            : step === "school-proprietors" ?
            <SlideInfomationProprietor handle_save_data={save_data}  user={tenantUser?.user} handle_go_back={go_to_previous} index={2} />
            : step === "departments" ?
            <SlideInfomationDepartment handle_save_data={save_data} user={tenantUser?.user} handle_go_back={go_to_previous} index={3} />
            : step === "address" ?
            <SlideInfomationAddress handle_save_data={save_data} user={tenantUser?.user}  handle_go_back={go_to_previous} index={4} />
            : step === "classes" ?
            <SlideInfomationClass server={ tenantUser?.server } prev_data={school_onboard_request} school_types={school_onboard_request.school_types} handle_save_data={save_data} handle_go_back={go_to_previous} index={5} />
            : step === "terms" ?
            <SlideInfomationVerify server={ tenantUser?.server } handle_save_data={save_data} handle_go_back={go_to_previous} index={6} />
            : step === "pictures" ?
            <SlideInfomationPictures user={tenantUser} handle_save_data={save_data} handle_go_back={go_to_previous} index={7} />
            : step === "report-cards" ?
            <SlideInfomationReportCards handle_save_data={save_data} handle_go_back={go_to_previous} index={8} />
            : step === "social" ?
            <SlideInfomationSocial server={tenantUser?.server} prev_data={school_onboard_request}  handle_save_data={save_data} handle_go_back={go_to_previous} index={8} />
            : step === "tier" ?
            <SlideInfomationTier handle_form_submit={submit_school} handle_go_back={go_to_previous} index={9} />
            :
            <SlideInfomationSchool user={ tenantUser.user } handle_save_data={save_data} handle_go_back={go_to_previous} index={0} />
          }
          </>

          <SlidePercent percent = { percent } />

        </div>


        <div className='pinned_tiidel'>
          <p>tiidel Inc</p>
        </div>

        {
          issubmitting && (
            <div className='onboard_submit_requests'>
              <div className='loader'>
                <LoaderOval />
              </div>
              <div className='onboard_submit_request_progress'>
                <p>{ submit_notification }</p>
              </div>
            </div>
          )
        }
    </div>
  )
}

export default CreateSchool;


const SlidePercent = ({ percent }) => {
  const slider = useRef('');

  useEffect(() => {
    if (slider.current) {
      slider.current.style.setProperty('--after-width', `${percent}%`);
    }
  }, [percent])
  
  
  return (
    <div className='onboard_slider' ref={slider} />
  )
}

