import React from 'react'
import { FaUsers } from 'react-icons/fa'
import { MdChevronRight } from 'react-icons/md'
import { NavLink, useLocation } from 'react-router-dom'

const AsideMain = () => {
    const location = useLocation()
    const filterList = () => {
        const filterBy = location.hash
        console.log('filter by: ', filterBy);
}
  return (
    <aside className='main_aside'>
            <h2 className="panel_title">
                <span className='icon'><FaUsers /></span>
                <span className='bold-text'>Departments</span>
                </h2>
                <div className='panel_category_list'>
                        <ul onClick={filterList}>
                                <NavLink to='' className={`panel_category_item ${location.hash === ''? 'filterSet': ''}`}>
                                    <span className='text'>Dashboard</span>
                                    <span className='count'><MdChevronRight /></span>
                                </NavLink>
                                <NavLink to='https://google.com' target='blank' className={`panel_category_item ${location.hash === '#admins'? 'filterSet': ''}`}>
                                    <span className='text'>Website</span>
                                    <span className='count'><MdChevronRight /></span>
                                </NavLink>
                                <NavLink to='service-plan' className={`panel_category_item ${location.hash === '#teachers'? 'filterSet': ''}`}>
                                    <span className='text'>Logistics</span>
                                    <span className='count'>60%</span>
                                </NavLink>
                                <NavLink to='/security#security' className={`panel_category_item ${location.hash === '#security'? 'filterSet': ''}`}>
                                    <span className='text'>Security</span>
                                    <span className='count chat_count security_error'>3</span>
                                </NavLink>
                                <NavLink to='#ps' className={`panel_category_item ${location.hash === '#ps'? 'filterSet': ''}`}>
                                    <span className='text'>Professional Services</span>
                                    <span className='count'>2</span>
                                </NavLink>
                                <NavLink to='/report-card-designs' className={`panel_category_item `}>
                                    <span className='text'>Our report cards</span>
                                    <span className='count'><MdChevronRight /></span>
                                </NavLink>
                                <NavLink to='/jobs#jobs' className={`panel_category_item ${location.hash === '#jobs'? 'filterSet': ''}`}>
                                    <span className='text'>Jobs</span>
                                    <span className='count'><MdChevronRight /></span>
                                </NavLink>
                                <NavLink to='/support#support' className={`panel_category_item ${location.hash === '#support'? 'filterSet': ''}`}>
                                        <span className='text'>Support</span>
                                        <span className='count'><MdChevronRight /></span>
                                </NavLink>
                                <NavLink to='/classes#classes' className={`panel_category_item ${location.hash === '#classes'? 'filterSet': ''}`}>
                                        <span className='text'>Classes</span>
                                        <span className='count'><MdChevronRight /></span>
                                </NavLink>
                                <NavLink to='/chats' className={`panel_category_item ${location.hash === '#chat'? 'filterSet': ''}`}>
                                        <span className='text'>Chat</span>
                                        <span className='count chat_count'>143</span>
                                </NavLink>
                        </ul>
                    </div>
            </aside>
  )
}

export default AsideMain