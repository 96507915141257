import React from 'react'
import { motion } from 'framer-motion'
import { ReactComponent as HeroSvg } from '../../../assets/svg/HeroSvg.svg'

import Productive from '../../../assets/img/landing/quesions.png'
import Adapt from '../../../assets/img/landing/Adapt.png'
import Support from '../../../assets/img/landing/Support.png'

const variants = {
   
}
const Goals = () => {
  return (
    <section className='advertise_n'>
        <div className='landing_svg'><HeroSvg /></div>
        <h2 className='section_title '>Our Goals and objectives</h2>
        <h3 className='hero_sub_title'>We aim to build an all in one solution to unite your staffs, teachers, parents and, students on a single system</h3>
       
       <div variants={variants} animate="animate">
            <div className='goal'>
                <div className='goal_desc'>
                    <h3>1. Increase proficiency of staff</h3>
                    <p>Make your staff and teachers more productive by reducing the work load on them, helping them to focus more on teaching</p>
                </div>
                <div  className='goal_img_wrapper'
                >
                    <img src={Productive} className='goal_img' alt='increase productivity of staff' />
                </div>
            </div>
            
            <div className='goal reverse'>
                <div  className='goal_img_wrapper'>
                    <img src={Adapt} className='goal_img' alt='increase productivity of staff' />
                </div>
                <div className='goal_desc'>
                    <h3>2. Boost creativity of teacher</h3>
                    <p>With our online market place where teachers can create content and get paid for their creative service makes our system incredible. We promote the growth of your school both individually and as a community</p>
                </div>
            </div>

            <div className='goal'>
                <div className='goal_desc'>
                    <h3>3. Revolutionize eduction in Africa</h3>
                    <p>Our dream is to make African more productive by building an engaging community where students can access thousands of resources required to become extremely productive at studying and make learning fun!</p>
                </div>
                <div className='goal_img_wrapper'>
                    <img src={Support} className='goal_img' alt='increase productivity of staff' />
                </div>
            </div>
       </div>

        

    </section>
  )
}

export default Goals
