import React, { useState } from 'react'
import ChatPersons from './ChatPersons'
import './Chat.css'

const Chats = () => {
  const [textMessage, setTextMessage] = useState('')
  const persons = [
    {
      "name": "kimbi darlington",
      "id": 12,
    },
    {
      "name": "John doe",
      "id": 98,
    },
    {
      "name": "General",
      "id": 1,
    },
    {
      "name": "Biology",
      "id": 2,
    },
    {
      "name": "Elon musk",
      "id": 3,
    },
    {
      "name": "Mami groundnut",
      "id": 87,
    },
    {
      "name": "Pa sugar cane",
      "id": 183,
    },
  ]
  return (
    <div className='chat_page'>
        <div className='dashboard_container'>
            <ChatPersons persons={persons} />
            <div className='mobile_chat'>Chat cannot be opened here. Click the link to install the mobile app</div>
            <div className='chat_content'>
                <div className='messages'>
                  messages here
                </div>
                <div className='chat_input'>
                    <div className='profile_pic'></div>
                    <div className='message_wrapper'>
                      <textarea placeholder='Write something...' value={textMessage} onChange={e=> setTextMessage(e.target.value)}></textarea>
                      <div className='message_btns'>send</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Chats