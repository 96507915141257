import './Nav.css'

import React, { useEffect, useState } from 'react'
import { VscBell, VscChevronDown, VscClose, VscInspect, VscListSelection } from 'react-icons/vsc'
import { IoChatboxEllipsesOutline, IoClose } from 'react-icons/io5'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import UseCookie from '../../lib/UseCookie'
import { PiGlobeThin } from 'react-icons/pi'
import useLocalStorage from '../../lib/UseLocalStorage'
import Cookies from 'universal-cookie'



const Nav = () => {
        const [showNav, setShowNav] = useState(false)
        const [notificationDropdown, setNotificationDropdown] = useState(false)
        const [profileDropdown, setProfileDropdown] = useState(false)
        const [logoutModal, setLogoutModal] = useState(false)
        const [languageDropdown, setLanguageDropdown] = useState(false)
        const [hasSchool, setHasSchool] = useLocalStorage('hasSchool', false)
        const [user, setUser] = UseCookie('user')

        const navigate = useNavigate()

        const removeNav = (e)=>{
                if(e.target.className === 'nav_link_n' || e.target.className === 'dropdown_text'){
                        setShowNav(false)
                }
        }
        const notificationDropdownHandler = () => {
                setNotificationDropdown(!notificationDropdown)
                setProfileDropdown(false)
                setLanguageDropdown(false)
        }
        const languageDropdownHandler = () => {
                setLanguageDropdown(!languageDropdown)
                setProfileDropdown(false)
                setNotificationDropdown(false)
        }
        const profileDropdownHandler = () => {
                setProfileDropdown(!profileDropdown)
                setNotificationDropdown(false)
                setLanguageDropdown(false)
        }
        const removeModal = (e) => {
                e.stopPropagation()
                setUser('')
                navigate("/");
                window.location.reload()
        }
        
        const logout = (e) => {
                e.stopPropagation()
                deleteAllCookies()
                localStorage.clear();
                window.location.href = '/'
        }
        const deleteAllCookies = () => {
                const cookies = new Cookies().remove('user')
        }
        const removeProfileDropdown = (e) => {
                if(e.target.className === 'nav_link_drop'){
                        setProfileDropdown(false)
                }
        }
        const handlelogoutModal = () => {
                setLogoutModal(true)
                setProfileDropdown(false)
        }
        
        
        
  return (
    <div className='organization_nav'>
        <a href='/'>
                <div className='logo'>Rank</div>
        </a>
        <div className={`shadow ${!showNav ? 'removeshadow': '' }`} onClick={() =>setShowNav(false)} />
        <div className={`shadow desktop_shadow_nav ${!profileDropdown ? 'removeshadow': '' }`} onClick={() =>setProfileDropdown(false)} />
        <div className={`shadow desktop_shadow_nav ${!notificationDropdown ? 'removeshadow': '' }`} onClick={() =>setNotificationDropdown(false)} />
        <nav onClick={removeNav} className={`${showNav ? 'showNavN' : 'hideNav'}`}>
                <div className='nav_left'>
                        <ul>
                                <li className='nav_list_n home_btn_mobile'>
                                        <NavLink className='nav_link_n' to = '/' >Home</NavLink>
                                </li>
                                {
                                        hasSchool
                                        ?
                                        <>
                                                <li className='nav_list_n dashboard_nav_link_tool_tip'>
                                                        <NavLink className='nav_link_n' to = '/dashboard' >Dashboard</NavLink>
                                                </li>
                                                <li className='nav_list_n dashboard_nav_link_tool_tip'>
                                                        <NavLink className='nav_link_n' to = '/classes' >Classes</NavLink>
                                                </li>
                                                {/* <li className='nav_list_n'>
                                                        <NavLink className='nav_link_n' to = '/registration' >Registration</NavLink>
                                                </li> */}
                                                <li className='nav_list_n'>
                                                        <NavLink className='nav_link_n' to = '/students' >Students</NavLink>
                                                </li>
                                                <li className='nav_list_n'>
                                                        <NavLink className='nav_link_n' to = '/time-table' >Time Table</NavLink>
                                                </li>
                                                <li className='nav_list_n'>
                                                        <NavLink className='nav_link_n' to = '/jobs#jobs' >Jobs</NavLink>
                                                </li>
                                        </>
                                        :
                                        <>
                                                <li className='nav_list_n'>
                                                        <NavLink onClick={ (event) => event.preventDefault() } className='nav_link_n disabled_links' >Dashboard</NavLink>
                                                </li>
                                                <li className='nav_list_n'>
                                                        <NavLink onClick={ (event) => event.preventDefault() } className='nav_link_n disabled_links' >Classes</NavLink>
                                                </li>
                                                {/* <li className='nav_list_n'>
                                                        <NavLink  onClick={ (event) => event.preventDefault() }  className='nav_link_n disabled_links' >Registration</NavLink>
                                                </li> */}
                                                <li className='nav_list_n'>
                                                        <NavLink  onClick={ (event) => event.preventDefault() }  className='nav_link_n disabled_links' >Students</NavLink>
                                                </li>
                                                <li className='nav_list_n'>
                                                        <NavLink  onClick={ (event) => event.preventDefault() }  className='nav_link_n disabled_links' >Time Table</NavLink>
                                                </li>
                                                <li className='nav_list_n'>
                                                        <NavLink  onClick={ (event) => event.preventDefault() }  className='nav_link_n disabled_links' >Jobs</NavLink>
                                                </li>
                                        </>
                                }
                        </ul>
                </div>
                <div className='nav_right'>
                        <div className='nav_dropdown'>
                                <span draggable onClick={languageDropdownHandler} className='dropdown_text profile_dropdown'>
                                        <span className='icon'><PiGlobeThin /></span>
                                        <span className='text'>language</span>
                                        
                                </span>
                                <div className={`dropdown_content small lang_dd_content ${languageDropdown ? 'showNotificationDropdown': ''}`}>
                                        <ul>
                                                <li className='nav_link_drop'>English</li>
                                                <li className='nav_link_drop'>French</li>
                                                <li className='nav_link_drop'>Portuguese</li>
                                                <li className='nav_link_drop'>Hindu</li>
                                                <li className='nav_link_drop'>Arabic</li>
                                        </ul>
                                </div>
                        </div>
                        <div className='nav_dropdown'>
                                <span draggable onClick={notificationDropdownHandler} className='dropdown_text profile_dropdown'>
                                        <span className='icon'><VscBell /></span>
                                        <span className='text'>Notifications</span>
                                        
                                </span>
                                <div className={`dropdown_content ${notificationDropdown ? 'showNotificationDropdown': ''}`}>
                                        <div className='notification_header_dropdown'>
                                                <h4>You have 4 new Notifications</h4>
                                                <button className='view_all_notification_btn'>view all</button>
                                        </div>
                                        <div className='notification_list_item'>
                                                <div className='left_section_of_notification'>
                                                        <span className='icon'><IoChatboxEllipsesOutline /></span>
                                                </div>
                                                <div className='right_section_of_notification'>
                                                        <h4>title of notification</h4>
                                                        <p>this is a short message to notify</p>
                                                </div>
                                        </div>
                                        <div className='notification_list_item'>
                                                <div className='left_section_of_notification'>
                                                        <span className='icon'><IoChatboxEllipsesOutline /></span>
                                                </div>
                                                <div className='right_section_of_notification'>
                                                        <h4>title of notification</h4>
                                                        <p>this is a short message to notify is quite longer than the other</p>
                                                </div>
                                        </div>
                                </div>
                        </div>
                        <div className='nav_dropdown'>
                                <span draggable onClick={profileDropdownHandler}  className='dropdown_text profile_dropdown nav_name'>
                                        <span className='icon'><VscChevronDown /></span>
                                        <span className='text bold-text'>{ user?.user?.first_name && user?.user?.first_name.split(' ')[0] }</span>
                                        
                                </span>
                                <div onClick={removeProfileDropdown} className={`dropdown_content small ${profileDropdown ? 'showNotificationDropdown': ''}`}>
                                        <ul>
                                                <li onClick={() => navigate('/settings')} className='nav_link_drop'>settings</li>
                                                <li className='nav_link_drop'>activities</li>
                                                <li className='nav_link_drop'>contact support</li>
                                                <li onClick={handlelogoutModal} className='nav_link_drop logout'>log out</li>
                                        </ul>
                                </div>
                        </div>
                        <div onClick={() => navigate('/plans')} className='nav_dropdown'>
                                <span draggable className='dropdown_text'>
                                        <span className='icon'><VscInspect /></span>
                                        <span className='text'>Plan</span>
                                        
                                </span>
                        </div>
                </div>
        </nav>
        <div className='logo-burger'>
                <span onClick={() => setShowNav(!showNav)} className='burger nav-icon'>
                        {
                                showNav
                                ?
                                <VscClose />
                                :
                                <VscListSelection />
                        }
                </span>
        </div>
        {
                logoutModal && (
                        <div className='logout_modal' onClick={() => setLogoutModal(false)}>
                                <div className='logout_shadow' />
                                <div className='logout_modal_content'>
                                        <h2>This action will remove you from the system</h2>
                                        <div className='log_btn_wrapper'>
                                                <button className='cancel_logout'>cancel</button>
                                                <button onClick={logout} className='logout_btn'>logout</button>
                                        </div>
                                </div>
                        </div>
                )
        }
    </div>
  )
}

export default Nav