import React, { useState } from 'react'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc'
import { ReactComponent as Pay } from './pay.svg'


const DashboardSalary = () => {
  const [showoption_dropdown, setShowoption_dropdown] = useState(false)
  const [showoption_dropdowntotal, setShowoption_dropdowntotal] = useState(false)
  return (
    <section className='finance_transactions salary_desp'>
        { showoption_dropdown && <div onClick={()=> setShowoption_dropdown(false)} className='shadow' /> }
        { showoption_dropdowntotal && <div onClick={()=> setShowoption_dropdowntotal(false)} className='shadow' /> }
        <div className='finance_total'>
                <h2>Total Balance</h2>
                <div className='showoption_dropdown'>
                      <span onClick={() => setShowoption_dropdowntotal(!showoption_dropdowntotal)} className='option_btn'><HiOutlineDotsVertical /></span>
                      {
                        showoption_dropdowntotal && (
                                <div className='dropdown_content'>
                                        content
                                </div>
                        )
                      }
                  </div>
                <div className='finance_grid_item'>
                        <h2>This month</h2>
                        <p className='price'>FCFA 10,000,000</p>
                        <div className='finance_stats'>
                                <p>vs last year total</p>
                                <p><span className='up_stat down_stat'><VscTriangleDown /></span><span>+999 students</span></p>
                        </div>
                </div>
        </div>
        <div className='finance_total'>
                <h2>Total Balance</h2>
                <div className='showoption_dropdown'>
                      <span onClick={() => setShowoption_dropdowntotal(!showoption_dropdowntotal)} className='option_btn'><HiOutlineDotsVertical /></span>
                      {
                        showoption_dropdowntotal && (
                                <div className='dropdown_content'>
                                        content
                                </div>
                        )
                      }
                  </div>
                <div className='finance_grid_item'>
                        <h2>This month</h2>
                        <p className='price'>FCFA 10,000,000</p>
                        <div className='finance_stats'>
                                <p>vs last year total</p>
                                <p><span className='up_stat down_stat'><VscTriangleDown /></span><span>+999 students</span></p>
                        </div>
                </div>
        </div>
        <div className='finance_total pay_date'>
                <h2>Next Pay</h2>
                <div className='finance_grid_item'>
                        <div className='pay_day'>
                                <p className='price'>23 D</p>
                                <p className='price'>14 H</p>
                                <p className='price'>9 M</p>
                                <p className='price'>3 S</p>
                        </div>
                        <div className='pay_emoji'>
                                <Pay />
                        </div>
                </div>
        </div>
           
    </section>
  )
}

export default DashboardSalary