
import React, { useState } from 'react'
import { Actions, AnimateOnboard } from '../../SlideInfomation'
import DesignReportCard from '../../../../../organisation/ReportCard/DesignReportCard'


export const SlideInfomationReportCards = ({ handle_save_data, index, handle_go_back }) => {
    const [logo, setLogo] = useState(null)
    const [school_photo, setSchool_photo] = useState(null)
    const [isloading, setIsloading] = useState(false)
    
  
    const save_data = () => {
      setIsloading(true)
      handle_save_data(index,{} )
    }
    const go_back = () => {
      handle_go_back(index);
    }
  
  return (
    <>
      <AnimateOnboard>
          <section className='school_onboard-1'>
            <div className='onboard_form_control report_card_onboard'>
                <label className='bold-text' htmlFor="name">REPORT CARD DESIGNS</label>
                <p>Please select a report card design. You can change or modify later</p>
                <div className='report_card_template_grid'>
                  <DesignReportCard />                
                </div>
            </div>
          </section>
      </AnimateOnboard>
      <Actions save_data={save_data} handle_go_back={go_back} index={index} isloading={isloading} />
    </>
  )
}
  
  


export default SlideInfomationReportCards;