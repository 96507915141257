import React, { useEffect, useState } from 'react'
import { Actions, AnimateOnboard } from '../../SlideInfomation'
import { SlNotebook } from 'react-icons/sl'
import useLocalStorage from '../../../../../lib/UseLocalStorage'
import { postTenant } from '../../../../../Resquest'



const SlideInfomationDepartment = ({ user, handle_save_data, index, handle_go_back }) => {
    
    const [selected_languages, setSelected_languages] = useState([])
    const [show_department_list, setShow_department_list] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [department_name, setdepartment_name] = useState('')
    const [currentSchool, setCurrentSchool ] = useLocalStorage('currentSchool', null)
    const [department, setDepartment ] = useLocalStorage('department', {})

    const department_list = [
        "General",
        "Technical",
        "Commercial",
        "Other"
    ]
    

    
    const save_data = () => {
      setIsloading(true)
      let list = languages.filter(lang => lang.is_selected)
      setSelected_languages(list)

      // if(department){
      //   return handle_save_data(index, {})
      // }
      create_departement(list)
      .then(() =>  setIsloading(false))
     
    }

    const create_departement = async(list) => {
      let languages = []
      list.forEach(lang => languages.push(lang.lang));

      const data = {
        "name": department_name.toLowerCase(),
        "language_supports": languages,
        "school": currentSchool?.id
      }

      const response = await postTenant(user?.school_code, '/departments/', data)
      response.status === 201 && setDepartment(response.data)
      response.status === 201 && handle_save_data(index, {})
    }
    const go_back =() => {
      handle_go_back(index)
    }

    const language_list = [
      {lang: "English", is_selected: false},
      {lang: "Français", is_selected: false},
      {lang: "普通话", is_selected: false},
      {lang: "吴语", is_selected: false},
      {lang: "Español", is_selected: false},
      {lang: "العربية", is_selected: false},
      {lang: "Português", is_selected: false},
      {lang: "Deutsch", is_selected: false},
      {lang: "한국어", is_selected: false},
      {lang: "日本語", is_selected: false},
      {lang: "Русский", is_selected: false},
    ]
    const [languages, setLanguages] = useState( language_list )
 
    
    const handleCheck = (lang) => {

      setLanguages(prevLanguages => prevLanguages.map(language =>
        language.lang === lang.lang ? { ...language, is_selected: !language.is_selected } : language
      ));
    //   let updatedList = [...school_types];
    //   if (event.target.checked) {
    //     updatedList = [...school_types, event.target.value];
    //   } else {
    //     updatedList.splice(school_types.indexOf(event.target.value), 1);
    //   }
    //   setSchool_types(updatedList);
    };
  return (
    <>
      <AnimateOnboard>
        <section className='school_onboard-1'>
          <div className='onboard_form_control'>
              <label htmlFor="name">Select department</label>
              <div className='onboard_input_wrapper'>
                <input onFocus={() => setShow_department_list(true) } type="text" value={department_name} onBlur={() => setShow_department_list(false)} onChange={e=> setdepartment_name('')} />
                <span className='form_control_icon'><SlNotebook /></span>
              </div>
              {
                show_department_list && (
                  <div className='onboard_search_response'>
                    {
                      department_list.map((department, index) => (
                        <div key={index} className='country_list_item' onMouseDown={() => setdepartment_name(department)}>
                          <span className="countryname">{ department }</span>
                        </div>
                      ))
                    }
                  </div>
                )
              }
          </div>
          <div className='onboard_form_control'>
              <label htmlFor="name">Supported languages <span className='small bold-text'>(You can change it later at any time)</span></label>
              <div className='list_of_supported_languages'>
                {
                    languages.map((language, index) => (
                    <OnboardLanguageBtn language={language}  key={index} handleCheck={handleCheck} />
                    ))
                }
              </div>
          </div>
        </section>
      </AnimateOnboard>
      <Actions save_data={save_data} handle_go_back={go_back} isloading={isloading} />
    </>
  )
}

const OnboardLanguageBtn = ({ handleCheck, language }) => {
  return (
    <div className="onboard_checkbox_wrapper onboard_language_wrapper ">
      <label style={{ cursor: 'pointer' }} className={`${language.is_selected ? 'onboard_select_language' : ''}`} htmlFor={ language.lang }>{ language.lang  }</label>
      <input type="checkbox" value={language.lang } checked={ language.is_selected } hidden onChange={() => handleCheck(language)}  id={ language.lang } />
      <p className='onboard_language_hidden_text'>{ language.lang }</p>
    </div>
  )
}

export default SlideInfomationDepartment;