import React, { useEffect, useLayoutEffect, useState } from 'react'
import Nav from '../organisation/nav/Nav'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { getToken } from "firebase/messaging";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';


import NavSecondary from '../organisation/nav/NavSecondary'
import DesignReportCard from '../organisation/ReportCard/DesignReportCard'
import PlanPage from '../USER/Plans/PlanPage'
import AdminDashboard from '../USER/DashBoard/AdminDashboard'
import StaffManagement from '../USER/DashBoard/StaffManagement'
import CreateStaff from '../USER/Wizard/CreateStaff'
import Footer from '../Footer/Footer'
import AdminDashboardActivity from '../USER/DashBoard/profile/SubComp/AdminDashboardActivity'
import AdmissionForm from '../USER/admission/AdmissionForm'
import Classes from '../USER/class/Classes'
import ClassDetails from '../USER/class/ClassDetails'
import Chats from '../organisation/Chat/Chats'
import JobView from '../organisation/Jobs/JobView'
import UseCookie from '../lib/UseCookie'
import Students from '../organisation/students/Students'
import StudentDetails from '../organisation/students/StudentDetails'
import Home from '../USER/home/Home'
import Setting from '../organisation/settings/Setting'
import SecurityPage from '../organisation/security/SecurityPage'
import CreateSchool from '../onboard/School/CreateSchool'
import NewSchoolApp from '../onboard/School/NewSchoolApp';
import useLocalStorage from '../lib/UseLocalStorage';
import Guardians from '../organisation/Guardians/Guardians';
import StaffDetails from '../USER/DashBoard/profile/Staff/StaffDetails';
import { getTenant } from '../Resquest';
import ClassSettings from '../USER/class/ClassSettings';
import Timetable from '../organisation/timetable/Timetable';
import FeePaymentStatus from '../onboard/transactions/FeePaymentStatus';
import StudentRegistrationList from '../organisation/students/StudentRegistrationList';

const AppUser = () => {
  const [showFooter, setshowFooter] = useState(true)
  const [user, setUser] = UseCookie('user')
  const [isHomePage, setIsHomePage] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  
  useLayoutEffect(() => {
    user?.user  === false && navigate('/')
    (location.pathname.includes('chats')) && setshowFooter(false)
  }, [])


  

  const setup_notification = async() => {
    if ('serviceWorker' in navigator) {
      const req = navigator.serviceWorker.register('/sw.js')
      
      const permission = await Notification.requestPermission()
      try{
        if (permission === 'granted') {
          // const registration = await navigator.serviceWorker.ready
          // const applicationServerKey = "BDAyDz3xeQIiOYXIsuQ2SZFiO7wNqG8IUObRQWoxfejhQXXlhmpHe9aidpwMt6TTsD8CY7Xtf0gk6YuSWWULGmM"
  
          // const subscription = await registration.pushManager.subscribe({
          //   userVisibleOnly: true,
          //   applicationServerKey: applicationServerKey
          // })
  
          // register_device(subscription.endpoint);

          // const token = await getToken(messaging, {
          //   vapidKey: "BDAyDz3xeQIiOYXIsuQ2SZFiO7wNqG8IUObRQWoxfejhQXXlhmpHe9aidpwMt6TTsD8CY7Xtf0gk6YuSWWULGmM"
          // })

          // console.log('Token generated: ', token);
  
        }
      }
      catch(err) {
        console.warn(err);
      }
      

    }
  }

  const register_device = async(registration_id) => {
    const request = {
      "registration_id": registration_id.split(':')[2],
      "type": "web"
    }
    try {
      const res = await registerDeviceAPI(request)
      console.log(res);
    }
    catch (error) {
      console.warn(error);
    }
  }

  /**
   * Make a request to the server to register the device
   * @param { Object } request request object for the device registration
   * @returns Response from server
   */
  const registerDeviceAPI = async(request) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzEwNDI0NTY0LCJpYXQiOjE3MTAzMzgxNjQsImp0aSI6ImNjYTRiNjMyODc1YTQzMzc4ZjAyOTZhYTg4MmEwNGJkIiwidXNlcl9pZCI6Mn0.GJeaYBMcFJy1vQTDmIRiJK7DtYRgQT0UCa530-Z55Y0"
      }
    }
    const response = await axios.post(`https://rankafrica.site/api/v1/devices/register/`, request, config)
    return response

  }

  

  useEffect(() => {
    // setup_notification()
  }, [])


  const receive_messages = async() => {
    
  }
  
  return (
    <div>
      {
        location.pathname.startsWith('/onboard') ? 
        <Routes>
          <Route path='/onboard/new-school/' element={ <NewSchoolApp />} />
          <Route path='/onboard/create-school/*' element={ <CreateSchool />} />
        </Routes>
        :
        <AppStack location={location.pathname} showFooter={showFooter} />
      }
    </div>
  )
}

export default AppUser


const AppStack = ({ location, showFooter }) => {
  const [hasSchool, setHasSchool] = useLocalStorage('hasSchool')
  const [tenantUser, setTenantUser] = useLocalStorage('tenantUser', )
  const [currentSchool, setCurrentSchool ] = useLocalStorage('currentSchool')

  useEffect(() => {
    tenantUser?.user?.schools.length > 0 ? setHasSchool(true) : setHasSchool(false)
    fetch_school()
  }, [])

  const fetch_school = async() => {
    if(!currentSchool){
      const response = await getTenant(tenantUser?.server, '/schools/')
      response.status === 200 && setCurrentSchool(response?.data[0])
    }
  }
  
  const [steps, setSteps] = useState([
    {
      target: '.dashboard_nav_link_tool_tip',
      content: 'This is link will direct you to the dashboard showing you all the functionalities of your school',
    },
    {
      target: '.registered_school_list',
      content: 'Shows you all you schools you are  a member in and option to create a school. click on school to login',
    },
    {
      target: '.main_aside',
      content: 'Side panel for system. help you navigate through features offered in your school',
    }
  ])
  const [run, setRun] = useState(true);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun( false );
    }

    // logGroup(type, data);
  };

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Nav user={tenantUser} />
      { location !== '/' && hasSchool && <NavSecondary /> }
      <div className='organisation_wrapper'>
        <Routes>


          {
            hasSchool
            ? (
              <>
              <Route path='/jobs'  element={<JobView />} />
              <Route path='/report-card-designs'  element={<DesignReportCard />} />
              <Route path='/dashboard/calendar'  element={<AdminDashboardActivity />} />

              <Route path='/staffs'  element={<StaffManagement user={tenantUser} school={currentSchool} />} />
              <Route path='/staffs/:id'  element={<StaffDetails server={ tenantUser?.server } />} />
              <Route path='/staffs/create'  element={<CreateStaff server={ tenantUser?.server } />} />
              
              <Route path='/students'  element={<Students server={ tenantUser?.server } />} />
              <Route path='/filter/students/registration'  element={<StudentRegistrationList server={ tenantUser?.server } />} />
              <Route path='/students/:id'  element={<StudentDetails server={ tenantUser?.server } />} />
              <Route path='/students/admission'  element={<AdmissionForm  server={ tenantUser?.server } />} />
              
              <Route path='/parents'  element={<Guardians server={tenantUser?.server} />} />
              <Route path='/chats'  element={<Chats />} />

              <Route path='/classes'  element={<Classes server={ tenantUser?.server } />} />
              <Route path='/classes/settings'  element={<ClassSettings server={ tenantUser?.server } />} />
              <Route path='/classes/:id'  element={<ClassDetails server={tenantUser?.server} />} />
              
              <Route path='/security'  element={<SecurityPage />} />
              <Route path='/time-table'  element={<Timetable server={tenantUser?.server} />} />

              {/* Transctions */}
              <Route path='/fee-payment/status'  element={<FeePaymentStatus server={tenantUser?.server} />} />

              </>
            ) : (
              <Route path='/no-school' element={ <NoSchool />} />
              )
            }
            {/* WHITELISTED ROUTES  */}
          <Route path='/dashboard'  element={<AdminDashboard server={ tenantUser?.server } />} />

          {/* PUBLICK ROUTES  */}
          <Route path='/settings'  element={<Setting />} />
          <Route path='/plans'  element={<PlanPage />} />
          <Route path='/'  element={<Home />} />
        </Routes>
      </div>
      { showFooter && <Footer /> }
    </>
  )
}


const NoSchool = () => {
  return (
    <div className='no_school_route'>
      <h2>You do not belong to any school known to rank</h2>
      <h4>Think we made an error, contact admin</h4>
      <Link to='/contact-us'>Contact admin</Link>
    </div>
  )
}
