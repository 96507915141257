import React from 'react'
import { useNavigate } from 'react-router-dom'
import Persona from '../persona.jpeg'
import Johndoe from '../johndoe.jpg'

const PersonKanban = ({ list }) => {
        const navigate = useNavigate()
        console.log(list);
  return (
        <div onClick={() =>navigate('/staff/id')} className='person'>
                <div className='person_profile_pic'>
                        {
                                list?.user?.avatar
                                ?
                                <img src={Johndoe} alt='john doe placeholder' />
                                :
                                <img src={Persona} className='person_profile_pic_persona' alt='john doe placeholder' />
                        }
                </div>
                <div className='person_profile_desc'>
                        <h2>{ list?.user?.first_name } { list?.user?.last_name }</h2>
                        <p className='bold-text' style={{textTransform: 'capitalize'}}>{ list?.role }</p>
                        <p className='small'>{ list?.user?.email }</p>
                        <p className='small'>{ list?.user?.phone }</p>
                </div>
                <span className={`color-dot ${ list?.user?.is_superuser ? 'admin_person' : list?.user?.is_staff ? 'teacher_person': '' }`}></span>
        </div>
  )
}

export default PersonKanban