import React, { useEffect, useState } from 'react'
import { FaUsers } from 'react-icons/fa'
import { FiFilter } from 'react-icons/fi'
import { IoListSharp } from 'react-icons/io5'
import { BsUiChecksGrid } from 'react-icons/bs'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'


import Persona from '../../USER/DashBoard/profile/persona.jpeg'
import PersonKanban from '../../USER/DashBoard/profile/views/PersonKanban'
import PersonTree from '../../USER/DashBoard/profile/views/PersonTree'
import useLocalStorage from '../../lib/UseLocalStorage'
import Search from '../../Addons/Search'
import { getTenant } from '../../Resquest'
import { ReactComponent as Loader } from '../../assets/LoaderOval.svg'

const StudentRegistrationList = ({ server }) => {
    const [viewMethod, setViewMethod] = useLocalStorage('viewType', 'tree')
    const [students, setStudents] = useState([])
    const [isloading, setIsloading] = useState(false)
    const [genderGroup, setGenderGroup] = useState({})
    const [regGroup, setRegGroup] = useState({})
    const [classGroup, setClassGroup] = useState({})
    const [allStudents, setAllStudents] = useState(0)

    const navigate = useNavigate()
    const staffCount = new Array(19).fill(0)
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const filter = queryParams.get('registration');
    
    const filterList = () => {
        const filterBy = location.hash
    }

    useEffect(() => {
        setIsloading(true)
        get_students()
    }, [location])

    const get_students = async() => {
        let response;
        if(filter){
            console.log('filter: ', filter);
            response = await getTenant(server, `/filter/registration/?status=${filter}`)
        }
        console.log('response', response);
        // response?.status === 200 && setStudents(response?.data)
        setIsloading(false)
    }
    
    useEffect(() => {
        fetch_analytics()
    }, [])
    
    const fetch_analytics = async() => {
        const res = await getTenant(server, '/analytics/students/')
        if(res?.status === 200) {
                setRegGroup(res?.data?.registrations)
                setClassGroup(res?.data?.students_in_classes)
                let genders = {
                        female: res?.data?.female_students || 0,
                        male: res?.data?.male_students || 0
                }
                setGenderGroup(genders)
        }
    }  


  return (
    <div className='staff_management'>
        <div className='user_grid'>
                <aside className='search_panel'>
                        <h2 className="panel_title">
                                <span className='icon'><FaUsers /></span>
                                <span className='bold-text'>Gender</span>
                        </h2>
                        <div className='panel_category_list'>
                            <ul onClick={filterList}>
                                <NavLink to='' className={`panel_category_item ${location.hash === ''? 'filterSet': ''}`}>
                                        <span className='text'>All</span>
                                        <span className='count'>{ allStudents || students.length }</span>
                                </NavLink>
                                <NavLink to='/students?filter=gender&type=male#male' className={`panel_category_item ${location.hash === '#male'? 'filterSet': ''}`}>
                                        <span className='text'>Male</span>
                                        <span className='count'>{ genderGroup.male || 0}</span>
                                </NavLink>
                                <NavLink to='/students?filter=gender&type=female#female' className={`panel_category_item ${location.hash === '#female'? 'filterSet': ''}`}>
                                        <span className='text'>Female</span>
                                        <span className='count'>{ genderGroup.female || 0 }</span>
                                </NavLink>
                            </ul>
                        </div>
                        <h2 className="panel_title">
                                <span className='icon'><FaUsers /></span>
                                <span className='bold-text'>Registration</span>
                        </h2>
                        <div className='panel_category_list'>
                            <ul onClick={filterList}>
                                <NavLink to='/filter/students/registration?registration=complete#complete' className={`panel_category_item ${location.hash === '#complete'? 'filterSet': ''}`}>
                                        <span className='text'>Completed</span>
                                        <span className='count'>{ regGroup?.complete || 0 }</span>
                                </NavLink>
                                <NavLink to='/filter/students/registration?registration=partial#partial' className={`panel_category_item ${location.hash === '#partial'? 'filterSet': ''}`}>
                                        <span className='text'>Partial</span>
                                        <span className='count'>{ regGroup?.partial || 0 }</span>
                                </NavLink>
                                <NavLink to='/filter/students/registration?registration=not_registered#not_registered' className={`panel_category_item ${location.hash === '#not_registered'? 'filterSet': ''}`}>
                                        <span className='text'>Not paid</span>
                                        <span className='count'>{ regGroup?.not_registered || 0 }</span>
                                </NavLink>
                            </ul>
                        </div>
                        <h2 className="panel_title">
                                <span className='icon'><FaUsers /></span>
                                <span className='bold-text'>Departments</span>
                        </h2>
                        <div className='panel_category_list'>
                                <ul onClick={filterList}>
                                        <NavLink to='' className={`panel_category_item ${location.hash === ''? 'filterSet': ''}`}>
                                                <span className='text'>All</span>
                                                <span className='count'>{ allStudents || students.length }</span>
                                        </NavLink>
                                        {Object.entries(classGroup).map(([school, info]) => (
                                                <NavLink key={school} to={`/students?filter=cls&type=${info.class_id}#${info.class_id}`} className={`panel_category_item ${location.hash === '#form_1'? 'filterSet': ''}`}>
                                                        <span className='text'>{ school }</span>
                                                        <span className='count'>{info.student_count}</span>
                                                </NavLink>
                                        ))}
                                        
                                </ul>
                        </div>
                </aside>
                
                <main className='main_user_section'>
                        <div className='controls'>
                                <button className='person_create_btn' onClick={()=> navigate('/students/admission')}>Enroll Student</button>
                                <div className='view_types'>
                                        <Search cat='students' data={students} />
                                        <button onClick={()=> setViewMethod('tree')} className={`view_type_btn ${viewMethod === 'tree' ? 'selected_view_type': ''}`}><IoListSharp /></button>
                                        <button onClick={()=> setViewMethod('kanban')} className={`view_type_btn ${viewMethod === 'kanban' ? 'selected_view_type': ''}`}><BsUiChecksGrid /></button>
                                </div>
                        </div>
                               {
                                        isloading
                                        ?
                                        <div className='loading_black'>
                                                <Loader />
                                        </div>
                                        :
                                       viewMethod === 'kanban'
                                       ?
                                       (
                                        <div className='user_main_grid'>
                                                {
                                                 students.length > 0 && students.map(student => (
                                                        //  <PersonKanban key={item} />
                                                        <div key={student?.id} onClick={() =>navigate(`${ student?.id }`)} className='person'>
                                                                <div className='person_profile_pic'>
                                                                        {
                                                                        student?.user?.avatar
                                                                        ?
                                                                        <img src={student?.user?.avatar} alt='john doe placeholder' />
                                                                        :
                                                                        <img src={Persona} alt='john doe placeholder' />
                                                                        }
                                                                </div>
                                                                <div className='person_profile_desc'>
                                                                <h2>{ student?.user?.first_name } { student?.user?.last_name }</h2>
                                                                <p className='bold-text'>{student?.matricule}</p>
                                                                <p className='small'>{ student?.user?.email }</p>
                                                                <br/><br/>
                                                                <p className='small'>{ student?.user?.student_class?.level } { student?.user?.student_class?.name }</p>
                                                                </div>
                                                                <span className='color-dot teacher_person'></span>
                                                        </div>
                                                 ))
                                                }
                                        </div>

                                ):
                                        
                                       <PersonTree students={students} />
                               } 
                </main>
        </div>
    </div>
  )
}

export default StudentRegistrationList
