import React from 'react'
import { BsStarFill } from 'react-icons/bs'

const RatingStars = ({ rating }) => {
  return (
        <div>
        {
                rating.map(rate => (
                        <span className='rating'><span className='icon'><BsStarFill /></span></span>
                ))
        }
        </div>
  )
}

export default RatingStars