import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Register } from '../Auth/Register'
import Landing from '../NONUSER/Home/Landing'
import { Login } from '../Auth/Login'
import { AccountCreateInfo } from '../Auth/AccountCreateInfo'
import { ForgotPasswordEmailForm } from '../Auth/ForgotPasswordEmailForm'
import { ResetLinkInfo } from '../Auth/ResetLinkInfo'
import { ResetPassword } from '../Auth/ResetPassword'
import { VerifyEmail } from '../Auth/VerifyEmail'
import Nav from '../NONUSER/Navbar/Nav'
import ContactUs from '../Contact/ContactUs'
import { Invitation } from '../Auth/Invitation'
import FaqPage from '../NONUSER/Home/Faq/FaqPage'
import AboutPage from '../NONUSER/Home/About/AboutPage'
import { InvitationRequest } from '../Auth/InvitationRequest'

const AppN = () => {
  return (
    <div>
      {/* <SlideInNotifications /> */}
      <Nav />
        <Routes>
                <Route path='/info_createaccount' element={ <AccountCreateInfo /> } />
                <Route path='/verify-email' element={ <VerifyEmail /> } />
                <Route path='/invitation' element={ <Invitation /> } />
                <Route path='/forgot-password' element={ <ForgotPasswordEmailForm /> } />
                <Route path='/reset-password' element={ <ResetPassword /> } />
                <Route path='/reset_link_info' element={ <ResetLinkInfo /> } />
                <Route path='/contact-us' element={ <ContactUs /> } />
                <Route path='/invitation/accept' element={ <InvitationRequest /> } />
                <Route path='/register' element={ <Register /> } />
                <Route path='/create content' element={ <Login /> } />
                <Route path='/login' element={ <Login /> } />
                <Route path='/about' element={ <AboutPage /> } />
                <Route path='/faq' element={ <FaqPage /> } />
                <Route path='/home' element={ <Landing /> } />
                <Route path='/' element={ <Landing /> } />
        </Routes>
    </div>
  )
}

export default AppN