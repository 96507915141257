import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const CountUpOnScrollWelcome = () => {
  const [counted, setCounted] = useState(false);
  const [visibleRef, isVisible] = useInView({ threshold: 0 });

  const [usersCount, setUsersCount] = useState(0);
  const [schoolsCount, setSchoolsCount] = useState(0);
  const [collegesCount, setCollegesCount] = useState(0);

  useEffect(() => {
    if (isVisible && !counted) {
      let usersCounter = 0;
      let schoolsCounter = 0;
      let collegesCounter = 0;

      const usersInterval = setInterval(() => {
        if (usersCounter <= 5000) {
          setUsersCount(usersCounter);
          usersCounter += 100;
        } else {
          clearInterval(usersInterval);
        }
      }, 10);

      const schoolsInterval = setInterval(() => {
        if (schoolsCounter <= 200) {
          setSchoolsCount(schoolsCounter);
          schoolsCounter += 1;
        } else {
          clearInterval(schoolsInterval);
        }
      }, 50);

      const collegesInterval = setInterval(() => {
        if (collegesCounter <= 100) {
          setCollegesCount(collegesCounter);
          collegesCounter += 1;
        } else {
          clearInterval(collegesInterval);
        }
      }, 50);

      setCounted(true);
    }
  }, [isVisible, counted]);

  return (
    <div className='home_countdown' ref={visibleRef}>
      <div className='countdown_flex'>
        <div className='countdown_item'>
          <h2>+{usersCount}</h2>
          <p>Users</p>
        </div>
        <div className='countdown_item'>
          <h2>+{schoolsCount}</h2>
          <p>Active schools</p>
        </div>
        <div className='countdown_item'>
          <h2>+{collegesCount}</h2>
          <p>Active colleges</p>
        </div>
      </div>
    </div>
  );
};



export default CountUpOnScrollWelcome;
