import React, { useState, useCallback, useEffect } from 'react';
import './Timetable.css'
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ReactComponent as Loader } from '../../assets/LoaderOval.svg'
import { getTenant } from '../../Resquest';
import TimeTableModal from './TimeTableModal';
import SlideInNotifications from '../../Addons/SlideNotification';


const Timetable = ({ server }) => {
  const [notification, setNotification] = useState({})
  const [showNotify, setShowNotify] = useState(false)

  const [terms, setTerms] = useState({})
  const [fetchingTerms, setFetchingTerms] = useState(false)
  const [classes, setClasses] = useState([])
  const [fetchingClasses, setFetchingClasses] = useState(false)
  const [fetchingEvents, setFetchingEvents] = useState(false)

  const [selectedTerm, setSelectedTerm] = useState({})
  const [selectedClass, setSelectedClass] = useState({})


  const [dayIndex, setDayIndex] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)

  const [showTimeTableModal, setShowTimeTableModal] = useState(false)

  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetch_terms()
    fetch_classes()

  }, [])

  useEffect(() => {
    fetch_time_table(selectedClass, selectedTerm)
  }, [selectedTerm, selectedClass])
  

  const fetch_terms = async() => {
    setFetchingTerms(true)
    const response = await getTenant(server, '/terms/')
    if(response?.status === 200) {
        setTerms(response.data)
        response?.data?.active_term?.id  &&  setSelectedTerm(response?.data?.active_term)
    }
    setFetchingTerms(false)
  }
    
  const fetch_classes = async() => {
      setFetchingClasses(true)
      const response = await getTenant(server, '/classes/')
      if(response?.status === 200) {
        setClasses(response?.data)
        setSelectedClass(response?.data[0])
      }
      setFetchingClasses(false)
  }
    
  const select_term = (term) => {
    setSelectedTerm(term)
  }
    
  const select_class = (cls) => {
    setSelectedClass(cls)
  }

  const fetch_time_table = async(cls, term) => {
    if(!cls?.id || !term?.id) return
    setFetchingEvents(true)
    console.log(cls.id, term.id);
    const response = await getTenant(server, `/timetables/${cls.id}/${term?.id}/`)
    console.log(response);
    if(response?.status === 200) {
      setEvents(response?.data)
    }
    setFetchingEvents(false)
  }



  const handleDateSelect = (selectInfo) => {
    setShowTimeTableModal(true)
    setStartTime(selectInfo.startStr.split('T')[1])
    setEndTime(selectInfo.endStr.split('T')[1])
    setDayIndex(selectInfo.start.getDay())
    
    let calendarApi = selectInfo.view.calendar;
    // calendarApi.unselect();

    // if (title) {
    //   setEvents((prevEvents) => [
    //     ...prevEvents,
    //     {
    //       id: Math.random(),
    //       title,
    //       startTime: selectInfo.startStr.split('T')[1],
    //       endTime: selectInfo.endStr.split('T')[1],
    //       daysOfWeek: [selectInfo.start.getDay()],
    //     },
    //   ]);
    // }
  };

  const handleRemoveModal = (event) => {

    if(!event) { 
      setShowNotify(true)
      setNotification({ text: "Unable to save new Event. Try again later!", id: Math.random(), status: 'fail' })
    }
    else {
      setEvents((prevEvents) => [
        ...prevEvents,
        {
          id: Math.random(),
          subject: event.subject,
          startTime: startTime,
          endTime: endTime,
          daysOfWeek: [dayIndex],
        },
      ]);
      setShowNotify(true)
      setNotification({ text: "Event saved successfully!", id: Math.random(), status: 'success' })
    }
    setShowTimeTableModal(false)
  }

  const handleEventClick = (clickInfo) => {
    let subject = prompt('Edit the subject:', clickInfo.event.extendedProps.subject);
    let instructor = prompt('Edit the instructor:', clickInfo.event.extendedProps.instructor);

    if (subject && instructor) {
      clickInfo.event.setProp('title', { subject, instructor });
    }
  };



  const submit = () => {
    console.log(events)
  }


  /**
   * Component that renders custom container content
   * @param {enventObject} eventInfo for the current event being displayed
   * @returns JSX for calendar
   */
  const renderEventContent = (eventInfo) => {
    const subject = eventInfo.event.extendedProps.subject;
    
    const generateRandomColor = () => {
      return '#' + Math.floor(Math.random()*16777215).toString(16) + '22';
    };

    const backgroundColor = generateRandomColor()
    return (
      <div style={{ backgroundColor }} className='timetable_event'>
        <b>{eventInfo.timeText}</b>
        <div>{subject?.name} - {subject?.instructor?.user?.first_name} {subject?.instructor?.user?.last_name}</div>
      </div>
    );
  };


  return (
    <div className='time_table_page'>
      <div className='time_table_aside'>
          {
            fetchingTerms
            ?
            <div className='time_fetching_data'>
              <div>Fetching Terms...</div>
              <div className="loading_data_loader"><Loader /></div>
            </div>
            :
            <section className='time_table_section_wrapper'>
              {
                terms?.active_term?.id
                && <div 
                  onClick={ () => select_term(terms?.active_term) } 
                    className={`select_time_term ${selectedTerm?.id === terms?.active_term?.id ? 'active' : ''}`}
                  >
                      Current term
                    </div>
              }
              {
                terms?.terms && terms?.terms.map(term => (
                  <div 
                    key={term?.id} 
                    onClick={ () => select_term(term) } 
                    className={`select_time_term ${term?.id === selectedTerm?.id ? 'active' : ''}`}>
                      Term {term?.term_name}
                    </div>
                ))
              }
              
            </section>
          }
          
          
          {
            fetchingClasses
            ?
            <div className='time_fetching_data'>
              <div>Fetching classes...</div>
              <div className="loading_data_loader"><Loader /></div>
            </div>
            :
            <section className='time_table_section_wrapper'>
              {
                classes.map(cls => (
                  <div 
                    key={cls?.id} 
                    onClick={() => select_class(cls)}  
                    className={`select_time_term ${cls?.id === selectedClass?.id ? 'active' : ''}`}
                  >
                    {cls?.level?.name} {cls?.class_name}
                  </div>

                ))
              }
            </section>
          }
      </div>
      <div className='time_table_wrapper'>
        {/* <button className='btn timetable_btn' onClick={submit}>Save timetable</button> */}
        {
          fetchingEvents
          &&
          <div className='fetching_timetable_events'>
            <div className="loading_data_loader"><Loader /></div>
          </div>
        }
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          allDaySlot={false}
          slotDuration="00:45:00"
          slotLabelInterval="00:45:00"
          slotMinTime="08:00:00" 
          slotMaxTime="17:00:00" 
          headerToolbar={false} 
          weekends={false} 
          selectable={true}
          selectMirror={true}
          select={handleDateSelect}
          events={events}
          editable={true}
          eventClick={handleEventClick}
          eventContent={renderEventContent} 
        />
      </div>
      <TimeTableModal dayIndex={dayIndex} handleRemoveModal={handleRemoveModal} startTime={startTime} endTime={endTime}  server={server} showPopup={showTimeTableModal} cls_id={selectedClass?.id} term_id={selectedTerm?.id}  />
      <SlideInNotifications triggered={showNotify} notification={notification} />
    </div>
  );
};

export default Timetable;
