import React, { useState } from 'react'

import './wizard.css'
import { postRequest } from '../../serverRequests'
import { AiOutlineCalendar, AiOutlineUser } from 'react-icons/ai'
import { postTenant } from '../../Resquest'
import { useNavigate } from 'react-router-dom'
import SlideInNotifications from '../../Addons/SlideNotification'

const CreateStaff = ({ server }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  const [address, setAddress] = useState('')
  const [dob, setDob] = useState('')
  const [gender, setGender] = useState('')
  const [bank, setBank] = useState('')
  const [Swift, setSwift] = useState('')
  const [bio, setBio] = useState('')
  const [expCount, setExpCount] = useState(0)
  const [showJobModal, setShowJobModal] = useState(false)
  const [genderDropDown, setGenderDropDown] = useState(false)
  const [isloading, setIsloading] = useState(false)

  // notify
  const [nofication, setNofication] = useState('')
  const [showNotify, setShowNotify] = useState(false)

  const navigate = useNavigate()

  const requestData = {
    "first_name": firstName,
    "last_name": lastName,
    "email": email,
    "phone": phone,
    "role": role,
    "address": address,
    "gender": gender,
//     "dob": dob,
//     "swift": Swift,
//     "bank": bank,
//     "bio": bio,
//     'expirience': expCount,
  }

  const work_roles = ['teacher', 'accountant', 'general', 'guardian', 'librarian', 'nurse', 'security', 'supervisor', 'other']

  const submitForm = (e) => {
    e.preventDefault()
    setIsloading(true)
    registerStaff()
  }

  const registerStaff = async() => {
        const res = await postTenant( server, "/staffs/", requestData)
        if(res?.status === 201) {
                setNofication({'text': 'Staff created successfully', 'status': 'success', 'id': Math.random()})
                setShowNotify(true)
                navigate('/staffs')
        }
        if(res?.response?.status === 400 && res?.response?.data?.message) {
                setNofication({'text': res?.response?.data?.message , 'status': 'info', 'id': Math.random()})
                setShowNotify(true)
        }
        else {
                setNofication({'text': 'An error occured', 'status': 'error', 'id': Math.random()})
                setShowNotify(true)
        }
        setIsloading(false)
  }

  const removeJobModal = () => {
        setTimeout(() => {
          setShowJobModal(false)
        }, 200)
  }
  const removeGenderDropDown = () => {
        setTimeout(() => {
          setGenderDropDown(false)
        }, 200)
  }

  return (
    <div className='creation_wizard'>
        <SlideInNotifications notification={nofication} triggered={showNotify} />
        <div className='container'>
          <div className='authenticate_form creation_form'>
                <h1 className='title'>Welcome to the employment wizard.</h1>
                <div className='bold-text'>Fill in the form to add a new staff member</div>
                <form onSubmit={submitForm}>
                        <section className='create_user_section'>
                                <aside className='staff_creation_aside'>
                                        <h2 className='create_section_title'>Profile Picture</h2>
                                        <div className='profile_pic'>
                                                <span className='icon'><AiOutlineUser /></span>
                                                <input type='file' accept='image/*' />
                                        </div>
                                </aside>
                                <main>
                                        <div className='nameWrapper inputflex'>
                                                <div className='sides'>
                                                        <label htmlFor='fname' className='input_label'>First Name</label>
                                                        <input value={firstName} onChange={e => setFirstName(e.target.value)} type='text' name='fname' className='auth_input' />
                                                </div>
                                                <div className='sides'>
                                                        <label htmlFor='lname' className='input_label'>Last Name</label>
                                                        <input value={lastName} onChange={e => setLastName(e.target.value)} type='text' name='lname' className='auth_input' />
                                                </div>
                                        </div>
                                        <div className='nameWrapper'>
                                                <label htmlFor='email' className='input_label'>Email</label>
                                                <input value={email} onChange={e => setEmail(e.target.value)} type='email' name='email' className='auth_input' />
                                        </div>
                                        <div className='nameWrapper'>
                                                <label htmlFor='phone' className='input_label'>Phone</label>
                                                <input  value={phone} onChange={e => setPhone(e.target.value)} type='text' name='phone' className='auth_input' />
                                        </div>
                                        <div className='nameWrapper'>
                                                <label htmlFor='job' className='input_label'>Role</label>
                                                <input onFocus={()=> setShowJobModal(true)} onBlur={removeJobModal} value={role} onChange={e => setRole(e.target.value)} type='text' name='job' placeholder='e.g: teacher' className='auth_input' />
                                                <div className={`dropdown ${showJobModal ? 'showdropdownJob': ''}`}>
                                                        {
                                                        work_roles.map((role, index) => (
                                                                <div key={index} onClick={() => setRole(role)} className='dropdown_job_item'>{role}</div>
                                                        ))
                                                        }
                                                </div>
                                        </div>
                                        {/* <div className='nameWrapper'>
                                                <label htmlFor='subject' className='input_label'>Subject</label>
                                                <input  value={subject} onChange={e => setSubject(e.target.value)} type='text' name='subject' className='auth_input' />
                                        </div> */}
                                </main>
                        </section>
                        <section className='create_user_section'>
                                <aside className='staff_creation_aside'>
                                        <h2 className='create_section_title create_section_title_icon'>
                                                <span><AiOutlineCalendar /></span>
                                                <span>Years of Exp</span>
                                        </h2>
                                </aside>
                                <main>
                                        <div className='nameWrapper inputflex'>
                                                <div className='sides'>
                                                        <input value={expCount} onChange={e => setExpCount(e.target.value)} type='number' name='fname' placeholder='expirience' className='auth_input' />
                                                </div>
                                        </div>
                                        
                                </main>
                        </section>
                        <section className='create_user_section'>
                                <aside className='staff_creation_aside'>
                                        <h2 className='create_section_title'>Private Information (optional)</h2>
                                </aside>
                                <main>
                                        <p className='section_description'>The information you provide here will help us know you better. It is highly recommended you fill in this section </p>
                                        <div className='nameWrapper'>
                                                <label htmlFor='address' className='input_label'>Address</label>
                                                <input  value={address} onChange={e => setAddress(e.target.value)} type='text' name='address' className='auth_input' />
                                        </div>
                                        <div className='nameWrapper'>
                                                <label htmlFor='dob' className='input_label'>Date of Birth</label>
                                                <input  value={dob} onChange={e => setDob(e.target.value)} type='date' name='dob' className='auth_input' />
                                        </div>
                                        <div className='nameWrapper'>
                                                <label htmlFor='job' className='input_label'>Gender</label>
                                                <input onFocus={()=> setGenderDropDown(true)} onBlur={removeGenderDropDown} value={gender} onChange={e => setGender(e.target.value)} type='text' name='job' placeholder='e.g: Female' className='auth_input' />
                                                <div className={`dropdown ${genderDropDown ? 'showdropdownJob': ''}`}>
                                                        <div onClick={() => setGender("Male")} className='dropdown_job_item'>Male</div>
                                                        <div onClick={() => setGender("Female")} className='dropdown_job_item'>Female</div>
                                                        <div onClick={() => setGender("Other")} className='dropdown_job_item'>Other</div>
                                                        
                                                </div>
                                        </div>
                                        <div className='nameWrapper'>
                                                <label htmlFor='bank' className='input_label'>Bank Account</label>
                                                <input  value={bank} onChange={e => setBank(e.target.value)} type='text' name='bank' className='auth_input' />
                                        </div>
                                        <div className='nameWrapper'>
                                                <label htmlFor='swift' className='input_label'>Swift code</label>
                                                <input  value={Swift} onChange={e => setSwift(e.target.value)} type='text' name='swift' className='auth_input' />
                                        </div>
                                        <div className='nameWrapper'>
                                                <label htmlFor='bio' className='input_label'>Description</label>
                                                <textarea  value={bio} onChange={e => setBio(e.target.value)} type='text' name='bio' placeholder='short bio about your new staff member' rows={10} className='auth_input' />
                                        </div>
                                        <div className='nameWrapper'>
                                                {
                                                        isloading 
                                                        ?
                                                        <div className='auth_input auth_submit_btn' style={{ cursor: 'progress' }}>loading... </div>
                                                        :
                                                        <input type='submit' value='Create Account' className='auth_input auth_submit_btn' />
                                                }
                                        </div>
                                </main>
                        </section>

                    













                    {/* ======================== replacement here ==================== */}
                </form>
          </div>
          
          {
            showJobModal && <div onClick={() => setShowJobModal(false)} className='shadow' />
          }
        </div>
    </div>
  )
}

export default CreateStaff