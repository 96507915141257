import React from 'react'

import { FcDocument } from 'react-icons/fc'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import RatingStars from '../../Addons/RatingStars'

const CardTemplate = () => {
        const stars = new Array(5).fill(0)
  return (
        <div className='report_card_template'>
                <div className='imageWrapper'>

                </div>
                <div className='description'>
                        <div className='card_design_title bold-text'>
                                <span className='bold-text'>Science project</span>
                                <RatingStars rating={stars} />
                        </div>
                        <div className='description_complex_detail'>
                                <span className='icon'><FcDocument /></span>
                                <span className='text'>Created Aug 14, 2023</span>
                                <div className='dropdown'>
                                        <span className='icon menu_icon'><HiOutlineDotsVertical /></span>
                                </div>
                        </div>
                </div>
      </div>
  )
}

export default CardTemplate