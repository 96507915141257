import React, { useEffect } from 'react'
import { FaUsers } from 'react-icons/fa'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { getTenant } from '../../Resquest'

const AsideGeneral = ({ server }) => {
    const location = useLocation()
    const navigate = useNavigate()

    const filterList = () => {
        const filterBy = location.hash
        console.log('filter by: ', filterBy);
    }

    useEffect(() => {
      fetch_analytics()
    }, [])

        const fetch_analytics = async() => {
                const req = await getTenant(server, '/analytics/students/')
                console.log(req);
        }       
    
    
  return (
    <aside className='search_panel'>
                        <h2 className="panel_title">
                                <span className='icon'><FaUsers /></span>
                                <span className='bold-text'>Gender</span>
                        </h2>
                        <div className='panel_category_list'>
                            <ul onClick={filterList}>
                                <NavLink to='' className={`panel_category_item ${location.hash === ''? 'filterSet': ''}`}>
                                        <span className='text'>All</span>
                                        <span className='count'>5032</span>
                                </NavLink>
                                <NavLink to='#male' className={`panel_category_item ${location.hash === '#male'? 'filterSet': ''}`}>
                                        <span className='text'>Male</span>
                                        <span className='count'>2700</span>
                                </NavLink>
                                <NavLink to='#female' className={`panel_category_item ${location.hash === '#female'? 'filterSet': ''}`}>
                                        <span className='text'>Female</span>
                                        <span className='count'>2500</span>
                                </NavLink>
                            </ul>
                        </div>
                        <h2 className="panel_title">
                                <span className='icon'><FaUsers /></span>
                                <span className='bold-text'>Registration</span>
                        </h2>
                        <div className='panel_category_list'>
                            <ul onClick={filterList}>
                                <NavLink to='#reg_complete' className={`panel_category_item ${location.hash === '#reg_complete'? 'filterSet': ''}`}>
                                        <span className='text'>Completed</span>
                                        <span className='count'>1005</span>
                                </NavLink>
                                <NavLink to='#reg_partial' className={`panel_category_item ${location.hash === '#reg_partial'? 'filterSet': ''}`}>
                                        <span className='text'>Partial</span>
                                        <span className='count'>2033</span>
                                </NavLink>
                                <NavLink to='#reg_not' className={`panel_category_item ${location.hash === '#reg_not'? 'filterSet': ''}`}>
                                        <span className='text'>Not paid</span>
                                        <span className='count'>780</span>
                                </NavLink>
                            </ul>
                        </div>
                        <h2 className="panel_title">
                                <span className='icon'><FaUsers /></span>
                                <span className='bold-text'>Departments</span>
                        </h2>
                        <div className='panel_category_list'>
                                <ul onClick={filterList}>
                                        <NavLink to='' className={`panel_category_item ${location.hash === ''? 'filterSet': ''}`}>
                                                <span className='text'>All</span>
                                                <span className='count'>5032</span>
                                        </NavLink>
                                        <NavLink to='#form_1' className={`panel_category_item ${location.hash === '#form_1'? 'filterSet': ''}`}>
                                                <span className='text'>Form 1</span>
                                                <span className='count'>1</span>
                                        </NavLink>
                                        <NavLink to='#form_2' className={`panel_category_item ${location.hash === '#form_2'? 'filterSet': ''}`}>
                                                <span className='text'>Form 2</span>
                                                <span className='count'>6</span>
                                        </NavLink>
                                        <NavLink to='#form_3' className={`panel_category_item ${location.hash === '#form_3'? 'filterSet': ''}`}>
                                                <span className='text'>Form 3</span>
                                                <span className='count'>3</span>
                                        </NavLink>
                                        <NavLink to='#form_4' className={`panel_category_item ${location.hash === '#form_4'? 'filterSet': ''}`}>
                                                <span className='text'>Form 4</span>
                                                <span className='count'>2</span>
                                        </NavLink>
                                        <NavLink to='#form_5' className={`panel_category_item ${location.hash === '#form_5'? 'filterSet': ''}`}>
                                                <span className='text'>Form 5</span>
                                                <span className='count'>2</span>
                                        </NavLink>
                                        <NavLink to='#lower_sixth' className={`panel_category_item ${location.hash === '#lower_sixth'? 'filterSet': ''}`}>
                                                <span className='text'>Lower sixth</span>
                                                <span className='count'>2</span>
                                        </NavLink>
                                        <NavLink to='#upper_sixth' className={`panel_category_item ${location.hash === '#upper_sixth'? 'filterSet': ''}`}>
                                                <span className='text'>Upper sixth</span>
                                                <span className='count'>2</span>
                                        </NavLink>
                                </ul>
                        </div>
                </aside>
  )
}

export default AsideGeneral