import React, { useEffect, useState } from 'react'
import TreeItem from '../DashBoard/profile/views/TreeItem'
import { useNavigate } from 'react-router-dom'
import { VscChromeClose } from 'react-icons/vsc'

import { ReactComponent as LoaderOval } from '../../assets/LoaderOval.svg'
import { getTenant, postTenant } from '../../Resquest'
import SlideInNotifications from '../../Addons/SlideNotification'

const GradeSubjects = ({ student, subject, showSubjectWrapper, showSubject, handle_remove_modal, server, cls_id  }) => {
    const [isSaving, setIsSaving] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)

    const [selectAll, setSelectAll] = useState(false)
    const [studentSubjects, setStudentSubjects] = useState([])
    const [subjectMarks, setSubjectMarks] = useState([])

    const [selectedTerm, setSelectedTerm] = useState()
    const [selectedTermId, setSelectedTermId] = useState()
    const [terms, setTerms] = useState([])
    const [sequences, setSequences] = useState([])
    
    const [notification_message, setNotification_message] = useState('')
    const [fetchingMarks, setFetchingMarks] = useState(false)
    const selectItem = (id) => {
    }

    const [showNotify, setShowNotify] = useState(false)
    const [notify, setNotify] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        fetch_terms()
    }, [])

    useEffect(() => {
        subject && fetch_subject_marks();
    }, [subject, selectedTermId])

    

    const fetch_subject_marks = async() => {
        setFetchingMarks(true)
        setNotification_message('')
        
        const response = await getTenant(server, `/subject/results/?term=${selectedTermId || 1}&subject=${subject?.id}&class=${cls_id}`)
        response?.status === 200 && setSubjectMarks(response.data?.grades)
        if(response.status === 200){
            setSequences(response.data.sequences)
        }
        else {
            alert('Unable to find subject marks for this Term. Please try again later or contact admin.')
        }
        setFetchingMarks(false)
    }
    
    const fetch_terms = async() => {
        const response = await getTenant(server, '/school-programs/current/terms/')
        if(response?.status === 200) {
            save_terms(response.data)
        }
    }   

    const alert = (message) => {
        setNotification_message(message)
    }

    /**
     * Update term list by finding new term and setting current term to new term
    * @param {string} term A string that is the term you clicked on. used to fetch term in term list
    */
    const save_terms =(terms) => {
        if(terms.active_term){
            setSelectedTermId(terms?.active_term?.id)
            setSelectedTerm('Term ' + terms.active_term.term_name)
        }
        else {
            let current_term = 'Term ' + terms?.terms[0].term_name
            let current_term_id = terms?.terms[0].id
            setSelectedTermId(current_term_id)
            setSelectedTerm(current_term)
        }
        setTerms(terms)
    }
    

    const openStudent = (student) => {
    }

    const saveChanges = () => {
        setIsSaving(true)
        
        submit_grades()
        setIsEdit(false)
    }
    
    const submit_grades = async() => {
        console.log('request object', studentSubjects)
        const response = await postTenant(server, `/grades/subject/${cls_id}/${subject?.id}/`, studentSubjects)

        console.log(response)
        console.log('the status of this response', response.status)
        if(response.status === 201) {
            update_changes(response.data, 'success')
            return 
        }
        else if(response?.status === 206) {
            update_changes(response.data, 'info')
            return 
        }
        else {
            showError()
        }
        setIsSaving(false)
    }
    
    const update_changes = (data, status) => {
        handle_remove_modal()
        setNotify({id: Math.random(), text: 'Changes saved successfully', status: status})
        setShowNotify(true)
        setIsSaving(false)
        setStudentSubjects([])
    }
    const showError = () => {
        setNotify({id: Math.random(), text: 'Unable to save grades', status: 'fail'})
        setShowNotify(true)
    }


    const editState = (state) => {
        setIsEdit(true)
    }

    const close_modal = () => {
        handle_remove_modal()
        setSubjectMarks([])
        setIsEdit(false)
    }
    

    const save_grade = (data) => {
        if (!Array.isArray(studentSubjects)) {
            console.error('studentSubjects is not defined or not an array');
            return;
        }
    
        const subject = studentSubjects.filter(sub => sub.subject === data.subject && sub.sequence === data.sequence);
    
        if (subject.length >= 1) {
            const updatedSubjects = studentSubjects.map(sub => 
                sub.subject === data.subject && sub.sequence === data.sequence ? data : sub
            );
            setStudentSubjects(updatedSubjects);
        } else {
            setStudentSubjects([...studentSubjects, data]);
        }
    }

    const download_result = async() => {
        setIsDownloading(true)
    }

    const update_selected_term = (term) => {
        setSelectedTermId(term.id)
        setSelectedTerm('Term ' + term.term_name)
    }

    
    return (
        <>
        <div className={`system_modal_base ${showSubjectWrapper ? 'show_system_modal_base': ''}`}>
            <div className={`system_modal_wrapper  ${showSubject ? 'show_system_modal_wrapper': ''}`}>
                <div className='system_modal_content'>
                    <header className='modal_title modal_title_with_loader'><span hidden={ !isSaving } className='modal_title_loader'><LoaderOval /></span><span>{ subject?.name } Marks</span></header>
                    <div onClick={ close_modal } className='system_modal_content_btn'><VscChromeClose /></div>
                    
                    <div className='modal_navigate'>
                        <div className='modal_location'>Class/Subject/Grade</div>
                        {
                            terms.terms && (
                            <div className='filters'>
                                <div className='filter_btn'>{ selectedTerm }</div>
                                <div className='modal_select' style={{maxHeight: '400px', overflowY: 'scroll'}}>
                                        {
                                            terms.terms && terms.terms.map(term => (
                                                <li onClick={() => update_selected_term(term)} key={term.id}>Term {term.term_name}</li>
                                            ))
                                        }
                                </div>
                            </div>
                            )
                        }
                    </div>
                    <div className='subject_list'>
                    <table className='tree'>
                        <thead className='tree_table_head'>
                            <tr>
                                <th><input type='checkbox' value={selectAll} onChange={() => setSelectAll(!selectAll)} /></th>
                                <th>Student</th>
                                {
                                    sequences.map(seq => (
                                        <th>{ seq.name }</th>
                                    ))
                                }
                                
                                <th>Avg.</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody className='tree_table_body'>
                                    {
                                        
                                        fetchingMarks
                                        ?
                                        <>
                                            <div className='fetching_grade_loader'>
                                                <LoaderOval />
                                            </div>
                                        </>
                                        :
                                        notification_message.length <= 0 
                                        &&
                                        (
                                            Object.entries(subjectMarks).map(([student, grades]) => (
                                                    <SubjectList grades={grades} subjectMarks={subjectMarks} handle_save_grades={save_grade} key={subject?.id} isEditing={isEdit} handleEditState={ editState } handleOpenStudent={ openStudent } student={student} handleSelect={selectItem} selectAll={selectAll} />
                                            ))
                                        )
                                    }
                                    
                            </tbody>
                    </table> 
                    {
                        notification_message.length > 0
                        &&(
                            <div className='grading_student_message'>
                                <div>{ notification_message }</div>
                            </div>
                        )
                    }
                    </div>
                    
                    <div className='modal_actions_section'>
                        <div className='action_button_list'>
                            <button onClick={() => navigate(`/staffs/${subject?.id}`)} className='modal_btn primary'>Teacher Details</button>
                            {
                                isEdit ? (
                                    <button onClick={ saveChanges } className='modal_btn modal_btn_with_loader'>
                                        <span>Save</span>
                                        { isSaving && <span className='modal_btn_loader'><LoaderOval /></span> }
                                    </button>

                                ) : (
                                    <button onClick={ download_result } className='modal_btn modal_btn_with_loader'>
                                        <span>Download marks</span>
                                        { isDownloading && <span className='modal_btn_loader'><LoaderOval /></span> }
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SlideInNotifications triggered={showNotify} notification={notify} />
        </>
    )
}


export default GradeSubjects;





const SubjectList = ({ subject, student, grades, subjectMarks, selectAll, handleSelect, handleOpenStudent, handle_save_grades, isEditing, handleEditState }) => {
    
    const [seqA, setSeqA] = useState(0)
    const [seqB, setSeqB] = useState(0)
    const [average, setAverage] = useState(0.0)
    const [seqGrades, setSeqGrades] = useState([])

    useEffect(() => {
        const newData = grades.map(item => ({
            id: item?.sequence_id,
            grade: item?.grade?.grade
        }));

        setSeqGrades(newData)
          
        const sum = newData.reduce((acc, item) => acc + item.grade, 0);
        setAverage(Math.round(sum / newData.length).toFixed(1));
    }, [])
    
    
    useEffect(() => {
        const sum = seqGrades.reduce((acc, item) => acc + item.grade, 0);
        setAverage((sum / seqGrades.length).toFixed(1));
    }, [seqGrades])
    

    const editRow = () => {
        handleEditState(true)
    }

    const handleInputChangeSeqA = (e, index) => {
        let value  = e.target.value

        if (/^\d*$/.test(value)) {
            value = parseFloat(value) > 20 ? 20 : value
            let target = seqGrades.filter(grade => grade.id === index)
            target.grade = parseFloat(value) || 0

            setSeqGrades(prevValues => (
                prevValues.map(item => {
                  if (item.id === index) {
                    return { ...item, grade: parseFloat(value) || 0 };
                  }
                  return item;
                })
            ));
        }

    };

    const handleInputChangeSeqB = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setSeqB(parseFloat(value) || 0);
        }
    };

    const update_grade = (grade) => {
        const data = {
            "sequence": grade.id,
            "grade": grade.grade,
            "subject": subjectMarks[student][0].grade.subject.id,
            "student": subjectMarks[student][0].grade.student,
        }
        handle_save_grades(data)
    }


      
    return (
        <tr onDoubleClick={editRow} className='tree_item' >
                <td><input type='checkbox' value={selectAll} onChange={() => handleSelect(subject)} /></td>
                <td>{student}</td>
                {
                    isEditing ? (
                        <>
                        {
                            seqGrades.map(grade => (
                                <td key={grade?.grade?.id} className='editing_input_data'>
                                    <div>
                                        <input 
                                            onBlur={(e) =>update_grade(grade)}  
                                            type='text' 
                                            autoFocus 
                                            onFocus={e => e.target.select()} 
                                            value={grade.grade} 
                                            className='resultEdit' 
                                            onChange={(e) => handleInputChangeSeqA(e, grade?.id)} 
                                        />
                                    </div>
                                </td>
                            ))
                        }
                        </>

                    ) : (
                        <>
                        {
                            seqGrades.map(grade => (
                                <td key={grade?.grade?.id}>{ grade.grade }</td>
                            ))
                        }
                        </>
                    )
                }
                
                <td>{ average }</td>
                
                {/* <td>
                { 
                    studentSubjects[subject].length > 0 &&  studentSubjects[subject][0].sequence?.subject?.sub_coef
                }
                </td> */}
                <td>{ average * subjectMarks[student][0].grade?.subject?.sub_coef }</td>
                
        </tr>
    )
}
