import React, { useEffect, useState } from 'react'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc'
import { getTenant } from '../../../Resquest'


const DashBoardFinance = ({ server }) => {
  const [showoption_dropdown, setShowoption_dropdown] = useState(false)
  const [showoption_dropdowntotal, setShowoption_dropdowntotal] = useState(false)
  const [deepAnalytics, setDeepAnalytics] = useState({})
  const [monthAnalytics, setMonthAnalytics] = useState({})

  useEffect(() => {
    fetch_deep_analytics()
    fetch_month_analytics()
  }, [])
  
  
  const fetch_deep_analytics = async() => {
    const response = await getTenant(server, '/analytics/registrations/deep/')
    response.status === 200 && setDeepAnalytics(response.data);
  }
  
  const fetch_month_analytics = async() => {
    let month = new Date().getMonth()
    let year = new Date().getFullYear()
    const response = await getTenant(server, `/analytics/registrations/?month=${month}&year=${year}`)
    response.status === 200 && setMonthAnalytics(response.data);
  }

  return (
    <section className='finance_transactions'>
        { showoption_dropdown && <div onClick={()=> setShowoption_dropdown(false)} className='shadow' /> }
        { showoption_dropdowntotal && <div onClick={()=> setShowoption_dropdowntotal(false)} className='shadow' /> }
           <div className='finance_analytics'>
                <h2>Estimated Earnings</h2>
                <div className='showoption_dropdown'>
                    <span onClick={() => setShowoption_dropdown(!showoption_dropdown)} className='option_btn'><HiOutlineDotsVertical /></span>
                    {
                      showoption_dropdown && (
                      <div className='dropdown_content'>
                        content
                      </div>
                      )
                    }
                </div>
                <div className='finance_grid'>
                  <div className='finance_grid_item'>
                    <h2>Today so far</h2>
                    <p className='price'>FCFA { deepAnalytics?.today?.sales || 0 }</p>
                  </div>
                  <div className='finance_grid_item'>
                    <h2>Yesterday</h2>
                    <p className='price'>FCFA { deepAnalytics?.yesterday?.sales || 0 }</p>
                    <div className='finance_stats'>
                      <p><span className='up_stat'><VscTriangleUp /></span><span> +{ deepAnalytics?.today?.students || 0 } students</span></p>
                      <p>2 complete</p>
                      
                    </div>
                  </div>
                  <div className='finance_grid_item'>
                    <h2>Last 7 days</h2>
                    <p className='price'>FCFA { deepAnalytics?.last_7_days?.sales || 0 }</p>
                    <div className='finance_stats'>
                      <p><span className='up_stat'><VscTriangleUp /></span><span>+{ deepAnalytics?.last_7_days?.students || 0 } students</span></p>
                      <p>2 complete</p>
                    </div>
                  </div>
                  <div className='finance_grid_item'>
                    <h2>Last 30 days</h2>
                    <p className='price'>FCFA { deepAnalytics?.last_30_days?.sales || 0 }</p>
                    <div className='finance_stats'>
                      <p><span className='up_stat'><VscTriangleUp /></span><span>+{ deepAnalytics?.last_30_days?.students || 0 } students</span></p>
                      <p>2 complete</p>
                    </div>
                  </div>
                </div>
           </div>
           <div className='finance_total'>
                <h2>Total Balance</h2>
                <div className='showoption_dropdown'>
                      <span onClick={() => setShowoption_dropdowntotal(!showoption_dropdowntotal)} className='option_btn'><HiOutlineDotsVertical /></span>
                      {
                        showoption_dropdowntotal && (
                        <div className='dropdown_content'>
                          content
                        </div>
                        )
                      }
                  </div>
                <div className='finance_grid_item'>
                  <h2>This month</h2>
                  <p className='price'>FCFA { monthAnalytics?.total_amount || 0 }</p>
                  <div className='finance_stats'>
                      <p>vs last year total</p>
                      <p><span className='up_stat down_stat'><VscTriangleDown /></span><span>+{ monthAnalytics?.registrations || 0 } students</span></p>
                    </div>
                </div>
           </div>
    </section>
  )
}

export default DashBoardFinance