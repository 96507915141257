import React, { useState } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../lib/UseLocalStorage';

export default function FlutterWaveButton({amount, transaction_id}) {
  const [tenantUser, setTenantUser] = useLocalStorage('tenantUser',)

  const config = {
    public_key: "FLWPUBK_TEST-6d7a22f4d474189c1b13eb1a19edc104-X",
    tx_ref: transaction_id,
    amount: amount,
    currency: 'XAF',
    payment_options: 'card,mobilemoney,usd',
    redirect_url: '/fee-payment/status',
    customer: {
      email: tenantUser?.user?.email || 'darlingtonkimbi@gmail.com',
       phone_number: '**********',
      name: tenantUser?.user?.first_name + " " + tenantUser?.user?.last_name,
    },
    customizations: {
      title: 'Fee Payment',
      description: 'Payment payment',
      logo: '',
    },
  };

  const handleFlutterPayment = useFlutterwave(config)
 
  const { t } = useTranslation()
  const [processingPay, setProcessingPay] = useState(false)
  return (
    <div className="App">
      {
        processingPay
        ?
        <button className='processing_payment'>Processing payment...</button>
        :
        <button
          onClick={() => {
            setProcessingPay(true)
            handleFlutterPayment({
              callback: (response) => {
                  closePaymentModal() 
              },
              onClose: () => {},

            });
          }}
        >
          Pay fees
        </button>
      }
    </div>
  );
}