import React from 'react'
import './Plans.css'
import {  FiCheckCircle, FiXCircle } from 'react-icons/fi'
import {  LuCrown } from 'react-icons/lu'
import happygirl from '../../assets/img/banner_happy_girl.png'
import Footer from '../../Footer/Footer'
import FlutterWaveSubscriptionButton from '../../Addons/FlutterWaveSubsriptionButton'
import useLocalStorage from '../../lib/UseLocalStorage'

const PlanPage = () => {
  const [currentSchool, setCurrentSchool ] = useLocalStorage('currentSchool')
  
  return (
  <>
    <div className='plan_page'>
        <div className='container'>
                <div className='plan_grid_wrapper'>
                        <h2 className='title center-text'>Choose your pricing  Plan</h2>
                        <div className='plan_grid'>
                                <div className='plan_type'>
                                        <h2 className='bold-text'>Free</h2>
                                        <div className='plan_price center-text title'>60,000.0 FCFA</div>
                                        <p className='plan-renewal center-text'>Life time access</p>
                                        <div className='plan_options'>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>24/7 access</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Grading</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>24/7 access</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Grading</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon uncheck'><FiXCircle /></span>
                                                        <span className='text'>Dedicated server</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Report Card generation</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon uncheck'><FiXCircle /></span>
                                                        <span className='text'>Report Card generation</span>
                                                </div>
                                                <div className='plan_action'>
                                                        {/* <button className='plan_action_btn'>Buy Now</button> */}
                                                        <FlutterWaveSubscriptionButton amount={60000} school_code={currentSchool?.school_code} />
                                                </div>
                                        </div>
                                </div>
                                <div className='plan_type'>
                                        <h2 className='bold-text'>Standard (Recommended)</h2>
                                        <div className='plan_price center-text title'>100,000.0 FCFA</div>
                                        <p className='plan-renewal center-text'>Every month</p>
                                        <div className='plan_options'>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>24/7 access</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Grading</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>24/7 access</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Grading</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon uncheck'><FiXCircle /></span>
                                                        <span className='text'>Dedicated server</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Report Card generation</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon uncheck'><FiXCircle /></span>
                                                        <span className='text'>Report Card generation</span>
                                                </div>
                                                <div className='plan_action'>
                                                        {/* <button className='plan_action_btn action_btn_standard'>Buy Now</button> */}
                                                        <FlutterWaveSubscriptionButton amount={100000} school_code={currentSchool?.school_code} />
                                                </div>
                                        </div>
                                </div>
                                <div className='plan_type'>
                                        <h2 className='bold-text'>Premium</h2>
                                        <div className='plan_price center-text title'>140,000.0 FCFA</div>
                                        <p className='plan-renewal center-text'>Every month</p>
                                        <div className='plan_options'>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>24/7 access</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Grading</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>24/7 access</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Grading</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon uncheck'><FiCheckCircle /></span>
                                                        <span className='text'>Dedicated server</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon'><FiCheckCircle /></span>
                                                        <span className='text'>Report Card generation</span>
                                                </div>
                                                <div className='plan_option'>
                                                        <span className='icon uncheck'><FiXCircle /></span>
                                                        <span className='text'>Report Card generation</span>
                                                </div>
                                                <div className='plan_action'>
                                                        {/* <button className='plan_action_btn action_btn_premium'>Buy Now</button> */}
                                                        <FlutterWaveSubscriptionButton amount={140000} school_code={currentSchool?.school_code} />
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
                <div className='banner '>
                        <div className='banner_text'>
                                <h2 className='title'>You are currently on Standard Plan</h2>
                                <p className=''>Upgrade to premium and get 10% off</p>
                                <button className='upgrade_banner_btn'><span>Upgrade</span> <span className='icon'><LuCrown /></span></button>
                        </div>
                        <div className='banner_image'>
                                <img src = {happygirl} alt='girl pointing to upgrade button' />
                        </div>
                </div>
        </div>
    </div>
    <Footer />
  </>
  )
}

export default PlanPage