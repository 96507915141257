
import React, { useEffect, useState } from 'react'


import {motion} from 'framer-motion';
import { GoArrowRight } from "react-icons/go";
import { IoSchoolOutline } from "react-icons/io5";
import { MdOutlineAttachEmail } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi2";


import { ReactComponent as Loader } from '../../../assets/loader.svg'
import useLocalStorage from '../../../lib/UseLocalStorage';


const pageTransition = {
  hidden: {
      y: "3vh",
      opacity: 0,
  },
  visible: {
      y: "0",
      opacity: 1
  },
  exit: {
      y: "-3vh",
      opacity: 0,

  }
}
export const AnimateOnboard = ({ children }) => {
  return (
        <motion.div className='onboard_slide_form' variants={pageTransition} initial="hidden" animate="visible" exit="exit" transition={{duration: 0.6}}>
          {children}
        </motion.div>

  )
}


/**
 * Buttons used to save data and change route
 * @param {PropList}  
 * @returns 
 */
export const Actions = ({save_data, handle_go_back, isloading, index, finish = false}) => {
  return (
    
    <div className='next_btn_wrapper'>
      <button className={`onboard_next_btn school_onboard_back_btn`} onClick={handle_go_back} >
        <span>Back</span>
      </button>

      {
        finish ? (
          <button disabled={isloading} className={`onboard_next_btn ${isloading ? "onboard_load_btn" : ""}`} onClick={save_data}>
            <span>Finish</span>
            <span className='btn_svg'>
              { isloading ? <Loader /> : <GoArrowRight /> }
            </span> 
          </button>
        ) : (
        <button disabled={isloading} className={`onboard_next_btn ${isloading ? "onboard_load_btn" : ""}`} onClick={save_data}>
          <span>Next</span>
          <span className='btn_svg'>
            { isloading ? <Loader /> : <GoArrowRight /> }
          </span> 
        </button>
        )
      }
    </div>
  )
}


// START
export const SlideInfomationSchool = ({user, handle_save_data, index }) => {
  const [schoolName, setSchoolName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [isloading, setIsloading] = useState(false)
  
  const data = {
    "name": schoolName,
    "email": email,
    "phone": phone
  }
  const save_data = () => {
    setIsloading(true)
    handle_save_data(index, data)
  }

  useEffect(() => {
    let skl = user?.schools && user?.schools[ user?.schools.length - 1]
    let json_data = JSON.parse(skl.replace(/'/g, '"'));
    setSchoolName(json_data?.school)
  }, [])
  


return (
  <>
    <AnimateOnboard>
        <section className='school_onboard-1'>
          <div className='onboard_form_control'>
              <label htmlFor="name">Name of school</label>
              <div className='onboard_input_wrapper'>
                <input type="text" value={schoolName} />
                <span className='form_control_icon'><IoSchoolOutline /></span>
              </div>
          </div>
          <div className='onboard_form_control'>
              <label htmlFor="name">Email of school</label>
              <div className='onboard_input_wrapper'>
                <input type="email" required value={email} onChange={e=> setEmail(e.target.value)} />
                <span className='form_control_icon'><MdOutlineAttachEmail /></span>
              </div>
          </div>
          <div className='onboard_form_control'>
              <label htmlFor="name">Contact number</label>
              <div className='onboard_input_wrapper'>
                <input type="text" value={phone} onChange={e=> setPhone(e.target.value)} />
                <span className='form_control_icon'><HiOutlinePhone /></span>
              </div>
          </div>
        </section>
    </AnimateOnboard>
    <Actions save_data={save_data} isloading={isloading} />
  </>
)
}





