import { AnimateOnboard, Actions } from "../../SlideInfomation"
import React, { useState } from 'react'

import { IoSchoolOutline } from "react-icons/io5";

import { ReactComponent as Loader } from '../../../../../assets/loader.svg'
import axios from "axios";
import useLocalStorage from "../../../../../lib/UseLocalStorage";



const SlideInfomationPictures = ({ user, handle_save_data, index, handle_go_back }) => {
    const [logo, setLogo] = useState(null)
    const [logoFile, setLogoFile] = useState(null)
    const [school_photo, setSchool_photo] = useState(null)
    const [isloading, setIsloading] = useState(false)
    const [image, setImage] = useState(null)
    const [importing, setImporting] = useState(false)
    const [currentSchool, setCurrentSchool ] = useLocalStorage('currentSchool', null)
    
  
    const save_data = () => {
      setIsloading(true)
      update_school_profile()
    }

    const update_school_profile = async() => {
      try {
        const formData = new FormData()
        formData.append('logo', logo, logo?.name)
  
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": `Bearer ${user?.access}`
        }
        }
        const response = await axios.put(`https://${user?.server}.rankafrica.site/api/v1/school-files/${currentSchool?.id}/`, formData, config)
        response?.status === 200 && handle_save_data(index,{} )

      } catch(err){
        console.warn(err);
      }
      setIsloading(false)
    }

    const go_back = () => {
      handle_go_back(index);
    }
  
    const handleImportImage = (event) => {
      setImporting(true)

      const file = event.target.files[0];
      setLogo(event.target.files[0]);
      
      if (file && file.type.startsWith('image/')) {
        setIsloading(true);
        if (file.name.includes(".png") || file.name.includes(".jpeg") || file.name.includes(".jpg")) {
          setTimeout(() => { 
            setLogoFile(URL.createObjectURL(event.target.files[0])) 
            setIsloading(false)
          }, 3000);
        }
      }
      
  
    }
  
  return (
    <>
      <AnimateOnboard>
          <section className='school_onboard-1'>
            <div className='onboard_form_control'>
                <label className='bold-text' htmlFor="name">School logo</label>
                <p>Image should be 512 x 512 in dimension</p>
                <div className='onboard_profile_wrapper'>
                  <input type="file" accept='image/jpeg, image/png, image/gif' onChange={e => handleImportImage(e)} />
                  <span className='form_control_icon'><IoSchoolOutline /></span>
                  {
                    importing && (
                      <div className="importedLogo_wrapper">
                        {
                          logoFile ? <img src={logoFile} alt='school logo' /> : <Loader />
                        }
                      </div>
                    )
                  }
                </div>
            </div>
            <br />
            {/* <div className='onboard_form_control'>
                <label className='bold-text' htmlFor="name">Images of institution</label>
                <p>Images should be at least 850 x 1024 in height</p>
                <div className='onboard_profile_wrapper multiple_photos'>
                  <input type="file" value={school_photo} onChange={e=> setSchool_photo(e.target.value)} />
                  <span className='form_control_icon'><IoSchoolOutline /></span>
                </div>
                <p style={{textAlign: 'right', fontSize: '0.85em', marginTop: '1rem'}}>totlal: 0/5</p>
            </div> */}
          </section>
      </AnimateOnboard>
      <Actions save_data={save_data} handle_go_back={go_back} index={index} isloading={isloading} />
    </>
  )
  }

  export default SlideInfomationPictures;