import React, { useEffect, useState } from 'react'
import { PiFilesThin, PiStarLight, PiTrashThin, PiUserListThin } from 'react-icons/pi'
import PopupAddToAlert from '../../Addons/PopupAddToAlert'
import './Student.css'
import Persona from '../../assets/img/persona.jpeg'

import { ReactComponent as LoaderOval } from '../../assets/LoaderOval.svg'
import { getTenant, postTenant } from '../../Resquest'
import { useParams } from 'react-router-dom'
import SlideInNotifications from '../../Addons/SlideNotification'
import { VscClose } from 'react-icons/vsc'
import FlutterWaveButton from '../../Addons/FlutterWaveButton'

const StudentDetails = ({ server }) => {
    const [classDispAttr, setclassDispAttr] = useState('students')
    const [student, setStudent] = useState({})
    const [error, setError] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const { id } = useParams()
    const [showInstallments, setShowInstallments] = useState(false)
    const [msg, setMsg] = useState('')
    const [registrationInfo, setRegistrationInfo] = useState({})
    const [classFees, setClassFees] = useState({})
    const [amount, setAmount] = useState(0)
    const [creatingTransaction, setCreatingTransaction] = useState(true)
    const [warnSuccess, setWarnSuccess] = useState(false)
    const [payTransaction, setPayTransaction] = useState(null)

    useEffect(() => {
        setIsloading(true)
        fetch_student_data()
        setIsloading(false)
    }, [])

    
    useEffect(() => {
        setCreatingTransaction(true)
    }, [amount])
    
    
    // useEffect(() => {
    //     fetch_registration_information()
    // }, [])
    // const fetch_registration_information = async() => {
    //     const response = await getTenant(server, `/register/information/${id}/`)
    //     response.status === 200 && setRegistrationInfo(response.data)
    //     console.log(response)
    // }

    const fetch_student_data = async() => {
        const response = await getTenant(server, `/students/${id}/`);
        response?.status === 200 ? setStudent(response?.data[0]) : setError(true)

        console.log(response?.data)
    }

    const download_student_profile = () => {

    }

    const student_registrations = async() => {
        let data = {
            student: student?.id
        }
        console.log(data)
        const response = await postTenant(server, `/register/`, data)
        console.log(response)
        if(response?.status === 202) {
            setClassFees(response?.data?.class_fee)
            setRegistrationInfo(response?.data?.registration)
        }
        if(response?.response){
            response?.response.status === 406 && warn('Fees for this class has not yet been set. Contact Admin')
        }
        else if(response?.status === 400) {
            warn('An error occured while trying to register student, try again later')
        }


        setShowInstallments(true)
    }

    const warn = (alert) => {
        setMsg(alert)
    }
    
    const handle_remove_modal = () => {
        setShowInstallments(false)
    }

    const selectInstallment = (value) => {
        setAmount(value)
    }
    const create_transaction = async() => {
        warn('')

        let data = {
            registration: registrationInfo?.id,
            amount: amount,
            payment_date: "2024-11-11"
        }
        
        const response = await postTenant(server, `/fee-payments/`, data)

        if(response?.status === 201){
            setPayTransaction(response?.data)
            setCreatingTransaction(false)
            warn('Transaction created successfully')
            setWarnSuccess(true)
            setTimeout(() => {
                warn('')
                setWarnSuccess(false)
            }, 3000);
        }
        else {
            warn('An error occured while trying to create transaction, try again later')
        }
    }
  return (
    <div className='class_details_page'>
        <div className='container'>
            <div className='content'>
                <header className='details_header'>
                    <a href={`https://${server}.rankafrica.site/api/v1/student_result/1/1/${student?.id}/download`} target='_blank' className='head'>
                        <span className='icon'><PiFilesThin /></span>
                        <div className='context'>
                            <p>transcript</p>
                            <p>N/A</p>
                        </div>
                    </a>
                    <div className='head'>
                        <span className='icon'><PiTrashThin /></span>
                        <div className='context'>
                            <p>expell</p>
                            <p>student</p>
                        </div>
                    </div>
                    <a href={`https://${server}.rankafrica.site/api/v1/student_profile/${student?.id}/download/`} target='_blank' onClick={ download_student_profile } className='head'>
                        <span className='icon'><PiUserListThin /></span>
                        <div className='context'>
                            <p>Stud. Profile</p>
                            <p>Download</p>
                        </div>
                    </a>
                    <div className='head'>
                        <span className='icon'><PiStarLight /></span>
                        <div className='context'>
                            <p>78% grade</p>
                            <p>12% remaining</p>
                        </div>
                    </div>
                </header>
                {
                    error
                    ?
                    <h2>Error fetching data</h2>
                    :
                    isloading
                    ?
                    <LoaderOval />
                    :
                    <>
                    <div className='user_information_section'>
                        <div className='user_persona_info'>
                            <div className='name_section'>
                                <div>
                                    <h1>{ student?.user?.first_name } { student?.user?.last_name }</h1>
                                    <p>{ student?.user?.email }</p>
                                </div>
                                <p>Admitted On: { student?.admission_date && new Date(student?.admission_date).toDateString() }</p>
                            </div>
                            <div className='profile'>
                                <img src={Persona} alt='kimbi dalrington pic' />
                            </div>
                        </div>

                        <div className='user_attr_content_details'>
                            <div className='col_one'>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Department</h4>
                                    <h4 className='col_value'>{ student?.student_class?.level?.departments?.name }-{ student?.student_class?.level?.departments?.language_supports && student?.student_class?.level?.departments?.language_supports[0] }</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Class</h4>
                                    <h4 className='col_value'>{ student?.student_class?.level?.name } { student?.student_class?.class_name }</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Registration</h4>
                                    <h4 className='col_value'><span className="staff_invitation_status accept">Completed</span></h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>New Student</h4>
                                    <h4 className='col_value'>{ student?.is_new_student ? 'True': 'None' }</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Has Repeated</h4>
                                    <h4 className='col_value'>{ student?.is_repeater ? 'True' : 'None' }</h4>
                                </div>
                            </div>

                            <div className='col_one'>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Class Average</h4>
                                    <h4 className='col_value'>12.40 / 20.00</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Rank</h4>
                                    <h4 className='col_value'>undefined</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Gender</h4>
                                    <h4 className='col_value'>{ student?.user?.gender }</h4>
                                </div>
                                <div className='col_entry'>
                                    <h4 className='col_key'>Date of Birth</h4>
                                    <h4 className='col_value'>{ student?.user?.date_of_birth && new Date(student?.user?.date_of_birth).toDateString() || new Date().toDateString()}</h4>
                                </div>
                                <button onClick={ student_registrations } className='modal_btn modal_btn_with_loader'>
                                    <span>Register Student</span>
                                </button>
                            </div>
                            {
                                showInstallments
                                && (
                                    <div className='show_fee_installments'>
                                        <div onClick={handle_remove_modal } className='close_school_login_modal'><VscClose /></div>
                                        <div className='show_fee_installments_content'>
                                            <h3>Select Installment from below </h3>
                                            { msg.length > 1 && 
                                            <div className='nameWrapper'>
                                                <input value= {msg} disabled className={`auth_input input_alert ${warnSuccess? 'success': ''}`} />
                                            </div>
                                            }
                                            <div className='show_fee_intallment_select'>
                                                {/* <select type='text'  className='col_input' placeholder='Select Amount'>
                                                    <option>Select Installment</option>

                                                </select> */}
                                                <FeeInstallments handleSelectInstallment={selectInstallment} feeData={classFees} />
                                                {
                                                    creatingTransaction 
                                                    ?
                                                    <button onClick={create_transaction} className=''>Create transaction</button>
                                                    :
                                                    <FlutterWaveButton amount={amount} transaction_id={payTransaction?.transaction_id} />

                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    </>
                }


                <main className='class_attr_list'>
                    <div className='headers_attr'>
                        <div onClick={()=> setclassDispAttr('guardian')} className={`class_attr_category ${classDispAttr === 'guardian' ? 'active': ''}`}>Guardian</div>
                        <div onClick={()=> setclassDispAttr('students')} className={`class_attr_category ${classDispAttr === 'students' ? 'active': ''}`}>Student info</div>
                    </div>
                    <section className='attr_content'>
                        {
                            classDispAttr === 'guardian'
                            ?
                            <StudentGuardianInfo student={student} />
                            :
                            classDispAttr === 'students'
                            ?
                            <StudentInfo student={student} server={server} />
                            :
                            <div>404 not found</div>
                        }
                    </section>
                </main>
            </div>
        </div>
    </div>
  )
}

const FeeInstallments = ({ feeData, handleSelectInstallment }) => {
    const installments = [
      { label: 'First Installment', amount: feeData.first_installment },
      { label: 'Second Installment', amount: feeData.second_installment },
      { label: 'Third Installment', amount: feeData.third_installment }
    ];
  
    return (
      <select className='col_input' onChange={(e) => handleSelectInstallment(e.target.value)}>
          <option  value={0} >Select Installment </option>
        {installments.map((installment, index) => (
          <option key={index} value={installment.amount}>
            {installment.label}: {installment.amount}
          </option>
        ))}
      </select>
    );
  };

export default StudentDetails


const StudentInfo = ({ student, server }) => {
    const [isEdit, setIsEdit] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [showNotify, setShowNotify] = useState(false)
    const [notification, setNotification] = useState({})
    const [departments, setDepartments] = useState([])
    const [classes, setClasses] = useState([])
    const [selectedClass, setSelectedClass] = useState('2')

    const saveChanges = () => {
        setIsSaving(true)
        console.log(selectedClass, student.student_class.id);
        if(parseInt(selectedClass) !== student.student_class.id){
            promote_student()
        }
        setIsSaving(false)

        setTimeout(() => {
            setShowNotify(true)
            setNotification({ text: "Your changes have been saved!!", id: Math.random(), status: 'ok' })
        }, 3000);
    }

    const promote_student = async() => {
        const response = await postTenant(server, `/promote_student/${student.id}/${selectedClass}/`)
        if(response?.status === 200){
            setShowNotify(true)
            setNotification({ text: "Student has been promoted Succesfully", id: Math.random(), status: 'success' })
        }
        setIsEdit(false)
    }

    useEffect(() => {
      fetch_departments()
      fetch_classes()
    }, [])

    const fetch_departments = async() => {
        const response = await getTenant(server, '/departments/')
        setDepartments(response?.data)
    }

    const fetch_classes = async() => {
        const response = await getTenant(server, '/classes/')
        setClasses(response?.data)
    }
    
    return (
        <section className='tile_section'>
            <div className='user_attr_content_details'>
                {
                    isEdit
                    ?
                    <>
                    <div className='col_one'>
                        <h2>Student Level</h2>
                        <div className='col_entry'>
                            <h4 className='col_key'>Department</h4>
                            <h4 className='col_value'>
                                <select type='text'  className='col_input' placeholder='select department'>
                                    {
                                        departments.length > 0 && departments.map(department => (
                                            <option key={department.id} value={department?.id} >{ department?.name } - { department.language_supports[0]}</option>
                                        ))
                                    }
                                    <option>Add new Department</option>
                                </select>
                            </h4>
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Class</h4>
                            <h4 className='col_value'>
                                <select type='text' value={selectedClass} onChange={e => setSelectedClass(e.target.value)} className='col_input' placeholder='select department'>
                                    {
                                        classes.length > 0 && classes.map(cls => (
                                            <option key={cls.id} value={cls.id} >{ cls?.level?.name } { cls?.class_name }</option>
                                        ))
                                    }
                                </select>
                            </h4>
                        </div>

                    </div>

                    <div className='col_one'>
                        <h2>Registration</h2>
                        <div className='col_entry'>
                            <h4 className='col_key'>Registration</h4>
                            <input type='text' className='col_input' placeholder='Change Registration Status' />
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Expell</h4>
                            <input type='text' className='col_input' placeholder='Expel Student' />
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Repeat Student</h4>
                            <h4 className='col_value'>
                                <input type='text' className='col_input' placeholder='Repeater?...' />
                            </h4>
                        </div>
                        
                    </div>
                    </>
                    :
                    <>
                    <div className='col_one'>
                        <h2>Student Level</h2>
                        <div className='col_entry'>
                            <h4 className='col_key'>Department</h4>
                            <h4 className='col_value'>{ student?.student_class?.level?.departments?.name }-{ student?.student_class?.level?.departments?.language_supports && student?.student_class?.level?.departments?.language_supports[0] }</h4>
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Class</h4>
                            <h4 className='col_value'>{ student?.student_class?.level?.name } { student?.student_class?.class_name }</h4>
                        </div>

                    </div>

                    <div className='col_one'>
                        <h2>Registration</h2>
                        <div className='col_entry'>
                            <h4 className='col_key'>Registration</h4>
                            <h4 className='col_value'>Completed</h4>
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Expell</h4>
                            <h4 className='col_value'>None</h4>
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Repeat Student</h4>
                            <h4 className='col_value'>None</h4>
                        </div>
                        
                    </div>
                    </>
                }
                
            </div>
            {
                isEdit ? (
                    <button onClick={ saveChanges } className='modal_btn modal_btn_with_loader'>
                        <span> {isSaving ? 'Saving' : 'Save changes' }</span>
                        { isSaving && <span className='modal_btn_loader'><LoaderOval /></span> }
                    </button>

                ) : (
                    <button onClick={ () => setIsEdit(true) } className='modal_btn modal_btn_with_loader'>
                        <span>Edit Information</span>
                    </button>
                )
            }
            <SlideInNotifications triggered={showNotify} notification={notification} />
        </section>
    )
}


const StudentGuardianInfo = () => {
    return (
        <section className='tile_section'>
            <div className='user_attr_content_details'>
                
                    <div className='col_one'>
                        <h2>Father</h2>
                        <div className='col_entry'>
                            <h4 className='col_key'>Full Name</h4>
                            <h4 className='col_value'>Not provided</h4>
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Occupation</h4>
                            <h4 className='col_value'>Unknown</h4>
                        </div>

                    </div>

                    <div className='col_one'>
                        <h2>Mother</h2>
                        <div className='col_entry'>
                            <h4 className='col_key'>Full Name</h4>
                            <h4 className='col_value'>Not provided</h4>
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Occupation</h4>
                            <h4 className='col_value'>Unknown</h4>
                        </div>
                        <div className='col_entry'>
                            <h4 className='col_key'>Contact</h4>
                            <h4 className='col_value'>Unknown</h4>
                        </div>
                        
                    </div>
            </div>
        </section>
    )
}