import React, { useEffect, useState } from 'react'
import TreeItem from '../DashBoard/profile/views/TreeItem'
import { useNavigate } from 'react-router-dom'
import './ClassDetails.css'
import { VscChromeClose } from 'react-icons/vsc'
// import Johndoe from '../johndoe.jpg'

import { ReactComponent as LoaderOval } from '../../assets/LoaderOval.svg'
import { getTenant, postTenant } from '../../Resquest'
import SlideInNotifications from '../../Addons/SlideNotification'


const ClassStudents = ({ list, cls_id, server }) => {
        const [selectAll, setSelectAll] = useState(false)
        const [showStudent, setShowStudent] = useState(false)
        const [showStudentWrapper, setShowStudentWrapper] = useState(false)
        const [selectedStudent, setSelectedStudent] = useState(null)
        
        const selectItem = (id) => {
        }
        const openStudent = (student) => {
            setShowStudent(true)
            setShowStudentWrapper(true)
            setSelectedStudent(student)
        }

        const removeModal = () => {
            setShowStudent(false)
            setTimeout(() => {
                setShowStudentWrapper(false)
            }, 150);
        }
        
        

        
  return (
    <div className='person tree_view class_student'>
        <div className='scroll_tree_wrapper'>
                <div className='scroll_container'>
                    <table className='tree'>
                        <thead className='tree_table_head'>
                                    <tr>
                                            <th><input type='checkbox' value={selectAll} onChange={() => setSelectAll(!selectAll)} /></th>
                                            <th>Name</th>
                                            <th>Gender</th>
                                            <th>Grade</th>
                                            <th>Rank</th>
                                            <th>Grade</th>
                                            <th>admission date</th>
                                    </tr>
                            </thead>
                            <tbody className='tree_table_body'>
                                    {
                                            list.map(student => (
                                                    <Student handleOpenStudent={ openStudent } student={ student } handleSelect={selectItem} selectAll={selectAll} key={student?.id} />
                                            ))
                                    }
                                    
                            </tbody>
                    </table> 
                </div>
                
                
                <StudentSubjects server={server} student={selectedStudent} cls_id={cls_id} showStudentWrapper={showStudentWrapper} showStudent={showStudent} handle_remove_modal={removeModal}  />
        </div>
    </div>
  )
}

export default ClassStudents





const Student = ({ student, selectAll, handleSelect, handleOpenStudent }) => {
        const [admissionDate, setAdmissionDate] = useState('')
        useEffect(() => {
            setAdmissionDate(new Date(student?.admission_date).toDateString())
        }, [])
        
        const navigate = useNavigate()
        return (
        <tr className='tree_item' onClick={ () => handleOpenStudent(student) }>
                <td><input type='checkbox' value={selectAll} onChange={() => handleSelect(student)} /></td>
                <td>{ student?.user?.first_name } { student?.user?.last_name }</td>
                <td>{ student?.user?.gender }</td>
                <td>{ student?.user?.grade || 0 }</td>
                <td>{ student?.rank || 0 }</td>
                <td>Complete</td>
                <td>{ admissionDate }</td>
        </tr>
  )
}



const StudentSubjects = ({ student, showStudentWrapper, showStudent, handle_remove_modal, server, cls_id  }) => {
    const [isSaving, setIsSaving] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [studentSubjects, setStudentSubjects] = useState([])
    const [showNotify, setShowNotify] = useState(false)
    const [notify, setNotify] = useState({})
    const [studentGrades, setStudentGrades] = useState([])
    const [term, setTerm] = useState({})
    const [selectedTerm, setSelectedTerm] = useState()
    const [selectedTermId, setSelectedTermId] = useState()
    const [terms, setTerms] = useState([])
    const [sequences, setSequences] = useState([])
    const [notification_message, setNotification_message] = useState('')
    const [fetchingMarks, setFetchingMarks] = useState(false)
    const selectItem = (id) => {
    }

    const navigate = useNavigate()

    useEffect(() => {
        fetch_terms()
    }, [])

    useEffect(() => {
        student && fetch_student_subjects();
    }, [student, selectedTermId])

    

    const fetch_student_subjects = async() => {
        setFetchingMarks(true)
        setNotification_message('')
        const response = await getTenant(server, `/results/?term=${selectedTermId || 1}&student=${student?.id}&class=${cls_id}`)
        console.log('results for term' + selectedTerm, response)
        response?.status === 200 && setStudentSubjects(response.data?.grades)
        if(response.status === 200){
            setSequences(response.data.sequences)
            setFetchingMarks(false)
            return
        }
        if(response?.statusText === 'NET::ERR_CONNECTION_REFUSED'){
            alert('Unable to connect to server. Please try again later or contact admin.')
        }
        else {
            alert('Unable to find student marks for this Term. Please try again later or contact admin.')
        }
        setFetchingMarks(false)
    }
    
    const fetch_terms = async() => {
        const response = await getTenant(server, '/school-programs/current/terms/')
        if(response?.status === 200) {
            save_terms(response.data)
        }
        if(response?.status === 412){
            alert('No active term found. Contact Admins to help set up school program.')
            window.location.href = '/dashboard'
        }
    }   

    const alert = (message) => {
        setNotification_message(message)
    }

    /**
     * Update term list by finding new term and setting current term to new term
    * @param {string} term A string that is the term you clicked on. used to fetch term in term list
    */
    const save_terms =(terms) => {
        if(terms.active_term){
            setSelectedTermId(terms?.active_term?.id)
            setSelectedTerm('Term ' + terms.active_term.term_name)
        }
        else {
            let current_term = 'Term ' + terms?.terms[0].term_name
            let current_term_id = terms?.terms[0].id
            setSelectedTermId(current_term_id)
            setSelectedTerm(current_term)
        }
        setTerms(terms)
    }
    

    const openStudent = (student) => {
    }

    const saveChanges = () => {
        setIsSaving(true)
        const subjectAverages = {};
        const subjectCounts = {};

        const sequenceIds = Object.values(studentSubjects).flatMap(seq => seq.map(
            ({ sequence }) => {
                let is_added = studentGrades.find(grade => grade.sequence === sequence.id && grade.subject === sequence.subject.id);
                if(!is_added) {
                    return {
                        sequence: sequence.id,
                        grade: sequence.grade,
                        subject: sequence.subject.id,
                        student: student.id
                    }
                }
            }
        )).filter(item => item !== undefined);

        setStudentGrades(prevStudentGrades => [...prevStudentGrades, ...sequenceIds]);
        let grade_list = [...studentGrades, ...sequenceIds]

        grade_list.forEach(grade => {
            const { grade: g, subject } = grade;
            if (!subjectAverages[subject]) {
                subjectAverages[subject] = g;
                subjectCounts[subject] = 1;
            } else {
                subjectAverages[subject] += g;
                subjectCounts[subject]++;
            }
        });
        for (let subject in subjectAverages) {
            subjectAverages[subject] = Math.round((subjectAverages[subject] / subjectCounts[subject]) * 10) / 10;
        }
        studentGrades.forEach(grade => {
            const { subject } = grade;
            grade.seq_average = subjectAverages[subject];
        });

        
        submit_grades(grade_list)
        setIsEdit(false)
    }
    
    const submit_grades = async(grades) => {
        const response = await postTenant(server, `/grades/student/${cls_id}/${student?.id}/?term=${selectedTermId}`, grades)
        if(response?.status === 201) {
            update_changes(response.data, 'success')
        }
        if(response?.status === 206) {
            update_changes(response.data, 'success')
        }
        else {
            showError()
        }
        setIsSaving(false)
    }
    
    const update_changes = (data, status) => {
        handle_remove_modal()
        setNotify({id: Math.random(), text: 'Changes saved successfully', status: status})
        setShowNotify(true)
    }
    const showError = () => {
        setNotify({id: Math.random(), text: 'Unable to save grades', status: 'fail'})
        setShowNotify(true)
    }


    const editState = (state) => {
        setIsEdit(true)
    }

    const close_modal = () => {
        handle_remove_modal()
        setIsEdit(false)
    }
    

    const save_grade = (data) => {
        // Purpose of this function is to save grades to the studentGrades state
        // If the grade already exists, update the grade with the new grade 
        // If the grade does not exist, add the grade to the studentGrades state
        
        const subject = studentGrades.filter(sub => sub.subject === data.subject && sub.sequence === data.sequence)
        if(subject.length >= 1) {
            setStudentGrades(studentGrades.map(sub => sub.subject === data.subject && sub.sequence === data.sequence ? data : sub))
        }
        else {
            setStudentGrades([...studentGrades, data])
        }
    }

    const download_result = async() => {
        setIsDownloading(true)
        
        if(!selectedTermId){
            setNotify({id: Math.random(), text: 'No active term found', status: 'fail'})
            setShowNotify(true)
            setTimeout(() => {
                setNotify({id: Math.random(), text: 'Select a term to download result', status: 'fail'})
            }, 700);
            setIsDownloading(false)
            return
        }
        
        const response = await getTenant(server, `/student_result/${selectedTermId}/${student?.id}/download/`)
        console.log(response);
        if(response.status === 200){

        }
        else{
            setNotify({id: Math.random(), text: 'Unable to download student results', status: 'fail'})
            setShowNotify(true)
            setTimeout(() => {
                setNotify({id: Math.random(), text: 'Please try again later or contact admin', status: 'fail'})
            }, 700);
        }
        setIsDownloading(false)
    }

    const update_selected_term = (term) => {
        setSelectedTermId(term.id)
        setSelectedTerm('Term ' + term.term_name)
    }

    
    return (
        <>
        <div className={`system_modal_base ${showStudentWrapper ? 'show_system_modal_base': ''}`}>
            <div className={`system_modal_wrapper  ${showStudent ? 'show_system_modal_wrapper': ''}`}>
                <div className='system_modal_content'>
                    <header className='modal_title modal_title_with_loader'><span hidden={ !isSaving } className='modal_title_loader'><LoaderOval /></span><span>{ student?.user.first_name } { student?.user.last_name }'s Grades</span></header>
                    <div onClick={ close_modal } className='system_modal_content_btn'><VscChromeClose /></div>
                    
                    <div className='modal_navigate'>
                        <div className='modal_location'>Class/Student/Grade</div>
                        {/* <div className='filters'>
                            filter
                        </div> */}
                        {
                            terms.terms && (
                            <div className='filters'>
                                <div className='filter_btn'>{ selectedTerm }</div>
                                <div className='modal_select' style={{maxHeight: '400px', overflowY: 'scroll'}}>
                                        {
                                            terms.terms && terms.terms.map(term => (
                                                <li onClick={() => update_selected_term(term)} key={term.id}>Term {term.term_name}</li>
                                            ))
                                        }
                                </div>
                            </div>
                            )
                        }
                    </div>
                    <div className='subject_list'>
                    <table className='tree'>
                        <thead className='tree_table_head'>
                            <tr>
                                <th><input type='checkbox' value={selectAll} onChange={() => setSelectAll(!selectAll)} /></th>
                                <th>Subject</th>
                                {
                                    sequences.map(seq => (
                                        <th>{ seq.name }</th>
                                    ))
                                }
                                
                                <th>Avg.</th>
                                <th>Coeff</th>
                                <th>Total</th>
                                <th>Teacher</th>
                            </tr>
                            </thead>
                            <tbody className='tree_table_body'>
                                    {
                                        
                                        fetchingMarks
                                        ?
                                        <>
                                            <div className='fetching_grade_loader'>
                                                <LoaderOval />
                                            </div>
                                        </>
                                        :
                                        notification_message.length <= 0 
                                        &&
                                        (
                                            Object.entries(studentSubjects).map(([subject, grades]) => (
                                                    <SubjectList grades={grades} studentSubjects={studentSubjects} handle_save_grades={save_grade} key={subject?.id} isEditing={isEdit} handleEditState={ editState } handleOpenStudent={ openStudent } subject={subject} handleSelect={selectItem} selectAll={selectAll} />
                                            ))
                                        )
                                    }
                                    
                            </tbody>
                    </table> 
                    {
                        notification_message.length > 0
                        &&(
                            <div className='grading_student_message'>
                                <div>{ notification_message }</div>
                            </div>
                        )
                    }
                    </div>
                    
                    <div className='modal_actions_section'>
                        <div className='action_button_list'>
                            <button onClick={() => navigate(`/students/${student?.id}`)} className='modal_btn primary'>View Profile</button>
                            {
                                isEdit ? (
                                    <button onClick={ saveChanges } className='modal_btn modal_btn_with_loader'>
                                        <span>Save</span>
                                        { isSaving && <span className='modal_btn_loader'><LoaderOval /></span> }
                                    </button>

                                ) : (
                                    <button onClick={ download_result } className='modal_btn modal_btn_with_loader'>
                                        <span>Download profile</span>
                                        { isDownloading && <span className='modal_btn_loader'><LoaderOval /></span> }
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SlideInNotifications triggered={showNotify} notification={notify} />
        </>
    )
}



const SubjectList = ({ subject, grades, studentSubjects, selectAll, handleSelect, handleOpenStudent, handle_save_grades, isEditing, handleEditState }) => {
    
    const [seqA, setSeqA] = useState(0)
    const [seqB, setSeqB] = useState(0)
    const [average, setAverage] = useState(0.0)
    const [seqGrades, setSeqGrades] = useState([])

    useEffect(() => {
        const newData = grades.map(item => ({
            id: item.id,
            grade: item.sequence.grade
        }));

        setSeqGrades(newData)
          
        const sum = newData.reduce((acc, item) => acc + item.grade, 0);
        setAverage((sum / newData.length).toFixed(1));
    }, [])
    
    
    useEffect(() => {
        const sum = seqGrades.reduce((acc, item) => acc + item.grade, 0);
        setAverage((sum / seqGrades.length).toFixed(2));
    }, [seqGrades])
    

    const editRow = () => {
        handleEditState(true)
    }

    const handleInputChangeSeqA = (e, index) => {
        let value  = e.target.value

        if (/^\d*$/.test(value)) {
            value = parseFloat(value) > 20 ? 20 : value
            let target = seqGrades.filter(grade => grade.id === index)
            target.grade = parseFloat(value) || 0

            setSeqGrades(prevValues => (
                prevValues.map(item => {
                  if (item.id === index) {
                    return { ...item, grade: parseFloat(value) || 0 };
                  }
                  return item;
                })
              ));
            
        }
    };

    const handleInputChangeSeqB = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setSeqB(parseFloat(value) || 0);
        }
    };

    const update_grade = (grade) => {
        const data = {
            "sequence": grade.id,
            "grade": grade.grade,
            "subject": studentSubjects[subject][0].sequence.subject.id,
            "student": studentSubjects[subject][0].sequence.student,
        }
        handle_save_grades(data)
    }


      
    return (
        <tr onDoubleClick={editRow} className='tree_item' >
                <td><input type='checkbox' value={selectAll} onChange={() => handleSelect(subject)} /></td>
                <td>{subject}</td>
                {
                    isEditing ? (
                        <>
                        {
                            seqGrades.map(grade => (
                                <>
                                <td className='editing_input_data'><div><input onBlur={(e) =>update_grade(grade)}  type='text' autoFocus onFocus={e => e.target.select()} value={grade.grade} className='resultEdit' onChange={(e) => handleInputChangeSeqA(e, grade?.id)} /></div></td>
                                </>
                            ))
                        }
                        {/* <td className='editing_input_data'><div><input onBlur={update_grade} type='text' value={seqB} onFocus={e => e.target.select()} className='resultEdit' onChange={handleInputChangeSeqB} /></div></td> */}
                        </>

                    ) : (
                        <>
                        {
                            seqGrades.map(grade => (
                                <td>{ grade.grade }</td>
                            ))
                        }
                        </>
                    )
                }
                
                <td>{ average }</td>
                
            
                <td>
                { 
                    studentSubjects[subject].length > 0 &&  studentSubjects[subject][0].sequence?.subject?.sub_coef
                }
                </td>
                <td>{ average * studentSubjects[subject][0].sequence?.subject?.sub_coef }</td>
                <td>{ studentSubjects[subject][0].sequence?.subject?.instructor }</td>
        </tr>
    )
}
