import React, { useEffect, useState } from 'react'
import { ReactComponent as LoaderOval } from '../../../assets/LoaderOval.svg'
import SlideInNotifications from '../../../Addons/SlideNotification'
import { getTenant, postTenant } from '../../../Resquest'
import useLocalStorage from '../../../lib/UseLocalStorage'

const ClassTuitionSettings = (cls, server) => {
        const [showClassDetails, setShowClassDetails] = useState(false)
        const [isSaving, setIsSaving] = useState(false)
        const [tenantUser, setTenantUser] = useLocalStorage('tenantUser', )
        const [target, setTarget] = useState({})
        const [showNotify, setShowNotify] = useState(false)
        const [notification, setNotification] = useState('')
        const [feeAmount, setFeeAmount] = useState(0)
        const [firstInstallment, setFirstInstallment] = useState(0)
        const [secondInstallment, setSecondInstallment] = useState(0)
        const [thirdInstallment, setThirdInstallment] = useState(0)
        const [schoolFeeForClass, setSchoolFeeForClass] = useState({})
        const [clsFees, setClsFees] = useState([])
        const [endDate, setEndDate] = useState('')
        const [endDateUI, setEndDateUI] = useState('')

        useEffect(() => {
                setFirstInstallment(feeAmount)
        }, [feeAmount])

        useEffect(() => {
                fetch_term_detail()
        }, [target])

        useEffect(() => {
                if(showClassDetails) return
                fetch_class_fees()
        }, [showClassDetails])


        useEffect(() => {
                setFirstInstallment(schoolFeeForClass?.first_installment || 0)
                setSecondInstallment(schoolFeeForClass?.second_installment || 0)
                setThirdInstallment(schoolFeeForClass?.third_installment || 0)
                setFeeAmount(schoolFeeForClass?.fee_amount || 0)
        }, [schoolFeeForClass])


        useEffect(() => {
                setSecondInstallment(feeAmount - firstInstallment)
        }, [firstInstallment])


        useEffect(() => {
                const balance = parseInt(feeAmount) - (parseInt(firstInstallment) + parseInt(secondInstallment))
                balance >= 0 && setThirdInstallment(balance)
        }, [firstInstallment, secondInstallment])
        

        const fetch_class_fees = async() => {
                const response = await getTenant(tenantUser?.server, '/classes/fees/')
                response.status === 200 && setClsFees(response.data) 
        }
        
        const fetch_term_detail = async() => {
                if(target?.id){
                        const response = await getTenant(tenantUser?.server, `/classes/fees/${target?.id}/`)
                        response.status === 200 ? setSchoolFeeForClass(response.data) : setSchoolFeeForClass({})
                }
        }

        const data = {
                "cls": target.id,
                "fee_amount": feeAmount,
                "first_installment": firstInstallment,
                "second_installment": secondInstallment,
                "third_installment": thirdInstallment
        }

        const saveChanges = async() => {
                const totalInstallment = parseInt(firstInstallment) + parseInt(secondInstallment) + parseInt(thirdInstallment)
                if(totalInstallment !== parseInt(feeAmount)){
                       return alert("Total installments don't match with Fee amount", "info")
                }
                create_student_fee()
        }
        
        const create_student_fee = async() => {
                const response = await postTenant(tenantUser?.server, '/classes/fees/', data)
                if(response.status === 201){
                        alert('Changes saved successfully', 'success')
                        goBack()
                }
        }

        const alert = (msg, status) => {
                setShowNotify(true)
                setNotification({text: msg, id: Math.random(), status: status})
        } 

        const goBack = () => {
                setShowClassDetails(false)
        }

        const select_class = (cls) => {
                setTarget(cls)
                setShowClassDetails(true)
        }


  return (
    <div>
        {
                showClassDetails
                ?
                <div className='class_tuition_detail'>
                        <div className='modal_location'>{ target?.level?.name } { target?.class_name }</div>
                        <div className='tuition_regulation_form'>
                                <div className='col_entry fee_amount'>
                                        <label>Fee Amount</label>
                                <input type='text' className='col_input' value={feeAmount} onChange={e=> setFeeAmount(e.target.value)} placeholder='Tuition Fee' />
                                </div>
                                <div className='col_entry'>
                                        <label>First. Installments</label>
                                <input type='text' className='col_input' value={firstInstallment} onChange={e=> setFirstInstallment(e.target.value)} placeholder='First Installments' />
                                </div>
                                <div className='col_entry'>
                                        <label>Second Installments</label>
                                <input type='text' className='col_input' value={secondInstallment} onChange={e=> setSecondInstallment(e.target.value)} placeholder='Second Installments' />
                                </div>
                                <div className='col_entry'>
                                        <label>Third Installments</label>
                                <input type='text' className='col_input' value={thirdInstallment} onChange={e=> setThirdInstallment(e.target.value)} placeholder='Third Installments' />
                                </div>
                                {/* <div className='col_entry'>
                                <input type='text' className='col_input' placeholder='End Date' />
                                </div> */}

                                <div className='flex_tuition_edit_btn'>
                                        <button onClick={ goBack } className='modal_btn modal_btn_with_loader'>
                                                <span> Go back</span>
                                        </button>
                                        <button onClick={ saveChanges } className='modal_btn modal_btn_with_loader'>
                                                <span> {isSaving ? 'Saving' : 'Save changes' }</span>
                                                { isSaving && <span className='modal_btn_loader'><LoaderOval /></span> }
                                        </button>
                                </div>
                        </div>
                </div>
                :
                <div className='scroll_container'>
                <table className='tree'>
                        <thead className='tree_table_head'>
                                <tr>
                                        <th><input type='checkbox' /></th>
                                        <th>Class</th>
                                        <th>Department</th>
                                        <th>Tuition</th>
                                        <th>Installments</th>
                                        <th>End Date</th>
                                </tr>
                        </thead>
                        <tbody className='tree_table_body'>
                                {
                                        cls?.cls.map(clas => (
                                                <ClassTit clsFees={clsFees} key={clas?.cls?.id} handle_select_class={select_class} cls={ clas } />
                                        ))
                                }
                                
                        </tbody>
                </table> 
                </div>
        }
        <SlideInNotifications notification={notification} triggered={ showNotify } />
        
    </div>
  )
}

export default ClassTuitionSettings


const ClassTit = ({cls, clsFees, handle_select_class}) => {
        const [classFee, setClassFee] = useState('Null')
        const [instalmentCount, setInstalmentCount] = useState(0)
        useEffect(() => {
          // find the class fee and installment count for particular class and update classFee state if there is a match 
                const fee = clsFees.find(fee => fee.id === cls?.id)
                if(fee) {
                        let installment_counter = 0
                        setClassFee(fee.fee_amount)
                        fee.first_installment && installment_counter++
                        fee.second_installment && installment_counter++
                        fee.third_installment && installment_counter++
                        setInstalmentCount(installment_counter)
                }
        }, [clsFees])
        
        const select_cls = () => {
                handle_select_class( cls )
        }
    return (
        <tr onClick={ select_cls } className='class_tuition_tr'>
            <td><input type='checkbox' /></td>
            <td>{ cls?.level?.name } { cls?.class_name }</td>
            <td>{ cls?.level?.departments?.name } { cls?.level?.departments?.language_supports && cls?.level?.departments?.language_supports[0] }</td>
            <td>{ classFee }</td>
            <td><span className='tag class_installment_tag'>{ instalmentCount }</span>Installments</td>
            <td>End Date</td>
        </tr>
    )
}