import React from 'react'
import './settings.css'
const Setting = () => {
  return (
    <div className='setting_page'>
      <div className='container'>
        <div className='setting_wrapper'>
          <div className='home_card'>
            <h1 className='mini_title'>Email address</h1>
            <div className='flex'>
              <div className='setting_account_current'>
                <h2 className='mini_title'>someone@server.com</h2>
                <br/> 
                <button className='setting_btn_normal'>Change Email</button>
              </div>
              <div className='setting_account'>
                <h2 className='mini_title'>Mail servers</h2>
                <h2 className='mini_title delete_title'>Delete Account</h2>
                <p className='delete_warn'>Once you delete your account, here is no going. Please be certain</p>
                <button className='setting_btn_normal delete_btn'>Delete your account</button>
              </div>
            </div>
          </div>
          
          
          <div className='home_card'>
            <h1 className='mini_title'>Payment details</h1>
            <div className='setting_param'>
              <div className='setting_left'>Prefered method</div>
              <div className='setting_right mini_title'>Paypal</div>
            </div>
            <div className='setting_param'>
              <div className='setting_left'>Linked account</div>
              <div className='setting_right mini_title'>
                <button className='setting_btn_normal'>kimbispaypal@pay.com</button>
              </div>
            </div>
          </div>
          
          
          <div className='home_card'>
            <h1 className='mini_title'>Subsciption plan</h1>
            <div className='setting_param'>
              <div className='setting_left'>Current plan</div>
              <div className='setting_right mini_title'>free</div>
            </div>
            <div className='setting_param'>
              <div className='setting_left'>Change plan</div>
              <div className='setting_right mini_title'>
                <button className='setting_btn_normal'>upgrade</button>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  )
}

export default Setting