import React, { useContext, useEffect, useState } from 'react'
import { ReactComponent as Loader } from '../../../assets/loader.svg'
import { getTenant, postTenant } from '../../../Resquest'
import { StudentAdmissionContext } from '../../../Addons/AdmissionContext'
import SlideInNotifications from '../../../Addons/SlideNotification'
import { useNavigate } from 'react-router-dom'

const PageThree = ({ server, handle_submit_information }) => {

    const [year, setYear] = useState('2023-2024')
    const [qualification, setQualification] = useState('')
    const [registration, setRegistration] = useState('Not paid')
    const [studentClass, setStudentClass] = useState('')
    const [department, setDepartment] = useState('')
    const [regAmount, setRegAmount] = useState(0)
    const [balance, setBalance] = useState('')
    const [isloading, setIsloading] = useState(false)



    const [medicalCondition, setMedicalCondition] = useState('')
    const [emergencyContact, setEmergencyContact] = useState('')

    const [qualificationDD, setQualificationDD] = useState(false)
    const [departmentDD, setDepartmentDD] = useState(false)
    const [classDD, setClassDD] = useState(false)
    const [selectedClassId, setSelectedClassId] = useState(null)
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)
    const [registrationDD, setRegistrationDD] = useState(false)

    const [departments, setDepartments] = useState([])
    const [classes, setClasses] = useState([])

    const [studentAdmission, setStudentAdmission] = useContext(StudentAdmissionContext)
    const [guardians, setGuardians] = useState([])

    const [showNotify, setShowNotify] = useState(false)
    const [notification, setNotification] = useState({})

    const navigate = useNavigate()

    const medicalConditions = [
        'Asthma',
        'Allergies',
        'Diabetes',
        'Epilepsy',
        'Attention Deficit Hyperactivity Disorder (ADHD)',
        'Autism Spectrum Disorder (ASD)',
    ];


    useEffect(() => {
        if(guardians.length <= 0 && studentAdmission.guarians){
            let guadians_var = studentAdmission.guarians
            setGuardians(guadians_var)
            delete studentAdmission.guarians
        }
    }, [])
    
    
    const submit_information = () => {
        setIsloading(true)
        if(!selectedDepartmentId || !selectedClassId){
            setShowNotify(true)
            setNotification({text: 'Please select a valid department/class', id: Math.random(), status: 'info' })
            return 
        }

        const data = {
            "department": selectedDepartmentId,
            "student_class": selectedClassId, 
        }

        create_student({...studentAdmission, ...data})
    }


    const create_student = async(request) => {
        console.log(request);
        const response = await postTenant(server, '/students/', request)
        if(response.status === 201){
            setNotification({text: 'Student Created successfully!', id: Math.random(), status: 'success' })
            setShowNotify(true)
            create_guardian(response.data.id)
        }
        else {
            setNotification({text: 'Error admiting student. try again later!', id: Math.random(), status: 'failed' })
            setShowNotify(true)
            setIsloading(false)
        }
    }


    const create_guardian = async(student_id) => { 
        const guardian_request = []
        let guardian_var =  guardians.guardians
        if(!guardian_var || guardian_var.length <= 0){
            setIsloading(false)
            setShowNotify(true)
            setNotification({text: 'Please add at least one guardian', id: Math.random(), status: 'info' })
            return
        }

        guardian_var.forEach(guardian => {
            guardian_request.push({
                "student": student_id,
                ...guardian
            })
        })

        const response = await postTenant(server, '/guardians/', guardian_request)
        if(response.status === 201){
            setShowNotify(true)
            setNotification({text: 'Student guardians added :)', id: Math.random(), status: 'success' })
        }
        else {
            setNotification({text: 'Unable to create guardian for student', id: Math.random(), status: 'failed' })
            setIsloading(false)
        }
        complete_registration(response.data.id, student_id)

     }

     const complete_registration = async(guardian, student_id) => {
        navigate('/students/'+ student_id)
     }

    useEffect(() => {
        fetch_departments()
        fetch_classes()
    }, [])    
  
    const fetch_departments = async() => {
        const response = await getTenant(server, '/departments/')
        setDepartments(response?.data)
    }

    const fetch_classes = async() => {
        const response = await getTenant(server, '/classes/')
        setClasses(response?.data)
    }
      

    const update_class = (cls) => {
        console.log(cls);
        setSelectedClassId(cls.id)
        setStudentClass(cls.class_name)
    }

    const update_department = (department_name) => {
        const selectedDepartment = departments.find(department => department.name === department_name.split(' - ')[0])
        setSelectedDepartmentId(selectedDepartment.id)
        setDepartment(department_name)
    }

  return (
    <>
        <div className='admission_title'>
            <h1>Academics</h1>
            <p>Student class information and registration status</p>
        </div>
        <form className='admission_form'>
            <div className='nameWrapper inputflex'>
                <div className='sides'>
                    <label htmlFor='level' className='input_label'>Qualification</label>
                    <input 
                        value={qualification} 
                        onFocus={() => setQualificationDD(true)} 
                        onBlur={() => setTimeout(() => { setQualificationDD(false)}, 300) } 
                        placeholder='e.g Ordinary Level' type='text' 
                        name='level' className='auth_input' 
                    />
                    <div className={`student_admission_dropdown ${qualificationDD ? 'show': ''}`}>
                        <ul onClick={(e) => setQualification( e.target.textContent)}>
                            <li>High school</li>
                            <li>Secondary Education</li>
                            <li>Primary Education</li>
                            <li>Early Childhood Education</li>
                            <li>Others</li>
                        </ul>
                  </div>
                </div>
                
                <div className='sides'>
                    <label htmlFor='year' className='input_label'>Academic year</label>
                    <input value={year} onChange={e => setYear('2023 - 2024')} type='text' name='year' className='auth_input' />
                </div>

            </div>
            <div className='nameWrapper inputflex'>
                <div className='sides'>
                    <label htmlFor='department' className='input_label'>Department</label>
                    <input 
                        value={department} 
                        onFocus={() => setDepartmentDD(true)} 
                        onBlur={() => setTimeout(() => { setDepartmentDD(false)}, 300) } 
                        type='text' placeholder='Select department' 
                        name='department' className='auth_input'
                    />
                    <div className={`student_admission_dropdown ${departmentDD ? 'show': ''}`}>
                        <ul onClick={(e) => update_department( e.target.textContent)}>
                            {
                                departments.length > 0 && departments.map((department, index) => (
                                    <li key={index}>{ department.name } - { department?.language_supports[0] }</li>
                                ))
                            }
                        </ul>
                  </div>
                </div>
                <div className='sides'>
                    <label htmlFor='studentClass' className='input_label'>Class</label>
                    <input 
                        value={studentClass} 
                        onFocus={() => setClassDD(true)} 
                        onBlur={() => setTimeout(() => { setClassDD(false)}, 300) } 
                        type='text' placeholder='Select class' 
                        name='studentClass' className='auth_input' 
                    />
                    <div className={`student_admission_dropdown ${classDD ? 'show': ''}`}>
                        <ul>
                            {
                                classes.length > 0 && classes.map((classs, index) => (
                                    <li onClick={() => update_class(classs)} key={index}>{ classs.level.name} {classs.class_name}</li>
                                ))
                            }
                        </ul>
                  </div>
                </div>
            </div>
            
            <div className='nameWrapper'>
                <label htmlFor='education' className='input_label'>Medical Condition</label>
                <input 
                    value={medicalCondition} 
                    onChange={e => setMedicalCondition(e.target.value)}  
                    type='text' name='education' 
                    className='auth_input' 
                    list='medicalConditionsList'
                />
                <datalist id='medicalConditionsList'>
                    {medicalConditions.map((condition, index) => (
                    <option key={index} value={condition} />
                    ))}
                </datalist>
            </div>
            <div className='nameWrapper'>
                <label htmlFor='education' className='input_label'>Emergency contact</label>
                <input value={emergencyContact} onChange={e => setEmergencyContact(e.target.value)}  type='text' name='education' className='auth_input' />
            </div>


            <div className='nameWrapper'>
                <label htmlFor='registration' className='input_label'>Registration</label>
                <input 
                    value={registration} 
                    onFocus={() => setRegistrationDD(true)} 
                    onBlur={() => setTimeout(() => { setRegistrationDD(false)}, 300) } 
                    type='text' name='registration' className='auth_input' 
                />
                <div className={`student_admission_dropdown ${registrationDD ? 'show': ''}`}>
                        <ul onClick={(e) => setRegistration( e.target.textContent)}>
                            <li>Not paid</li>
                            <li>Partial</li>
                            <li>Completed</li>
                        </ul>
                  </div>
            </div>

            <div className='nameWrapper inputflex'>
                <div className='sides'>
                    <label htmlFor='regAmount' className='input_label'>Amount Paid</label>
                    <input value={regAmount} onChange={e => setRegAmount(e.target.value)} type='number' name='regAmount' className='auth_input' />
                </div>
                <div className='sides'>
                    <label htmlFor='balance' className='input_label'>Balance</label>
                    <input value={balance} onChange={e => setBalance(e.target.value)} type='text' disabled placeholder='Amount to pay' name='section' className='auth_input' />
                </div>
            </div>
        </form>
        <br />
        <br />
        <div className='nameWrapper'>
          <button onClick={ submit_information } type='submit' className='auth_input auth_submit_btn'>
            <span>{ isloading ? 'Registering...' : 'Register student' }</span>
            { isloading && <span className='btn_svg'><Loader /></span> }
          </button>
        </div>
        <SlideInNotifications notification={notification} triggered={ showNotify } />
    </>
  )
}

export default PageThree