import React from 'react'
import { RiInboxArchiveFill } from 'react-icons/ri'
import { MdChevronRight } from 'react-icons/md'
import { NavLink, useLocation } from 'react-router-dom'

const ChatPersons = ({ persons }) => {
    const location = useLocation()
    const filterList = () => {
        const filterBy = location.hash
        console.log('filter by: ', filterBy);
}
  return (
    <aside className='main_aside'>
            <h2 className="panel_title">
                <span className='icon'><RiInboxArchiveFill /></span>
                <span className='bold-text'>Inbox</span>
                </h2>
                <div className='panel_category_list'>
                        <ul onClick={filterList}>
                            {
                                persons.map(person => (
                                    <NavLink key={person?.id} to='#chat' className={`panel_category_item ${location.hash === '#chat'? 'filterSet': ''}`}>
                                            <span className='text'>{ person?.name }</span>
                                            { person?.id < 50 ?
                                            <span className='count chat_count'>{ person?.id }</span>
                                            :
                                            <span className='count'><MdChevronRight /></span>
                                            }
                                    </NavLink>

                                ))
                            }
                        </ul>
                    </div>
            </aside>
  )
}

export default ChatPersons