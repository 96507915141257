import React, { useContext, useState, useEffect } from 'react';
import { ReactComponent as Loader } from '../../../assets/loader.svg';
import { StudentAdmissionContext } from '../../../Addons/AdmissionContext';
import SlideInNotifications from '../../../Addons/SlideNotification'

const PageOne = ({ level, handle_save_info }) => {
  const [isloading, setIsloading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [age, setAge] = useState('');
  const [bio, setBio] = useState('');
  const [dobYear, setDobYear] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobDay, setDobDay] = useState('');
  const [gender, setGender] = useState('');
  const [status, setStatus] = useState('');
  const [studentAdmission, setStudentAdmission] = useContext(StudentAdmissionContext);

  const [showNotify, setShowNotify] = useState(false)
  const [notification, setNotification] = useState({})

  useEffect(() => {
    calculateAge();
  }, [dobYear, dobMonth, dobDay]);

  const calculateAge = () => {
    if (dobYear && dobMonth && dobDay) {
      const today = new Date();
      const birthDate = new Date(dobYear, dobMonth - 1, dobDay);
      let ageDiff = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        ageDiff--;
      }
      setAge(ageDiff);
    } else {
      setAge('');
    }
  };

  const validateYear = (year) => {
    const currentYear = new Date().getFullYear();
    if (year && (year < 1900 || year > currentYear)) {
        setDobYear('')
        setShowNotify(true)
        setNotification({text: 'Invalid year. Please enter a year between 1900 and the current year', id: Math.random(), status: 'error' })
        return;
    }
    if(!year){
        setDobMonth('')
        setShowNotify(true)
        setNotification({text: 'You need to enter a valid Year.', id: Math.random(), status: 'info' })
    }
  };

  const validateMonth = (month) => {
    if (month && (month < 1 || month > 12)) {
        setDobMonth('')
        setShowNotify(true)
        setNotification({text: 'Invalid month. Please enter a month between 1 and 12.', id: Math.random(), status: 'error' })
      return;
    }
    if(!month){
        setDobMonth('')
        setShowNotify(true)
        setNotification({text: 'You need to enter a valid Month.', id: Math.random(), status: 'info' })
    }
  };

  const validateDay = (day) => {
    if (day && (day < 1 || day > 31)) {
        setDobDay('')
        setShowNotify(true)
        setNotification({text: 'Invalid day. Please enter a day between 1 and 31.', id: Math.random(), status: 'error' })
    }if(!day){
        setDobMonth('')
        setShowNotify(true)
        setNotification({text: 'You need to enter a valid Day.', id: Math.random(), status: 'info' })
    }
  };

  const validateDateOfBirth = () => {
    const isValidYear = dobYear && dobYear <= new Date().getFullYear();
    const isValidMonth = dobMonth && dobMonth >= 1 && dobMonth <= 12;
    const isValidDay = dobDay && dobDay >= 1 && dobDay <= 31;

    return isValidYear && isValidMonth && isValidDay;
  };

  const create_student = async () => {
    if (!validateDateOfBirth()) {
      alert('Please enter a valid date of birth.');
      return;
    }

    const data = {
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      status: status,
      date_of_birth: `${dobYear}-${dobMonth < 10 ? '0'+dobMonth : dobMonth}-${dobDay < 10 ? '0'+dobDay : dobDay}`,
      bio: bio,
    };
    console.log('Student Personal Information:', data);
    setStudentAdmission(data);
    handle_save_info(level);
  };

  const [genderDD, setGenderDD] = useState(false);
  const [statusDD, setStatusDD] = useState(false);

  return (
    <>
      <div className="admission_title">
        <h1>Student Personal Information</h1>
        <p>These information are required to get the student into the institution</p>
      </div>
      <form className="admission_form">
        <div className="nameWrapper inputflex">
          <div className="sides">
            <label htmlFor="fname" className="input_label">First Name</label>
            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" name="fname" className="auth_input" />
          </div>
          <div className="sides">
            <label htmlFor="lname" className="input_label">Last Name</label>
            <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" name="lname" className="auth_input" />
          </div>
        </div>
        <div className="nameWrapper inputflex input_flex_date">
          <div className="sides">
            <label htmlFor="dobYear" className="input_label">Date of Birth(YYYY)</label>
            <input value={dobYear} onChange={(e) => setDobYear(e.target.value)} onBlur={() => validateYear(dobYear)} type="number" name="dobYear" placeholder="YYYY" className="auth_input" />
          </div>
          <div className="sides">
            <label htmlFor="dobMonth" className="input_label">-(MM)</label>
            <input value={dobMonth} onChange={(e) => setDobMonth(e.target.value)} onBlur={() => validateMonth(dobMonth)} type="number" name="dobMonth" placeholder="MM" className="auth_input" />
          </div>
          <div className="sides">
            <label htmlFor="dobDay" className="input_label">-(DD)</label>
            <input value={dobDay} onChange={(e) => setDobDay(e.target.value)} onBlur={() => validateDay(dobDay)} type="number" name="dobDay" placeholder="DD" className="auth_input" />
          </div>
        </div>
        <div className="nameWrapper">
          <label htmlFor="age" className="input_label">Age</label>
          <input value={age} type="text" name="age" className="auth_input" readOnly />
        </div>
        <div className="nameWrapper inputflex">
          <div className="sides">
            <label htmlFor="gender" className="input_label">Gender</label>
            <input value={gender} onFocus={() => setGenderDD(true)} onBlur={() => setTimeout(() => setGenderDD(false), 300)} type="text" name="gender" className="auth_input" />
            <div className={`student_admission_dropdown ${genderDD ? 'show' : ''}`}>
              <ul onClick={(e) => setGender(e.target.textContent)}>
                <li>Male</li>
                <li>Female</li>
                <li>Other</li>
                <li>Prefer not to say</li>
              </ul>
            </div>
          </div>
          <div className="sides">
            <label htmlFor="status" className="input_label">Status</label>
            <input value={status} onFocus={() => setStatusDD(true)} onBlur={() => setTimeout(() => setStatusDD(false), 300)} type="text" name="status" className="auth_input" />
            <div className={`student_admission_dropdown ${statusDD ? 'show' : ''}`}>
              <ul onClick={(e) => setStatus(e.target.textContent)}>
                <li>Single</li>
                <li>Married</li>
                <li>Widowed</li>
                <li>Prefer not to say</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="nameWrapper">
          <label htmlFor="bio" className="input_label">About</label>
          <textarea value={bio} onChange={(e) => setBio(e.target.value)} placeholder="e.g Previous school" name="bio" className="auth_input auth_textarea"></textarea>
        </div>
      </form>
      <div className="nameWrapper">
        <button onClick={create_student} type="button" className="auth_input auth_submit_btn">
          <span>Proceed</span>
          {isloading && <span className="btn_svg"><Loader /></span>}
        </button>
      </div>
      <SlideInNotifications notification={notification} triggered={ showNotify } />
    </>
  );
};

export default PageOne;

