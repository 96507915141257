
import React, { useState } from 'react'

import { GoPerson } from "react-icons/go"

import { Actions, AnimateOnboard } from "../../SlideInfomation"
import { TfiEmail } from "react-icons/tfi"
import { HiOutlinePhone } from 'react-icons/hi2'
import { patchTenant, postTenant } from '../../../../../Resquest'



// PROPRIETOR
export const SlideInfomationProprietor = ({ user, handle_save_data, handle_go_back, index }) => {
    const [directorEmail, setDirectorEmail] = useState('')
    const [director, setDirector] = useState('')
    const [directorPhone, setDirectorPhone] = useState('')
    const [principal, setPrincipal] = useState('')
    const [principalEmail, setPrincipalEmail] = useState('')
    const [isloading, setIsloading] = useState(false)
   
    const data = {
      "email": principalEmail,
      "phone": directorPhone,
      "principal_name": principal,
      "principal_mail": principalEmail,
      "director": director,
      "director_email": directorEmail,
      "director_phone": directorPhone
    }
    
    const save_data = () => {
      setIsloading(true)
      update_school_info()
      handle_save_data(index, {})
    }

    const update_school_info = async() => {
      const response = await patchTenant(user?.school_code, '/schools/', data)
      console.log(response);
    }


    const go_back =() => {
      handle_go_back(index)
    }
  
  
  return (
    <>
      <AnimateOnboard>
          <h1 className='bold-text large' style={{ marginBottom: '4rem', marginLeft: "1rem"}}>Information about school owners</h1>
          <section className='school_onboard-1'>
            <div className='onboard_form_control'>
                <label htmlFor="name">Name of Director</label>
                <div className='onboard_input_wrapper'>
                  <input type="text" value={director} onChange={e=> setDirector(e.target.value)} />
                  <span className='form_control_icon'><GoPerson /></span>
                </div>
            </div>
            <div className='onboard_form_control'>
                <label htmlFor="name">Email of Director</label>
                <div className='onboard_input_wrapper'>
                  <input type="email" required value={directorEmail} onChange={e=> setDirectorEmail(e.target.value)} />
                  <span className='form_control_icon'><TfiEmail /></span>
                </div>
            </div>
            <div className='onboard_form_control'>
                <label htmlFor="name">Phone number of Director</label>
                <div className='onboard_input_wrapper'>
                  <input type="email" required value={directorPhone} onChange={e=> setDirectorPhone(e.target.value)} />
                  <span className='form_control_icon'><HiOutlinePhone /></span>
                </div>
            </div>
            <br />
            <br />
            <div className='onboard_form_control'>
                <label htmlFor="name">Name of principal</label>
                <div className='onboard_input_wrapper'>
                  <input type="text" value={principal} onChange={e=> setPrincipal(e.target.value)} />
                  <span className='form_control_icon'><GoPerson /></span>
                </div>
            </div>
            <div className='onboard_form_control'>
                <label htmlFor="name">Email of principal</label>
                <div className='onboard_input_wrapper'>
                  <input type="text" value={principalEmail} onChange={e=> setPrincipalEmail(e.target.value)} />
                  <span className='form_control_icon'><TfiEmail /></span>
                </div>
            </div>
            <p className='small'>You can skip this and configure it later in settings</p>
          </section>
      </AnimateOnboard>
      <Actions save_data={save_data} skip={true}  handle_go_back={go_back} isloading={isloading} />
    </>
  )
  }

  export default SlideInfomationProprietor;