import React, { useState } from 'react'
import './Home.css'
import { Link } from 'react-router-dom'
import { PiPiggyBankLight } from 'react-icons/pi'
import FaqCard from '../../Addons/FaqCard'
import RegisteredSchools from '../../Addons/RegisteredSchools'
import PopularSchools from './Sub/PopularSchools'

const Home = () => {
  const [showfaq, setShowfaq] = useState(false)

  return (
    <div className='home_page_user'>
        <div className='user_hero'>
          <div className='container'>
            <div className='flex'>
              <div className='hero_text_section'>
                <h1>Hello Kimbi</h1>
                <p>Welcome to Rank where we empower African with knowlege</p>
                <p>Get optimized experience for your institution and focus on what matters</p>
                <Link className='btn get_started_with_rank' to='/register'>Get started</Link>
              </div>
              <div className='hero_image_section'>

              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='home_content'>


            <div className='register_school_home'>
              <h1 className='title'>Registered schools</h1>
              <RegisteredSchools />
            </div>

            <div className='our_users'>
              <h1 className='title'>Our popular users include</h1>
              <div className='popular_school_list'>
                <PopularSchools />
              </div>
            </div>


            <div className='home_card'>
              <div className='flex'>
                <div className='refer-icon'>
                  <div className='refer_icon_i'><PiPiggyBankLight /></div>
                </div>
                <div className='refer-center'>
                  <p className='bold-text'>refer & win 10.000XAF</p>
                  <p>Help another school manage their activities</p>
                </div>
                <div className='refer-btn'>
                  <a href='/referal/me' target='_blank'>refer school</a>
                </div>
              </div>
            </div>


            <FaqCard />
          </div>
        </div>
    </div>
  )
}

export default Home

