import { IoLinkOutline } from "react-icons/io5"
import { Actions, AnimateOnboard } from "../../SlideInfomation"
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import {AnimatePresence, motion} from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

import './Slide.css'
import { LiaTimesSolid } from "react-icons/lia"
import { BsBookmarkPlus } from "react-icons/bs"
import { postTenant } from "../../../../../Resquest";


// LEVEL CLASSES
const SlideInfomationClass = ({server, school_types, handle_save_data, index, handle_go_back, prev_data }) => {

    const [isloading, setIsloading] = useState(false)
    const [created_classes, setCreated_classes] = useState([])


    const save_data = () => {
      setIsloading(true)
      create_classes(created_classes)
      setIsloading(false)
    }


    const create_classes = async(classes) => {
      let request = []
      
      classes.forEach(element => {
        let data = {
          "level": element?.level_id
        }
        element.data.forEach(cls => {
          data = {
            ...data,
            "class_name": cls.class_name,
            "class_range": cls.class_range,
            "h_o_d": "",
          }
          request.push(data)
        })
      });
      create_request(request);
    }

    const create_request = async(data) => {
      const res = await postTenant(server, '/classes/', data)
      res?.status === 201 && handle_save_data(index, data)
    }

    const go_back =() => {
      handle_go_back(index)
    }
    
    useLayoutEffect(() => {
      if(!school_types) {
        return go_back()
      } 
      if(prev_data.classes){
        prev_data?.classes.length > 0 && setCreated_classes(prev_data.classes)
      }
    }, [])
    

    const hold_class_data = (data) => {
      const existingIndex = created_classes.findIndex(item => item.school_type === data.school_type);
    
      if (existingIndex !== -1) {
        created_classes[existingIndex].data.push(data.data);
        setCreated_classes([...created_classes]);
      } else {
        setCreated_classes(prevClasses => [
          ...prevClasses.slice(0, index),
          { school_type: data.school_type, data: [data.data], level_id: data.level_id},
          ...prevClasses.slice(index)
        ]);
      }
    };
    
    const remove_item = (school_type, id) => {
      setCreated_classes(prevClasses => prevClasses.map(item => {
        if (item.school_type === school_type) {
          return {
            ...item,
            data: item.data.filter(dataItem => dataItem.id !== id)
          };
        }
        return item;
      }));
    };

  return (
    <>
      <AnimateOnboard>
        <section className='school_onboard-1 school_onboard_class'>
          <h1 className='title'>Classes in level</h1>
          <div className="onboard_class_create_wrapper">
            <div className="onboard_class_create_list">
              <>
              {
                created_classes.map((item, index) => (
                  <div key={index}>
                    <p className="bold-text">{ item.school_type }</p>
                    {
                      item.data.map((class_item, index) => (
                        <AnimateClass>
                          <div key={index} draggable className="onboard_class_create_item">
                            <p>{ class_item.class_name } ( { class_item.class_range} )</p>
                            <span className="icon" onClick={() =>remove_item(item.school_type, class_item.id)}><LiaTimesSolid /></span>
                          </div>
                        </AnimateClass>
                      ))
                    }
                  </div>
                ))
              }
              </>
            </div>
          </div>


          <div className="onboard_level_select_wrapper">
            <h3 className='level_select_title'>create classes for this level. You can change it later in settings</h3>
            <>
             {
              school_types.map((school_type, index) => (
                <SchoolTypeForm key={school_type?.id} handle_save_grade_data={hold_class_data} school_type={school_type} />
              ))
             }
            </>
            
          </div>
          {/* <div className="onboard_class_btn_wrapper">
            <button type="button" className="onboard_class_btn">Save and continue</button>
          </div> */}
        </section>
      </AnimateOnboard>
      <Actions save_data={save_data} handle_go_back={go_back} isloading={isloading} />
    </>
  )
}

export default SlideInfomationClass



const SchoolTypeForm = ({ school_type, handle_save_grade_data }) => {
  const schoolTitleRef = useRef('')

  const save_grade = (data) => {
    const class_with_type = {
      "school_type": school_type?.name,
      "level_id": school_type?.id,
      "data": data
    }
    handle_save_grade_data(class_with_type)
  }

  const marked_check = (e) => {
    schoolTitleRef.current.checked = true
  }

  return (
    <>
      <div className='onboad_level_title'>
        <input type="radio" name="school_type" id={school_type?.name} ref={schoolTitleRef} />
        <label htmlFor={school_type?.name} className='current_level_name' >{ school_type?.name.toUpperCase() }</label>
      </div> 

      <div className="onboard_class_tree">
          <OnboardClass mark_check={marked_check} handle_save_grade={save_grade} />
      </div>
    </>
  )
}

/**
 * Animate class during creaton and exit
 * @param {children} param0 Wraps arround children you want to animate
 * @returns JSX with animation
 */
export const AnimateClass = ({ children }) => {
  const pageTransition = {
    hidden: {
        y: "20px",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1
    },
    exit: {
        y: "3vh",
        opacity: 0,
  
    }
  }
  return (
      <AnimatePresence onExitComplete={true} mode='wait'>
        <motion.div variants={pageTransition} initial="hidden" animate="visible" exit="exit" transition={{duration: 0.6}}>
          {children}
        </motion.div>
      </AnimatePresence>
  )
}


export const OnboardClass = ({  handle_save_grade, mark_check }) => {
  const [class_name, setClass_name] = useState('')
  const [class_student_range, setClass_student_range] = useState('')
  const [show_student_droplist, setShow_student_droplist] = useState(false)
  const [alertClassName, setAlertClassName] = useState(false)
  const [alertStudentRange, setAlertStudentRange] = useState(false)
  const student_group_list = [
    "0 - 50",
    "50 - 100",
    "100 - 200",
    "200 - 500",
  ]
  let id = uuidv4()
  const data = {
    "class_name": class_name,
    "class_range": class_student_range,
    "id": id
  }

  const validate_input = (range) => {
    setClass_student_range(range)
  }

  const save = () => {
    if(class_name.length < 1){
      setAlertClassName(true)
    }
    else if(class_student_range.length < 1){
      setAlertStudentRange(true)
    }
    else{
      handle_save_grade(data)
      clear_form()
    }
    setTimeout(() => {
      setAlertClassName(false)
      setAlertStudentRange(false)
    }, 3000);
  }

  const clear_form = () => {
    setClass_name('')
    setClass_student_range('')
  }

  const handle_dropdown = () => {
    mark_check()
    setShow_student_droplist(true)
  }
  return (
    <>
      <div className="onboard_class">
        <div className={`onboard_input_wrapper ${alertClassName ? 'alert': ''}`}>
            <input onFocus={mark_check} type="text" value={class_name} onChange={(e) => setClass_name(e.target.value)} placeholder="Class e.g One, Two... " />
        </div>
        
        <p className="onboard_class_link_line">
          <span className='icon'><IoLinkOutline /></span>
          <p className="line" />
        </p>
        <div className="onboard_student_wrapper">
          <div className={`onboard_input_wrapper ${alertStudentRange ? 'alert': ''}`}>
              <input type="text" onFocus={handle_dropdown} value={class_student_range} onChange={() => setClass_student_range("")} onBlur={() => setShow_student_droplist(false)} placeholder="class range" />
          </div>
          {
            show_student_droplist && (
              <div className="onboard_class_student_dropdown">
                {
                  student_group_list.map((range, index) => (
                    <div key={index} onMouseDown={() => validate_input(range) }>{ range }</div>
                  ))
                }
              </div>
            )
          }
        </div>
        <button className="onboard_class_item_save" onClick={save} ><BsBookmarkPlus /></button>
      </div>
    </>
  )
}