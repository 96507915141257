import React, { useEffect, useState } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { json, useNavigate } from 'react-router-dom';
import UseCookie from '../lib/UseCookie';
import { VscClose } from "react-icons/vsc";

import { ReactComponent as Loader } from '../assets/loader.svg'
import GoogleButton from '../Auth/OAuth/GoogleButton';
import FacebookButton from '../Auth/OAuth/FaceBookButton';
import LinkedInButton from '../Auth/OAuth/LinkedInButton';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { postTenant } from '../Resquest';
import useLocalStorage from '../lib/UseLocalStorage';

const RegisteredSchools = () => {
  const navigate = useNavigate();
  const [userSchoolList, setUserSchoolList] = useState([])
  const [user, setUser] = UseCookie('user')
  const [changeSchoolModal, setChangeSchoolModal] = useState(false)
  const [loginSchoolData, setLoginSchoolData] = useState({})

  useEffect(() => {
    get_schools_from_user()
  }, [])
  

  const get_schools_from_user = () => {
    let skls = user?.user?.schools && user?.user?.schools
    let school_list = []
    if(skls.length <1 ) return
    for(let index in skls){
      let json_data = JSON.parse(skls[index].replace(/'/g, '"'));
      school_list.push(json_data)
    }

    setUserSchoolList(school_list)

  }
  
  const create_school = () => {
    navigate('/onboard/new-school/')
  }

  const change_school = (school) => {
    setChangeSchoolModal(true)
    setLoginSchoolData(school)

  }

  const remove_school_login_modal = () => {
    setChangeSchoolModal(false)
  }
  return (
    <>
    <div className='registered_school_wrapper'>
        <div className='registered_school_list'>
          {
            userSchoolList.length > 0
            ?
            <UserSchools handle_change_school={change_school} user={user} schools={userSchoolList} />
            :
            <div className='no_registered_school'>
                <p>You have no registered schools</p>
                <div className='add_school' onClick={create_school}>Create School </div>
            </div>

          }

        </div>
    </div>
    {
      changeSchoolModal && <ChangeSchoolLoginModal school={loginSchoolData} handle_remove_modal={remove_school_login_modal} />
    }
    </>
  )
}

export default RegisteredSchools

const UserSchools = ({ schools, user, handle_change_school }) => {

  const navigate = useNavigate();
  
  const create_school = () => {
    navigate('/onboard/new-school/')
  }
  
  const login_to_school = (school) => {
    if(school.tenant === user.server){
      window.location.href = '/dashboard'
      return
    }
    handle_change_school(school)
  }

  return (
    <>
      {
        schools.map(school => (
          <div onClick={ () => login_to_school(school) } className='registered_school'>
              { school?.school }
          </div>
        ))
      }
      <div onClick={create_school} className='registered_school add'>
          <BsPlusLg />
      </div>
    </>
  )
}


const ChangeSchoolLoginModal = ({ school, handle_remove_modal }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isloading, setIsloading] = useState(false)
  const [formInputAlert, setFormInputAlert] = useState('')
  const [tenantUser, setTenantUser] = useLocalStorage('tenantUser', {})
  const [hasSchool, setHasSchool] = useLocalStorage('hasSchool')
  const [user, setUser] = UseCookie('user')

  const submitForm = (e) => {
    setIsloading(true)
    makeRequest()
    setIsloading(false)
  }

  const userData = {
    "password": password,
    "email": email
  }

  const makeRequest = async() => {
    
    console.log(school?.tenant);
    const res = await postTenant(school.tenant, '/auth/login/', userData)
    console.log(res);
    res?.status === 200 &&  setTenantUser(res?.data)
    res?.status === 200 &&  setHasSchool(true)

    if(res?.status === 200){
      setUser(res?.data)
      setIsloading(false)
      window.location.href = '/dashboard'
    }

    if(res?.response?.status === 400){
      alertForm('invalid credentials')
    }
  }

  const alertForm = (msg) => {
      setFormInputAlert(msg)
      setIsloading(false)
      setTimeout(() => {
              setFormInputAlert('')
      }, 3000);
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="change_school_login_modal">
        <div onClick={handle_remove_modal } className='close_school_login_modal'><VscClose /></div>


        <div className="change_school_login_content">
          <h1 className='change_school_login_content_title'>Log into { school?.school }</h1>
          {
          formInputAlert.length > 1 && (
            <div className='nameWrapper'>
              <input value= {formInputAlert} disabled className='auth_input input_alert' />
            </div>
          )
          }
          <div className='nameWrapper'>
            <label htmlFor='email' className='input_label'>Email</label>
            <input value={email} onChange={e => setEmail(e.target.value)} type='email' name='email' className='auth_input' />
          </div>
          <div className='nameWrapper'>
            <label htmlFor='password' className='input_label'>Password</label>
            <input  value={password} onChange={e => setPassword(e.target.value)} type='password' name='password' className='auth_input' />
          </div>
          <br />
          <br />
          <div className='nameWrapper'>
            <button onClick={submitForm} className='auth_input auth_submit_btn'>
              <span className='btn_text'>Login</span>
              { isloading && <span className='btn_svg'><Loader /></span> }
                    
            </button>
          </div>
          <div className="buttonWrapper signupBtn">
            <p className="or">OR</p>
            <div className='other_autheticate'>
                <GoogleButton />
                <FacebookButton  />
                <LinkedInButton />
            </div>
          </div>
        </div>

      </div>

    </GoogleOAuthProvider>
  )
}