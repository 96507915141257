import React, { useContext, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { VscClose } from 'react-icons/vsc'
import { StudentAdmissionContext } from '../../../Addons/AdmissionContext'

const PageTwo = ({ handle_save_info, level }) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [gaurdianType, setGaurdianType] = useState('')
    const [studentAdmission, setStudentAdmission] = useContext(StudentAdmissionContext)

    const [guardianData, setGuardianData] = useState([])

    const [typeDD, setTypeDD] = useState(false)

    const save_data = () => {
        const guarians = {
            "guardians": guardianData
        }
        handle_save_info(level);
        setStudentAdmission(prevInfo => ({...prevInfo, guarians}))
    }
    
    const add_guardian = () => {
        const data = {
            "first_name": firstName,
            "last_name": lastName,
            "phone": phone,
            "alt_mail": email,
            "type": gaurdianType,
        }
        if(phone.length < 5){
            alert('please provide a valid phone number')
            return
        }
        setGuardianData([...guardianData, data])
        clearForm()
    }

    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setPhone('')
        setEmail('')
        setGaurdianType('')
    }

    const removeGuardian = (phone) => {
        const newGuardianList = guardianData.filter(guardian => guardian.phone !== phone)
        setGuardianData(newGuardianList)
    }

  return (
    <>
        <div className='admission_title'>
            <h1>Parent/Guardian Info</h1>
            <p>Information about guardian of student in case of an emergency</p>
        </div>
        <form className='admission_form'>
            <div className='nameWrapper inputflex'>
                <div className='sides'>
                    <label htmlFor='fname' className='input_label'>First Name</label>
                    <input value={firstName} onChange={e => setFirstName(e.target.value)} type='text' name='fname' className='auth_input' />
                </div>
                <div className='sides'>
                    <label htmlFor='lname' className='input_label'>Last Name</label>
                    <input value={lastName} onChange={e => setLastName(e.target.value)} type='text' name='lname' className='auth_input' />
                </div>
            </div>
            <div className='nameWrapper'>
                <label htmlFor='gaurdianType' className='input_label'>Type</label>
                <input 
                    value={gaurdianType} 
                    onFocus={() => setTypeDD(true)} 
                    onBlur={() => setTimeout(() => { setTypeDD(false)}, 300) } 
                    placeholder='e.g Mother, Aunty, Cuisin' 
                    type='text' name='gaurdianType' className='auth_input' 
                />
                <div className={`student_admission_dropdown ${typeDD ? 'show': ''}`}>
                        <ul onClick={(e) => setGaurdianType( e.target.textContent)}>
                                <li>Mother</li>
                                <li>Father</li>
                                <li>Aunt</li>
                                <li>Uncle</li>
                                <li>Elder Sibling</li>
                        </ul>
                  </div>
            </div>
            <div className='nameWrapper inputflex'>
                <div className='sides'>
                    <label htmlFor='phone' className='input_label'>Phone</label>
                    <input value={phone} onChange={e => setPhone(e.target.value)} type='text' placeholder='+237 671234567' name='phone'className='auth_input' />
                </div>
                <div className='sides'>
                    <label htmlFor='email' className='input_label'>Email</label>
                    <input value={email} onChange={e => setEmail(e.target.value)} type='email' placeholder='example@mail.com' name='email' className='auth_input' />
                </div>
            </div>
            <button onClick={add_guardian} type='button' className='auth_input auth_submit_btn add_guardian_btn'>
                <span className='btn_svg'><AiOutlinePlus /></span>
                <span>Add</span>
            </button>
        </form>
        {
            guardianData.length > 0
            &&
            <div className='admission_title'>
                <h1>Guardian List</h1>
                <p>Information about guardian of student in case of an emergency</p>
                {
                    guardianData.map(guadian => (
                        <div key={guadian.phone} className='guardian_list'>
                            <p>{guadian.type}</p>
                            <p>{guadian.first_name} {guadian.last_name}</p>
                            <p>{guadian.phone}</p>
                            <p onClick={() => removeGuardian(guadian.phone)} className='close_btn_for_guardian_list'><VscClose /></p>
                        </div>
                    
                    ))
                }
            </div>
        }
        {
            guardianData.length > 0
            &&
            <div className='nameWrapper'>
                <button onClick={ save_data } type='submit' className='auth_input auth_submit_btn'>
                    <span>Proceed</span>
                </button>
            </div>
        }
    </>
  )
}

export default PageTwo