import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IoMdCheckmark } from 'react-icons/io'
import './transactions.css'
import { postTenant } from '../../Resquest'
import { VscClose } from 'react-icons/vsc'
import { ReactComponent as LoaderOval } from '../../assets/LoaderOval.svg'


const variants = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    visible: {
        opacity: 1,
        scale: [ 0, 1.5],
        transition: {
            duration: .1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    }
}


const FeePaymentStatus = ({ server }) => {
    const [serverResponse, setServerResponse] = useState({})
    const [isloading, setIsloading] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    const transaction_id = queryParams.get('transaction_id');
    const tx_ref = queryParams.get('tx_ref');

    useEffect(() => {
        setIsloading(true)
        verify_transaction()
    }, [])

    const verify_transaction = async() => {
        let data = {
            "status": status,
            "tx_ref": tx_ref,
            "transaction_id": transaction_id 
        }
        console.log('data', data);
        const response = await postTenant(server, '/verify-payment/', data)
        if(response?.status === 200){
            setServerResponse(response.data)
        }
        console.log(response.data);
        setIsloading(false)
    }
    


  return (
    <div className='fee_payment_page'>
        <h1>Fee Payment Status</h1>
        {
            isloading
            ?
            <div style={{ textAlign: 'center'}}>
                <span className="class_fetch_data_loader">
                    <LoaderOval />
                </span>
                <h1>Verifying payment. Please wait...</h1>
            </div>
            :
            <>
            <div className='fee_payment_flex'>
                <div className='fee_payment_status'>
                    {
                        serverResponse?.data?.status === 'success' 
                        ?
                            <div className='check_mark_valid_wrapper'>
                                <motion.div
                                    className='check_mark_valid'
                                    variants={variants}
                                    initial="hidden"
                                    animate="visible"
                                >
                                    <motion.div
                                        className='check_mark_inner'
                                        variants={variants}
                                        initial="visible"
                                        animate="visible"
                                    >
                                        <span className='check_mark_icon'><IoMdCheckmark /> </span>
                                    </motion.div>
                                </motion.div>
                            </div>
                        :
                            <div className='check_mark_valid_wrapper'>
                                <motion.div
                                        className='check_mark_valid failed'
                                        variants={variants}
                                        initial="hidden"
                                        animate="visible"
                                    >
                                    <motion.div
                                            className='check_mark_inner failed'
                                            variants={variants}
                                            initial="visible"
                                            animate="visible"
                                        >
                                            <span className='check_mark_icon'><VscClose /> </span>
                                    </motion.div>
                                </motion.div>
                            </div>
                    }
                </div>
                <div className='fee_transaction_details'>
                    <div className='fee_payment_item'>
                        <h2>Payment Status</h2>
                        <p>{ serverResponse?.data?.message || 'Unable to verify this transaction.'}</p>
                    </div>
                    <div className='fee_payment_item'>
                        <h2>Amount</h2>
                        <p>{ serverResponse?.data?.data?.currency } { serverResponse?.data?.data?.amount || 'Null' }</p>
                    </div>
                    <div className='fee_payment_item'>
                        <h2>Transaction ID</h2>
                        <p>{ serverResponse?.data?.data?.id || 'Null' }</p>
                    </div>
                    <div className='fee_payment_item'>
                        <h2>Reference Code</h2>
                        <p>{ serverResponse?.data?.data?.tx_ref || 'Null' }</p>
                    </div>
                    <div className='fee_payment_item'>
                        <h2>Provider</h2>
                        <p>{ serverResponse?.data?.data?.meta?.MOMO_NETWORK || 'Null' }</p>
                    </div>
                    <div className='fee_payment_item'>
                        <h2>Payment Type</h2>
                        <p>{ serverResponse?.data?.data?.payment_type || 'Null' }</p>
                    </div>

                </div>
            </div>
            <div className='fee_payment_action'>
                <Link to='/dashboard'>Go to dashboard</Link>
                { serverResponse?.data?.status === 'success' && <button className='download_transction'>Download receipt</button>}
            </div>
        </>
        }
    </div>
  )
}

export default FeePaymentStatus