import React, { useEffect, useState } from 'react'
import './admission.css'


import { ReactComponent as Loader } from '../../assets/loader.svg'
import PageOne from './Form/PageOne'
import PageTwo from './Form/PageTwo'
import PageThree from './Form/PageThree'
import { getTenant } from '../../Resquest'
import { StudentAdmissionContext, StudentAdmissionContextProvider } from '../../Addons/AdmissionContext'


const AdmissionForm = ({ server }) => {
  const [classes, setClasses] = useState([])
  const [departments, setDepartments] = useState([])
  const [studentInfo, setStudentInfo] = useState({})
  const [level, setLevel] = useState(0)


  useEffect(() => {
    fetch_classes()
    fetch_department()
  }, [])
  
  const save_info = (level) => {
    level < 2 && setLevel(level += 1)
  }

  const fetch_classes = async() => {
    const res = await getTenant(server, '/classes/')
    res.status === 200 && setClasses(res?.data)
    console.log(res);
  }

  const fetch_department = async() => {
    const res = await getTenant(server, '/departments/')
    res.status === 200 && setDepartments(res?.data)
    console.log(res);
  }




  
  const submit_information = async(data) => {
    console.log("previous Infos", studentInfo);
    console.log("current affairs", data);

  }
  return (
    <StudentAdmissionContextProvider>
    <div className='admission_pages'>
      <aside className='admission_aside'>
        <h2>Admission Wizard</h2>
        <div className='admission_progress'>
          <div className='progress_list' onClick={() => setLevel(0)}>
            <span className='admission_page_count active'>1</span>
            <span className='text'>Student Info</span>
          </div>
          <div className='progress_list' onClick={() => setLevel(1)}>
            <span className='admission_page_count'>2</span>
            <span className='text'>Parent Info</span>
          </div>
          <div className='progress_list' onClick={() => setLevel(2)}>
            <span className='admission_page_count'>3</span>
            <span className='text'>Academics</span>
          </div>
        </div>
      </aside>
      <div className='admission_main_content'>
        {
          level === 0 
          ? 
          <PageOne server={server} level={0} classes={classes} departments={departments} handle_save_info={save_info} />
          : level === 1 
          ? 
          <PageTwo level={1} handle_save_info={save_info} />
          : 
          <PageThree handle_submit_information={submit_information} server={server} />
        }
          
        
      </div>

    </div>
    </StudentAdmissionContextProvider>
  )
}

export default AdmissionForm



