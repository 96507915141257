import React from 'react'
import { Link } from 'react-router-dom'
import { MdSlowMotionVideo } from "react-icons/md";


import Learn from '../../assets/img/learn.png'
import './Home.css'
import Footer from '../../Footer/Footer'

import Faqs from './Faq/Faqs'
import PopularClients from './PopularClients'
import Secure from './Secure'
import Goals from './Goal/Goals'
import ShiftingCountdown from './LaunchCounter'

const Landing = () => {
  return (
    <div>
        <div className='hero_section'>
          <div className='container hero_flex'>
            <div className='action_left'>
              <h1 className='hero_title'>We are more than just a management system</h1>
              <h3 className='hero_sub_title'>Our comprehensive services cater to schools, colleges, and educational centers, ensuring excellence at competitive prices. With round-the-clock customer support, we streamline administration processes, facilitating an efficient and hassle-free experience.</h3>
              <div className='call_to_action_wrapper'>
                <Link className='call_to_action' to='/register'>Get started for free</Link>
                <button className='call_to_action watch_vid_btn'>
                  <span className='icon'><MdSlowMotionVideo /></span>
                  <span>Watch Video</span>
                </button>
              </div>
            </div>
            <div className='hero_img_wrapper'>
                <img src={Learn} alt='learn animate' />
            </div>
          </div>
        </div>
        <Goals />
        <PopularClients />
        <Secure />
        <Faqs />
        {/* <ShiftingCountdown /> */}
        <Footer />
    </div>
  )
}

export default Landing