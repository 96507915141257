
import React, { useEffect, useRef, useState } from 'react'
import { Actions, AnimateOnboard } from '../../SlideInfomation'
import { IoLinkOutline, IoSchoolOutline } from 'react-icons/io5'
import Calendar from 'react-calendar'
import { getTenant, postTenant } from '../../../../../Resquest'
import useLocalStorage from '../../../../../lib/UseLocalStorage'
import SlideInNotifications from '../../../../../Addons/SlideNotification'



export const SlideInfomationVerify = ({ server, handle_save_data, index, handle_go_back }) => {
    const [isloading, setIsloading] = useState(false)
    const [createdTerms, setCreatedTerms] = useState([])
    const [currentSchool, setCurrentSchool ] = useLocalStorage('currentSchool', {})
    const [setsequenceCount, setSetsequenceCount] = useState(2)
    const [oldMinDate, setOldMinDate] = useState(null)

    const terms =[
      "One", "Two", "Three"
    ]
    const sequence_name = ['Seq 1', 'Seq 2', 'Seq 3', 'Seq 4', 'Seq 5', 'Seq 6', 'Seq 7', 'Seq 8', 'Seq 9', 'Seq 10']

    const hold_term_data = (data) => {
      if(data?.date?.end_date) {
        setOldMinDate(new Date(data?.date?.end_date))
      }
      setCreatedTerms(prevTerms => [ ...prevTerms, { data} ]);
    }

    const save_data = async() => {
      setIsloading(true)
      let req = []
      createdTerms.forEach(term => {
        let data = {
          "school": currentSchool?.id,
          "term_name": term.data.term_name,
          "start_date": term.data.date.start_date,
          "end_date": term.data.date.end_date,
          "exams_per_term": setsequenceCount
        }
        req.push(data)
      });

      const response = await postTenant(server, '/terms/', req)
      if(response?.status === 201){
        create_sequences(response?.data?.terms)
      }
      else {
        alert("Sorry!! There is an error with the provided range")
      }
      setIsloading(false)
    } 



    const create_sequences = (terms) => {
      const sequence_request = []
      let counter = 1
      terms && terms.forEach((term, index) => {
        for (let i = 1; i <= setsequenceCount; i++) {
          let data = {
            term: term.id,
            name: "Seq " + counter++,
            start_date: term.start_date,
            end_date: term.end_date
          };
          sequence_request.push(data);
        }
      });
      create_all_sequences(sequence_request)
    }

    const create_all_sequences = async(data) => {
      const response = await postTenant(server, '/sequences/', data)
      if(response.status === 201) {
        handle_save_data(index, {})
      }
      setIsloading(false)

    }


    const go_back = () => {
      handle_go_back(index);
    }


  return (
    <>
      <AnimateOnboard>
          <section className='school_onboard-1'>
          <div className="onboard_level_select_wrapper terms">
            <h3 className='level_select_title'>Term manager helps you create terms in your school in an organized way</h3>
            <>
             {
              terms.map((term, index) => (
                <SchoolTypeForm prev_min_date={oldMinDate} key={index} term={term} handle_save_term_data={hold_term_data} />
              ))
             }
            </>
          </div>
          </section>
      </AnimateOnboard>
      <Actions save_data={save_data} handle_go_back={go_back} index={index} isloading={isloading} />
    </>
  )
}

export default SlideInfomationVerify;



const SchoolTypeForm = ({ term, handle_save_term_data, prev_min_date }) => {
  const schoolTitleRef = useRef('')

  const save_term = (dates) => {
    const term_dates = {
      "term_name": term,
      "date": dates
    }

    handle_save_term_data( term_dates )
  }

  const marked_check = (e) => {
    schoolTitleRef.current.checked = true
  }

  return (
    <>
      <div className='onboad_level_title'>
        <input type="radio" name="school_type" id={term} ref={schoolTitleRef} />
        <label htmlFor={term} className='current_level_name' >Term {term}</label>
      </div> 

      <div className="onboard_class_tree">
          <OnboardClass prev_min_date={prev_min_date} mark_check={marked_check} handle_save_date={save_term} />
      </div>
    </>
  )
}



export const OnboardClass = ({  handle_save_date, mark_check, prev_min_date }) => {
  const [start_date, setStart_date] = useState('')
  const [end_date, setEnd_date] = useState('')

    const [rs_date, setRs_date] = useState('')
    const [re_date, setRe_date] = useState('')


  const [date, setDate] = useState(new Date());
  const [min_date, setMin_date] = useState(null)
  const [showDateModal, setShowDateModal] = useState(false)
  const [isStartDate, setIsStartDate] = useState(true)


  useEffect(() => {
    if(prev_min_date !== null){
      setMin_date(prev_min_date)
    }
  }, [prev_min_date])
  




  const select_date = (is_start_date) => {
    setIsStartDate(is_start_date);
    if(!is_start_date) {
      if(start_date){
        mark_check()
        setShowDateModal(true)
      }
      else {
        return
      }
    }
    mark_check()
    setShowDateModal(true)
  }

  const save_date = (e) => {
    setMin_date(e)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    isStartDate ? setStart_date(e.toDateString()) : setEnd_date(e.toDateString())

    const formattedDate = new Date(e).toISOString().split('T')[0];
    isStartDate ? setRs_date(formattedDate) : setRe_date(formattedDate);
    setShowDateModal(false)

    if(!isStartDate){
      let data = {
        "start_date": rs_date,
        "end_date": formattedDate
      }
      handle_save_date(data)
    }
  }




  return (
    <>
      { showDateModal && 
      <div className='term_date_picker'>
        <div className='shadow' onClick={() => setShowDateModal(false)} />
        <Calendar onChange={e => save_date(e)} minDate={min_date} value={date} className = 'dashboard_calendar' />
      </div> 
      }
      <div className="onboard_class">
        <div className={`onboard_input_wrapper`}>
            <input onFocus={() => select_date(true)} type="text" value={start_date} onChange={(e) => setStart_date(e.target.value)} placeholder="Start Date " />
        </div>

        <p className="onboard_class_link_line">
          <span className='icon'><IoLinkOutline /></span>
          <p className="line" />
        </p>

        <div className="onboard_student_wrapper">
          <div className={`onboard_input_wrapper`}>
              <input type="text" onFocus={ () => select_date(false) } value={end_date} onChange={(e) => setEnd_date(e.target.value)} placeholder="End Date" />
          </div>
        </div>
      </div>
    </>
  )
}
