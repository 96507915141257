import {AnimatePresence, motion} from 'framer-motion';
import React, { useState } from 'react'
import { CiCircleCheck } from "react-icons/ci";

import { AnimateOnboard } from "../SlideInfomation"


const pageTransition = {
    hidden: {
        y: "3vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1
    },
    exit: {
        y: "-3vh",
        opacity: 0,
  
    }
  }

export const AnimateOptions = ({ children }) => {
    return (
          <motion.div variants={pageTransition} initial="hidden" animate="visible" exit="exit" transition={{duration: 0.6, delay: 0.2}}>
            {children}
          </motion.div>
  
    )
  }
  
export const OnboardSlideTextStart = ({step}) => {
    return (
        <AnimateOnboard>
        <h1 className='onboard_heading'>An all in one application to track and manage your school</h1>
        <p className='onboard_quote_text'>We provide exception services to control the infrastructure of your organisation, ochestrating complete management of your activities</p>
        </AnimateOnboard>

    )
}
export const OnboardSlideTextVerify = ({step}) => {
    return (
        <AnimateOnboard>
        <h1 className='onboard_heading'>Configure how you want your terms and exams to be.</h1>
        <p className='onboard_quote_text'>Personalize your academics to suite how you want your school to be structured and the duration of terms</p>
        </AnimateOnboard>

    )
}

export const OnboardSlideTextLocation = ({step}) => {
    return (
      <AnimateOnboard>
        <h1 className='onboard_heading'>Location of school</h1>
        <p className='onboard_quote_text'>Find student in your area or affar with our suffisticated recommendation system that brings students together using our services</p>
        <AnimateOptions>
          <div   className='onboard_service_tree'>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Access to multiple servers</p>
            </div>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Get quality results and analytics</p>
            </div>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Create courses and blog content</p>
            </div>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Boost appearance</p>
            </div>
          </div>
        </AnimateOptions>
      </AnimateOnboard>
    )
  }
  
  
  export const OnboardSlideTextAddress = ({step}) => {
    return (
      <AnimateOnboard>
        <h1 className='onboard_heading'>Earn from your content</h1>
        <p className='onboard_quote_text'>Go big or go home. Sell your schools incredible content and students around the world and gain exceptional recorgnision</p>
        <AnimateOptions>
          <div   className='onboard_service_tree'>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Best of all. You can start for free</p>
            </div>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Access to multiple servers</p>
            </div>
          </div>
        </AnimateOptions>
      </AnimateOnboard>
    )
  }
  export const OnboardSlideTextPictures = ({step}) => {
    return (
      <AnimateOnboard>
        <h1 className='onboard_heading'>Get pre built report cards and easy user registration</h1>
        <p className='onboard_quote_text'>Creating and managing marks has never been this easy. You can now connect to your mobile phone and get the best experience from both worlds</p>
        <AnimateOptions>
          <div   className='onboard_service_tree'>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Tons of free designs for your school </p>
            </div>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Buy report card designs </p>
            </div>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Advertise and get publicity as you desire </p>
            </div>
          </div>
        </AnimateOptions>
      </AnimateOnboard>
    )
  }
  
  export const OnboardSlideTextFamily = ({step}) => {
    return (
      <AnimateOnboard>
        <h1 className='onboard_heading'>Connect all members at once</h1>
        <p className='onboard_quote_text'>With our incredible messaging service you can now connect all your staff members and parents in one go</p>
        <AnimateOptions>
          <div   className='onboard_service_tree'>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Tons of free designs for your school </p>
            </div>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Buy report card designs </p>
            </div>
            <div className='onboard_service_item'>
              <span className='icon'><CiCircleCheck /></span>
              <p>Advertise and get publicity as you desire </p>
            </div>
          </div>
        </AnimateOptions>
      </AnimateOnboard>
    )
  }
  