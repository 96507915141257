import React, { useEffect, useState } from 'react'
import { VscClose } from 'react-icons/vsc'

import Learn from '../../assets/img/learn.png'

import './NewSchoolApp.css'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as Loader } from '../../assets/LoaderOval.svg'
import { loginTenant, postRequest, postTenant } from '../../Resquest'
import { PiPencilSimpleLight } from 'react-icons/pi'
import useLocalStorage from '../../lib/UseLocalStorage'

const NewSchoolApp = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [verified, setVerified] = useState(false)
    const [tenant, setTenant] = useState('')
    const [creatingTenant, setCreatingTenant] = useState(false)
    const [recommendations, setRecommendations] = useState([])
    const [message, setMessage] = useState('')
    const [name, setName] = useState('')
    const [currentServer, setCurrentServer] = useLocalStorage('currentServer', '')
    const [tenantUser, setTenantUser] = useLocalStorage('tenantUser', null)

    const navigate = useNavigate()

    const go_home = () => {
        navigate('/')
    }

    const create_tenant = (e) => {
        e.preventDefault()
        if(creatingTenant) return

        setCreatingTenant(true)
        setIsLoading(true)
        
        school_create()
    }


    const school_create = async() => {
        const response = await postRequest('/create-tenant/', {"tenant_name": tenant.toLowerCase(), "school_name": name})
        console.log(response)
        if (response.status === 201) {
            save_tenant(response?.data)
            setCreatingTenant(response.data.data.domain)
        }
        else {
            setCreatingTenant(false)
            alert("Unable to create school at the moment. Try again later")
        }
        return false
    }

    const alert = (msg) => {

    }

    

    const save_tenant = (response) => {
        const data = {
            "user": response?.data?.owner,
            "access": response?.data?.access,
            "refresh": response?.data?.refresh,
            "server": response?.data?.server,
            "server_url": response?.data?.server_url
        }
        setTenantUser(data);
        setCreatingTenant(false)
        window.location.href = '/onboard/create-school'
        
    }

    
    /***
     * Checks and returns if tenant exist
     */
    const school_exist = async() => {
        const response = await postRequest('/tenant-exist/', {"school_abr": tenant})
        if (response.status === 200) {
            response.data.recommendations ? setRecommendations(response.data.recommendations) : setMessage(response.data.message)
        }
        return false
    }

    const verify_name = async() => {
        setIsLoading(true)
        if (tenant.length > 0) {
            const exist = await school_exist()
            if (exist) {
                setVerified(false)
            } else {
                setVerified(true)
            }
        }
        setIsLoading(false)
    }

    const selectRecommendation = (recommendation) => {
        setTenant(recommendation)
        setRecommendations([])
    }
    const setTenantName = (name) => {
        let modifiedStr = name.replace(/[ .]/g, "");
        setTenant(modifiedStr.toUpperCase())
    }
    
  return (
    <div className='create_school_tenant'>
        <div className='create_school_tenant_header_wrapper'>
            <h1>Create a School</h1>
            {
                creatingTenant
                ?
                <div className='close_create_school_tenant loading'>
                    <Loader />
                </div>
                :
                <div onClick={go_home} className='close_create_school_tenant'>
                    <VscClose />
                </div>
            }
        </div>

        <div className='create_school_tenant_content'>
            <div className='create_school_tenant_content_title'>
                <h1>Get started with Rank</h1>
                <h1>Lets help you create your School</h1>
            </div>
            <div className='create_school_tenant_content_form'>
                <label htmlFor='school_name' style={{color: '#fff'}}>School Name</label>
                <div className='create_school_tenant_content_form_input'>
                    <input type='text' value={name} onChange={e => setName(e.target.value)} id='school_name' />
                </div>
            </div>
            <br />
            <div className='create_school_tenant_content_form'>
                <label htmlFor='school_abbreviation'>School Abbreviation</label>
                <div className='create_school_tenant_content_form_input'>
                    <input type='text' value={tenant} onChange={e => setTenantName(e.target.value)} id='school_abbreviation' />
                </div>
            </div>
            { 
            message ? <div className='message_from_server good'>{message}</div>
            :
            <div className="suggested_tenant_names">
                {
                    recommendations.map(recommendation => (
                        <div onClick={e => selectRecommendation(recommendation)} key={recommendation} className='suggested_tenant_name_item'>
                            <span className='icon'><PiPencilSimpleLight /></span>
                            <span>{recommendation}</span>
                        </div>
                    ))
                }
               
            </div>
            }

            <div className='create_tenant_button'>
                {
                    isLoading
                    ?
                    <button  className='btn create_tenant_btn loader'>
                        <Loader />
                    </button>
                    : verified ?
                    <button onClick={create_tenant} className='btn create_tenant_btn'>
                        <span>Create School</span>
                    </button>
                    :
                    <button onClick={verify_name} className='btn create_tenant_btn'>
                        <span>Verify Name</span>
                    </button>

                }
            </div>
        </div>


        <div className='create_school_tenant_svg'>
            <img src={Learn} alt='learn animate' />
        </div>

    </div>
  )
}

export default NewSchoolApp