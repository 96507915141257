import React from 'react'
import googleLogo from './googleLogo.png'
import { GoogleLogin } from '@react-oauth/google';


const GoogleButton = () => {
  return (
    <GoogleLogin
    onSuccess={credentialResponse => {
        console.log(credentialResponse);
    }}
    onError={() => {
        console.log('Login Failed');
    }}
    />
  )
}

export default GoogleButton
