import React, { useEffect, useState } from 'react'
import './addons.css'

import { IoSearch } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'


const Search = ({ cat, data }) => {
        const [searchString, setSearchString] = useState('')
        const [filterData, setFilterData] = useState([])
        const [isSearching, setIsSearching] = useState(false)

        const navigate = useNavigate()
        useEffect(() => {
                setFilterData(data)
        }, [data])
        
        const handle_search = (text) => {
                setSearchString(text)
                let filter = data.filter(data_item => 
                        data_item?.user?.first_name?.toLowerCase().includes(searchString.toLowerCase())||
                        data_item?.user?.last_name?.toLowerCase().includes(searchString.toLowerCase())
                )


                setFilterData(filter)
        }

  return (
        <div className='search_with_results'>
                <div className='search'>
                        <input 
                                type='text' 
                                onFocus={() => setIsSearching(true)} 
                                onBlur={()=> setTimeout(() => {
                                        setIsSearching(false)
                                }, 150) } 
                                placeholder='search...' 
                                value={searchString} 
                                onChange={e=> handle_search(e.target.value)} 
                                className='search_input' 
                        />
                        
                        <span className='search_icon'>
                                <IoSearch />
                        </span>
                </div>
                {
                        isSearching &&
                        (
                                <div className='search_results'>
                                        <ul className='search_result_list'>
                                                {
                                                        filterData.map(user => (
                                                                <li key={user?.id} onClick={() => navigate(`/${cat}/${user?.id}`)} className='search_result_link'>{ user?.user?.first_name } { user?.user?.last_name }</li>
                                                        ))
                                                }
                                        </ul>
                                </div>
                        )
                }
        </div>
  )
}

export default Search