import React from 'react'
import './ReportCardDesign.css'
import CardTemplate from './CardTemplate'
import { useLocation } from 'react-router-dom'


const DesignReportCard = () => {
  const free = new Array(5).fill(0)
  const paid = new Array(9).fill(0)
  const premium = new Array(3).fill(0)
  const location = useLocation()

  return (
    <>
    <div className='report-card-design-page'>
        {
          location.pathname.startsWith('/onboard') ? 
          "" :
          <div className='card_hero'>
                  <h2 className='title'>Welcome to Report Card Design</h2>
                  <div className='text'>You can use the templates below to setup a report card design for your school</div>
          </div>
        }
        <div className='container'>
          <div className='title contentTitle'>Free template designs</div>
          <div className='report_card_template_grid'>
            {
              free.map(index => (
                <CardTemplate key={index} />
              ))
            }
            
          </div>
        </div>
        <div className='container'>
          <div className='title contentTitle'>Paid designs</div>
          <div className='report_card_template_grid'>
            {
              paid.map(index => (
                <CardTemplate key={index} />
              ))
            }
            
          </div>
        </div>
        <div className='container'>
          <div className='title contentTitle'>Premium designs</div>
          <div className='report_card_template_grid'>
            {
              premium.map(index => (
                <CardTemplate key={index} />
              ))
            }
            
          </div>
        </div>
    </div>
    
    </>
  )
}

export default DesignReportCard